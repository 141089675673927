import React from 'react'
import { DistributionApp } from './Dashboard'

export default function Distribution() {
  return(
    <div className="Distribution Page-content">
      <div className="Box">
        <DistributionApp />
      </div>
    </div>
  )
}
