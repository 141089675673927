import React, { useEffect, useState } from 'react'
import { useLocalStorage } from './Storage'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import ethers from 'ethers'

import useSWR from 'swr'
import cx from "classnames";

import { getContract } from './Addresses'

import { getInjectedConnector, getExplorerUrl, fetcher,
  useEagerConnect, useInactiveListener } from './Helpers'

import { getPriceFeeds, getPriceFeed } from './PriceFeeds'

import { BsCaretDownFill } from 'react-icons/bs'

import X2AppOwner from './abis/X2AppOwner.json'
import X2ETHReader from './abis/X2ETHReader.json'

import "./X2Launcher.css"
import "./Dashboard.css"

const CHAIN_ID = 1
const TOTAL_TX_KEY = "totalTxKey:"

const LISTED_MARKET_INDEX = 18

const LEVERAGES = [
  {
    name: "0.5x",
    value: 5000
  },
  {
    name: "1x",
    value: 10000
  },
  {
    name: "3x",
    value: 30000
  },
  {
    name: "5x",
    value: 50000
  },
  {
    name: "10x",
    value: 100000
  },
  {
    name: "20x",
    value: 200000
  }
]

export function getTotalTxKey(account) {
  if (!account) {
    return TOTAL_TX_KEY
  }
  return TOTAL_TX_KEY + account
}

async function incrementTotalTxCount(library, account, props) {
  const txCount = await library.getTransactionCount(account)
  if (txCount > props.totalCount) {
    props.setTotalCount(txCount + 1)
    return
  }

  props.setTotalCount(props.totalCount + 1)
}

export const X2Login = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="ExchangeBeta-login">
      {!active &&
        <button type="button" onClick={activateMetamask} className="button-outline">
          Connect Wallet
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer" className={cx("button-outline", "ExchangeBeta-pending-tx", { active: pending > 0 })}>
          {pending === 1 && "1 Pending Tx"}
          {pending !== 1 && `${pending} Pending Txs`}
        </a>
      }
    </div>
  )
}

export default function X2Launcher() {
  const priceFeeds = getPriceFeeds(CHAIN_ID)
  const [priceFeedAddress, setPriceFeedAddress] = useState(priceFeeds[0].address)
  const [leverage, setLeverage] = useState(30000)
  const [note, setNote] = useState("")
  const [activatingConnector, setActivatingConnector] = React.useState()
  const { account, activate, active, library } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  const [isLaunching, setIsLaunching] = useState(false)

  const { data: pendingMarkets, mutate: updatePendingMarkets } = useSWR([active, getContract(CHAIN_ID, "X2ETHReader"), 'getMarkets', getContract(CHAIN_ID, "X2ETHFactory"), LISTED_MARKET_INDEX], {
    fetcher: fetcher(library, X2ETHReader),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updatePendingMarkets(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updatePendingMarkets])

  const { connector } = useWeb3React()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  const onClickPrimary = async () => {
    if (!active) {
      activateMetamask()
      return
    }

    setIsLaunching(true)
    const contract = new ethers.Contract(getContract(CHAIN_ID, "X2AppOwner"), X2AppOwner.abi, library.getSigner())
    const factory = getContract(CHAIN_ID, "X2ETHFactory")
    contract.createMarket(factory, priceFeedAddress, leverage, note)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Market submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Launch failed.")
      })
      .finally(() => {
        setIsLaunching(false)
      })
  }

  return(
    <div className="X2Launcher Page-content">
      <div className="Box">
        <div className="X2Launcher-title-box">
          <div className="X2Launcher-title">
            X2 Launcher
          </div>
          <X2Login totalCount={totalCount} setTotalCount={setTotalCount} />
        </div>
        <div>
          Launch any supported Chainlink market
        </div>
        <br/>
        <div className="X2Launcher-form">
          <div>Price Feed</div>
          <div className="X2Launcher-selector">
            <select value={priceFeedAddress} onChange={(e) => setPriceFeedAddress(e.target.value)}>
              {priceFeeds.map((p) => {
                return <option value={p.address} key={p.address}>
                  {p.name}
                </option>
              })}
            </select>
            <BsCaretDownFill className="select-caret" />
          </div>
          <div>Leverage</div>
          <div className="X2Launcher-selector">
            <select value={leverage} onChange={(e) => setLeverage(e.target.value)}>
              {LEVERAGES.map((l) => {
                return <option value={l.value} key={l.name}>
                  {l.name}
                </option>
              })}
            </select>
            <BsCaretDownFill className="select-caret" />
          </div>
          <div>Telegram Handle</div>
          <input type="text" className="input-outline Dashboard-input-outline" placeholder="e.g. @tghandle"
            value={note} onChange={(e) => setNote(e.target.value)} />
          <div className="X2Launcher-warning-box">
            <strong>PENDING MARKETS</strong><br/>
            {!pendingMarkets && <div>Connect wallet to view pending markets.</div>}
            {pendingMarkets && pendingMarkets[1].length === 0 && <div>No pending markets.</div>}
            {pendingMarkets && pendingMarkets[1].length > 0 && <div>The following markets have been created and are pending listing:</div>}
            {pendingMarkets && pendingMarkets[1].length > 0 && pendingMarkets[1].map((item, i) => {
              return <div key={i}>
                {parseInt(pendingMarkets[0][i].toNumber() / 10000)}x {getPriceFeed(CHAIN_ID, item).name}
              </div>
            })}
            <br/>
            <strong>WARNING</strong><br/>
            The Ethereum fee to create a market can be expensive.
            Please ensure that the Ethereum fee is acceptable to you when confirming the transaction in Metamask.
            <br/>
            <br/>
            Please also read about <a href="https://xvi10.gitbook.io/xvix/x2" target="_blank" rel="noopener noreferrer" className="simple">
              leverage tokens
            </a> before creating a market.
            <br/>
            <br/>
            <strong>LISTING</strong><br/>
            After creating a market, send a message to <a href="https://t.me/xdev_10" target="_blank" rel="noopener noreferrer" className="simple">
              @xdev_10
            </a> and we will list it.
            We will also have a sponsorship message on your market's page, this can be customized as you like.
          </div>
          <br/>
          <button type="button" className="Dashboard-button-primary" onClick={() => onClickPrimary() } disabled={isLaunching}>
            {!active && "Connect Wallet"}
            {active && !isLaunching && "Launch Market"}
            {active && isLaunching && "Launching..."}
          </button>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  )
}
