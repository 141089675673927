import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocalStorage } from './Storage'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import useSWR from 'swr'
import ethers from 'ethers'
import cx from "classnames";

import { ImCross } from 'react-icons/im'

import X2StakeReader from './abis/X2StakeReader.json'
import Xvix from './abis/Xvix.json'
import Farm from './abis/Farm.json'

import { getContract, XVIX_ADDRESS, XLGE_WETH_ADDRESS, WETH_ADDRESS } from './Addresses'

import { getInjectedConnector, getExplorerUrl, fetcher,
  useEagerConnect, useInactiveListener, formatAmount,
  formatArrayAmount } from './Helpers'

import './Stake.css'

const TOTAL_TX_KEY = "totalTxKey:"
const CHAIN_ID = 1

export function getTotalTxKey(account) {
  if (!account) {
    return TOTAL_TX_KEY
  }
  return TOTAL_TX_KEY + account
}


export const X2Login = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="ExchangeBeta-login">
      {!active &&
        <button type="button" onClick={activateMetamask} className="button-outline">
          Connect Wallet
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer" className={cx("button-outline", "ExchangeBeta-pending-tx", { active: pending > 0 })}>
          {pending === 1 && "1 Pending Tx"}
          {pending !== 1 && `${pending} Pending Txs`}
        </a>
      }
    </div>
  )
}

async function incrementTotalTxCount(library, account, props) {
  const txCount = await library.getTransactionCount(account)
  if (txCount > props.totalCount) {
    props.setTotalCount(txCount + 1)
    return
  }

  props.setTotalCount(props.totalCount + 1)
}

const StakeOptionModal = (props) => {
  const { account, library, active, activate } = useWeb3React()
  const { tokenName, tokenAddress, farmAddress, stakeInfo,
    tokenBalanceIndex, farmBalanceIndex, farmAllowanceIndex,
    isModalVisible, setIsModalVisible,
    option, setOption,
    totalCount, setTotalCount } = props
  const [stakeQuantity, setStakeQuantity] = useState("")
  const [unstakeQuantity, setUnstakeQuantity] = useState("")
  const [isStaking, setIsStaking] = useState(false)
  const [isUnstaking, setIsUnstaking] = useState(false)
  const [isApproving, setIsApproving] = useState(false)

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  const parsedStakeQuantity = parseFloat(stakeQuantity)
  let stakeQuantityInWei

  if (!isNaN(parsedStakeQuantity)) {
    stakeQuantityInWei = ethers.utils.parseEther(parsedStakeQuantity.toString())
  }

  let stakeError = false
  if (isNaN(parsedStakeQuantity)) {
    stakeError = "Invalid quantity"
  }
  if (stakeQuantity === "" || (stakeQuantityInWei && stakeQuantityInWei.eq(0))) {
    stakeError = "Enter an amount"
  }
  if (stakeQuantityInWei && stakeInfo && stakeQuantityInWei.gt(stakeInfo[tokenBalanceIndex])) {
    stakeError = "Insufficient tokens"
  }

  let needApproval = false
  if (stakeQuantityInWei && stakeInfo) {
    needApproval = stakeInfo[farmAllowanceIndex].lt(stakeQuantityInWei)
  }

  const parsedUnstakeQuantity = parseFloat(unstakeQuantity)
  let unstakeQuantityInWei

  if (!isNaN(parsedUnstakeQuantity)) {
    unstakeQuantityInWei = ethers.utils.parseEther(parsedUnstakeQuantity.toString())
  }

  let unstakeError = false
  if (isNaN(parsedUnstakeQuantity)) {
    unstakeError = "Invalid quantity"
  }
  if (unstakeQuantity === "" || (unstakeQuantityInWei && unstakeQuantityInWei.eq(0))) {
    unstakeError = "Enter an amount"
  }
  if (unstakeQuantityInWei && stakeInfo && unstakeQuantityInWei.gt(stakeInfo[farmBalanceIndex])) {
    unstakeError = "Insufficient balance"
  }

  const approveTokens = async (spender) => {
    setIsApproving(true)
    const contract = new ethers.Contract(tokenAddress, Xvix.abi, library.getSigner())
    contract.approve(spender, ethers.constants.MaxUint256)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Approval submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Approval failed.")
      })
      .finally(() => {
        setIsApproving(false)
      })
  }

  const stake = async () => {
    if (!active) {
      activateMetamask()
      return
    }
    if (needApproval) {
      approveTokens(farmAddress)
      return
    }

    setIsStaking(true)

    const contract = new ethers.Contract(farmAddress, Farm.abi, library.getSigner())
    contract.deposit(stakeQuantityInWei, account)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Stake submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setStakeQuantity("")
        setIsModalVisible(false)
        setOption("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Stake failed.")
      })
      .finally(() => {
        setIsStaking(false)
      })
  }

  const unstake = async () => {
    setIsUnstaking(true)

    const contract = new ethers.Contract(farmAddress, Farm.abi, library.getSigner())
    contract.withdraw(account, unstakeQuantityInWei)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Unstake submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setUnstakeQuantity("")
        setIsModalVisible(false)
        setOption("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Unstake failed.")
      })
      .finally(() => {
        setIsUnstaking(false)
      })
  }

  return (
    <div>
      {isModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => { setIsModalVisible(false); setOption("") } }></div>
          <div className="modal-box Stake-modal Stake-option-modal">
            <div className="Stake-modal-top">
              {option === "" &&
                <div className="Stake-title Stake-modal-title Stake-modal-option-title">{tokenName}</div>
              }
              {option === "stake" &&
                <div className="Stake-title Stake-modal-title Stake-modal-option-title">
                  Stake {tokenName}
                </div>
              }
              {option === "unstake" &&
                <div className="Stake-title Stake-modal-title Stake-modal-option-title">
                  Unstake {tokenName}
                </div>
              }
              <div className="Stake-modal-close" >
                <ImCross className="clickable" onClick={ () => { setIsModalVisible(false); setOption("") } } />
              </div>
            </div>
            {option === "" &&
            <div className="Stake-option-container">
              <div className="Stake-option" onClick={ () => setOption("stake") }>
                Stake
              </div>
              <div className="Stake-option" onClick={ () => setOption("unstake") }>
                Unstake
              </div>
            </div>}
            {option === "stake" &&
            <div>
              <div className="Stake-max-value clickable plain" onClick={() => setStakeQuantity(formatArrayAmount(stakeInfo, tokenBalanceIndex)) }>
                Max {formatArrayAmount(stakeInfo, tokenBalanceIndex)}
              </div>
              <div>
                <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={stakeQuantity}
                  onChange={(e) => setStakeQuantity(e.target.value) } />
              </div>
              <div>
                <button type="button" className="Stake-button-primary Stake-button-primary-xlge" disabled={stakeError || isStaking} onClick={() => stake() }>
                  {isStaking && "Staking..."}
                  {isApproving && "Approving..."}
                  {(!isApproving && !isStaking && stakeError !== false) && stakeError}
                  {(!isApproving && !isStaking && stakeError === false && !needApproval) && "Stake"}
                  {(!isApproving && !isStaking && stakeError === false && needApproval) && "Approve"}
                </button>
              </div>
            </div>}
            {option === "unstake" &&
            <div>
              <div className="Stake-max-value clickable plain" onClick={() => setUnstakeQuantity(formatArrayAmount(stakeInfo, farmBalanceIndex)) }>
                Max {formatArrayAmount(stakeInfo, farmBalanceIndex)}
              </div>
              <div>
                <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={unstakeQuantity}
                  onChange={(e) => setUnstakeQuantity(e.target.value) } />
              </div>
              <div>
                <button type="button" className="Stake-button-primary Stake-button-primary-xlge" disabled={unstakeError || isUnstaking} onClick={() => unstake() }>
                  {isUnstaking && "Unstaking..."}
                  {(!isUnstaking && unstakeError !== false) && unstakeError}
                  {(!isUnstaking && unstakeError === false) && "Unstake"}
                </button>
              </div>
            </div>}
          </div>
        </div>
      }
    </div>
  )
}

export default function Stake() {
  const { account, library, active } = useWeb3React()
  const [activatingConnector, setActivatingConnector] = React.useState()
  const { connector } = useWeb3React()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  const [isBurnVaultModalVisible, setIsBurnVaultModalVisible] = useState(false)
  const [isTimeVaultModalVisible, setIsTimeVaultModalVisible] = useState(false)

  const [isXlgeModalVisible, setIsXlgeModalVisible] = useState(false)
  const [xlgeOption, setXlgeOption] = useState("")
  const [isUniModalVisible, setIsUniModalVisible] = useState(false)
  const [uniOption, setUniOption] = useState("")
  const [isX2fsModalVisible, setIsX2fsModalVisible] = useState(false)
  const [x2fsOption, setX2fsOption] = useState("")

  const xvixEthUniPair = getContract(CHAIN_ID, "XvixEthUniPair")
  const timeVaultV2 = getContract(CHAIN_ID, "TimeVaultV2")
  const burnVaultV2 = getContract(CHAIN_ID, "BurnVaultV2")
  const xlgeFarm = getContract(CHAIN_ID, "XlgeFarm")
  const uniFarm = getContract(CHAIN_ID, "UniFarm")
  const distributor = getContract(CHAIN_ID, "X2Distributor")
  const stakeReader = getContract(CHAIN_ID, "X2StakeReader")
  const ETH_USD_3X_X2FS_FARM = getContract(CHAIN_ID, "ETH_USD_3X_X2FS_FARM")
  const ETH_USD_3X_X2FS = getContract(CHAIN_ID, "ETH_USD_3X_X2FS")

  const { data: stakeInfo, mutate: updateStakeInfo } = useSWR([active, stakeReader, 'getStakeInfo', xlgeFarm, uniFarm, burnVaultV2, timeVaultV2, XLGE_WETH_ADDRESS, xvixEthUniPair, XVIX_ADDRESS, WETH_ADDRESS, account], {
    fetcher: fetcher(library, X2StakeReader),
  })

  const { data: x2fsStakeInfo, mutate: updateX2fsStakeInfo } = useSWR([active, stakeReader, 'getTokenInfo', ETH_USD_3X_X2FS_FARM, ETH_USD_3X_X2FS, account], {
    fetcher: fetcher(library, X2StakeReader),
  })

  const { data: xlgeRewards, mutate: updateXlgeRewards } = useSWR([active, stakeReader, 'getRewards', xlgeFarm, account, distributor], {
    fetcher: fetcher(library, X2StakeReader),
  })

  const { data: uniRewards, mutate: updateUniRewards } = useSWR([active, stakeReader, 'getRewards', uniFarm, account, distributor], {
    fetcher: fetcher(library, X2StakeReader),
  })

  const { data: burnRewards, mutate: updateBurnRewards } = useSWR([active, stakeReader, 'getRawRewards', burnVaultV2, account, distributor], {
    fetcher: fetcher(library, X2StakeReader),
  })

  const { data: timeRewards, mutate: updateTimeRewards } = useSWR([active, stakeReader, 'getRewards', timeVaultV2, account, distributor], {
    fetcher: fetcher(library, X2StakeReader),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateStakeInfo(undefined, true)
        updateXlgeRewards(undefined, true)
        updateUniRewards(undefined, true)
        updateBurnRewards(undefined, true)
        updateTimeRewards(undefined, true)
        updateX2fsStakeInfo(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateStakeInfo,
      updateXlgeRewards, updateUniRewards, updateBurnRewards,
      updateTimeRewards, updateX2fsStakeInfo])

  let xvixBurnVaultAvailableBalance

  if (stakeInfo) {
    xvixBurnVaultAvailableBalance = stakeInfo[6].add(stakeInfo[1])
  }

  let xvixTimeVaultAvailableBalance
  if (stakeInfo) {
    xvixTimeVaultAvailableBalance = stakeInfo[6].add(stakeInfo[0])
  }

  let xlgeTotalBalance
  if (stakeInfo) {
    xlgeTotalBalance = stakeInfo[7].add(stakeInfo[8])
  }

  let uniTotalBalance
  if (stakeInfo) {
    uniTotalBalance = stakeInfo[10].add(stakeInfo[11])
  }

  let x2fsTotalBalance
  if (x2fsStakeInfo) {
    x2fsTotalBalance = x2fsStakeInfo[1].add(x2fsStakeInfo[2])
  }

  const claim = async (farm) => {
    const contract = new ethers.Contract(farm, Farm.abi, library.getSigner())
    contract.claim(account)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Claim submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Claim failed.")
      })
  }

  return(
    <div className="Stake Page-content">
      <StakeOptionModal
        tokenName="XLGE:ETH"
        tokenAddress={XLGE_WETH_ADDRESS}
        farmAddress={xlgeFarm}
        stakeInfo={stakeInfo}
        tokenBalanceIndex={7}
        farmBalanceIndex={8}
        farmAllowanceIndex={9}
        isModalVisible={isXlgeModalVisible}
        setIsModalVisible={setIsXlgeModalVisible}
        option={xlgeOption}
        setOption={setXlgeOption}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
      />
      <StakeOptionModal
        tokenName="XVIX/ETH"
        tokenAddress={xvixEthUniPair}
        farmAddress={uniFarm}
        stakeInfo={stakeInfo}
        tokenBalanceIndex={10}
        farmBalanceIndex={11}
        farmAllowanceIndex={12}
        isModalVisible={isUniModalVisible}
        setIsModalVisible={setIsUniModalVisible}
        option={uniOption}
        setOption={setUniOption}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
      />
      <StakeOptionModal
        tokenName="X2FS"
        tokenAddress={ETH_USD_3X_X2FS}
        farmAddress={ETH_USD_3X_X2FS_FARM}
        stakeInfo={x2fsStakeInfo}
        tokenBalanceIndex={1}
        farmBalanceIndex={2}
        farmAllowanceIndex={3}
        isModalVisible={isX2fsModalVisible}
        setIsModalVisible={setIsX2fsModalVisible}
        option={x2fsOption}
        setOption={setX2fsOption}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
      />
      {isBurnVaultModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsBurnVaultModalVisible(false) }></div>
          <div className="modal-box Stake-modal">
            <div className="Stake-modal-top">
              <div className="Stake-title Stake-modal-title">BURN REDUCTION VAULT</div>
              <div className="Stake-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsBurnVaultModalVisible(false) } />
              </div>
            </div>
            <br/>
            <div>
              XVIX tokens deposited in the <strong>Burn Reduction Vault</strong> are automatically staked
              <br/>
              <br/>
              IMPORTANT: If your tokens are in the <strong>Burn Reduction Vault (Old)</strong> they are NOT staked
              <br/>
              <br/>
              You can deposit (stake) and withdraw (unstake) your tokens through the <Link className="simple" to="/dashboard">Dashboard</Link>
            </div>
          </div>
        </div>
      }
      {isTimeVaultModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsTimeVaultModalVisible(false) }></div>
          <div className="modal-box Stake-modal">
            <div className="Stake-modal-top">
              <div className="Stake-title Stake-modal-title">TIMELOCK VAULT</div>
              <div className="Stake-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsTimeVaultModalVisible(false) } />
              </div>
            </div>
            <br/>
            <div>
              XVIX tokens deposited in the <strong>Timelock Vault</strong> are automatically staked
              <br/>
              <br/>
              IMPORTANT: If your tokens are in the <strong>Timelock Vault (Old)</strong> they are NOT staked
              <br/>
              <br/>
              You can deposit and withdraw (unstake) your tokens through the <Link className="simple" to="/dashboard">Dashboard</Link>
            </div>
          </div>
        </div>
      }
      <div className="Box">
        <div>
          <table className="Stake-title-box">
            <tbody>
              <tr>
                <td>
                  <div className="Stake-title">
                    Stake
                  </div>
                </td>
                <td>
                  <X2Login totalCount={totalCount} setTotalCount={setTotalCount} />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            The <Link to="/migrate">
              GMX migration
            </Link> is in progress, please migrate your XVIX, XVIX/ETH and XLGE tokens.
          </div>
          <div>
            Please note that migrations will be processed only for tokens bought before 14 Jul 2021.
          </div>
          <div className="Stake-card-container">
            <div className="Stake-card uni">
              <div className="Stake-card-title">XVIX/ETH UNI</div>
              <div className="Stake-card-total-staked">Total: {formatArrayAmount(stakeInfo, 5, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Your Tokens: {formatArrayAmount(stakeInfo, 11, 1, 2)} / {formatAmount(uniTotalBalance, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Rewards: {formatArrayAmount(uniRewards, 1)} ETH</div>
              <div className="Stake-card-options">
                <div className="Stake-card-option" onClick={ () => { setUniOption("unstake"); setIsUniModalVisible(true) } }>Unstake</div>
                <div className="Stake-card-option" onClick={ () => { claim(uniFarm) } }>Claim</div>
              </div>
            </div>
            <div className="Stake-card xlge">
              <div className="Stake-card-title">XLGE:ETH</div>
              <div className="Stake-card-total-staked">Total: {formatArrayAmount(stakeInfo, 4, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Your Tokens: {formatArrayAmount(stakeInfo, 8, 1, 2)} / {formatAmount(xlgeTotalBalance, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Rewards: {formatArrayAmount(xlgeRewards, 1)} ETH</div>
              <div className="Stake-card-options">
                <div className="Stake-card-option" onClick={ () => { setXlgeOption("unstake"); setIsXlgeModalVisible(true) } }>Unstake</div>
                <div className="Stake-card-option" onClick={ () => { claim(xlgeFarm) } }>Claim</div>
              </div>
            </div>
            <div className="Stake-card burn">
              <div className="Stake-card-title">BURN REDUCTION VAULT</div>
              <div className="Stake-card-total-staked">Total: {formatArrayAmount(stakeInfo, 3, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Your Tokens: {formatArrayAmount(stakeInfo, 1, 1, 2)} / {formatAmount(xvixBurnVaultAvailableBalance, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Rewards: {formatArrayAmount(burnRewards, 1, 1, 4)} ETH</div>
              <div className="Stake-card-options">
                <div className="Stake-card-option" onClick={ () => { setIsBurnVaultModalVisible(true) } }>Unstake</div>
                <div className="Stake-card-option" onClick={ () => { claim(burnVaultV2) } }>Claim</div>
              </div>
            </div>
            <div className="Stake-card time">
              <div className="Stake-card-title">TIMELOCK VAULT</div>
              <div className="Stake-card-total-staked">Total: {formatArrayAmount(stakeInfo, 2, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Your Tokens: {formatArrayAmount(stakeInfo, 0, 1, 2)} / {formatAmount(xvixTimeVaultAvailableBalance, 1, 2)} Staked</div>
              <div className="Stake-card-total-staked">Rewards: {formatArrayAmount(timeRewards, 1, 1, 4)} ETH</div>
              <div className="Stake-card-options">
                <div className="Stake-card-option" onClick={ () => { setIsTimeVaultModalVisible(true) } }>Unstake</div>
                <div className="Stake-card-option" onClick={ () => { claim(timeVaultV2) } }>Claim</div>
              </div>
            </div>
          </div>
          <div>
            * Tokens in regular wallets have a burn rate of 0.02% per hour
          </div>
          <br/>
          <div className="hr Stake-hr"></div>
          <br/>
          <div className="Stake-x2fs-info">
            Rewards will vary based on the trading volume of X2<br/>
            Please take this into account before staking<br/>
          </div>
          <div className="Stake-card x2fs">
            <div className="Stake-card-title">3X ETH/USD X2FS</div>
            <div className="Stake-card-fee">Receives 5% of 3X ETH/USD X2 Fees</div>
            <div className="Stake-card-yield">Annual Rewards: 0.00 ETH per X2FS</div>
            <div className="Stake-card-total-staked">Total: {formatArrayAmount(x2fsStakeInfo, 0, 1, 2)} Staked</div>
            <div className="Stake-card-total-staked">Your Tokens: {formatArrayAmount(x2fsStakeInfo, 2, 1, 2)} / {formatAmount(x2fsTotalBalance, 1, 2)} Staked</div>
            <div className="Stake-card-options">
              <div className="Stake-card-option" onClick={ () => { setX2fsOption("stake"); setIsX2fsModalVisible(true) } }>Stake</div>
              <div className="Stake-card-option" onClick={ () => { setX2fsOption("unstake"); setIsX2fsModalVisible(true) } }>Unstake</div>
            </div>
          </div>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  )
}
