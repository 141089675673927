import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useLocalStorage } from './Storage'
import useSWR from 'swr'
import { toast } from 'react-toastify'
import cx from "classnames";
import ethers from 'ethers'
import { Link, useParams, useHistory } from 'react-router-dom'

import './Exchange.css'
import { FaRegGem } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { BsInfoCircleFill } from 'react-icons/bs'
import { AreaChart, Area, Tooltip, YAxis } from 'recharts'
import { IoMdSwap } from 'react-icons/io'
import { GiTwoCoins } from 'react-icons/gi'
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import { BsCaretDownFill } from 'react-icons/bs'

import X2ETHReader from './abis/X2ETHReader.json'
import X2ETHMarket from './abis/X2ETHMarket.json'
import X2ETHToken from './abis/X2ETHToken.json'

import ChainlinkLogo from './img/chainlink.png'

import { getContract } from './Addresses'
import { getMarket, getMarkets, getMarketUnit } from './Markets'
import { formatAmount, formatAmountFree, formatArrayAmount, formatPriceFeed, getTokenUrl,
  getInjectedConnector, getExplorerUrl, fetcher,
  useEagerConnect, useInactiveListener, formatDateTime, useOutsideClick } from './Helpers'

const TOTAL_TX_KEY = "totalTxKey:"
const CHAIN_ID = 1

const MAX_QTY_POINTS = ethers.BigNumber.from(10).pow(30)
const DUST_AMOUNT = "100000000000000"

function getMinutesRemaining(dateTime) {
    const now = parseInt(Date.now() / 1000)
    const diff = parseInt(dateTime.toNumber() - now)
    const minutes = parseInt(diff / 60)
    const seconds = parseInt(diff - minutes * 60)
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds
    return `${minutes}:${secondsStr}`
}

export function getTotalTxKey(account) {
  if (!account) {
    return TOTAL_TX_KEY
  }
  return TOTAL_TX_KEY + account
}

async function incrementTotalTxCount(library, account, props) {
  const txCount = await library.getTransactionCount(account)
  if (txCount > props.totalCount) {
    props.setTotalCount(txCount + 1)
    return
  }

  props.setTotalCount(props.totalCount + 1)
}

const PriceTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <div className="Exchange-price-tooltip">
        <div>{formatDateTime(payload[0].payload.name)}</div>
        <div>{payload[0].payload.price} {getMarketUnit(payload[0].payload.market)}</div>
      </div>
    );
  }

  return null;
};

const ExchangeWidget = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const { marketAddress } = props
  const market = getMarket(CHAIN_ID, marketAddress)
  const markets = getMarkets(CHAIN_ID)
  const history = useHistory()
  const [favMarkets, setFavMarkets] = useLocalStorage("exchangeFavMarkets", {})
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [pendingBullTimeRemaining, setPendingBullTimeRemaining] = useState("")
  const [pendingBearTimeRemaining, setPendingBearTimeRemaining] = useState("")
  const [isSwapping, setIsSwapping] = useState(false)
  const [isFlippingAllBull, setIsFlippingAllBull] = useState(false)
  const [isFlippingAllBear, setIsFlippingAllBear] = useState(false)
  const [isSellingAllBull, setIsSellingAllBull] = useState(false)
  const [isSellingAllBear, setIsSellingAllBear] = useState(false)
  const [isClaimingBull, setIsClaimingBull] = useState(false)
  const [isClaimingBear, setIsClaimingBear] = useState(false)
  const [isBull, setIsBull] = useState(true)
  const [isBuying, setIsBuying] = useState(true)
  const { totalCount, setTotalCount } = props
  const [quantity, setQuantity] = useState("")
  const token = isBull ? market.bullToken : market.bearToken

  const favMarketsList = Object.keys(favMarkets).map((marketAddress) => {
    return getMarket(CHAIN_ID, marketAddress)
  })

  const newMarkets = {}
  const newMarketsList = Object.keys(newMarkets).map((marketAddress) => {
    return getMarket(CHAIN_ID, marketAddress)
  })

  const filteredMarkets = []
  for (let i = 0; i < markets.length; i++) {
    const m = markets[i]
    if (favMarkets[m.address] || newMarkets[m.address]) {
      continue
    }
    filteredMarkets.push(m)
  }

  const { data: balanceInfo, mutate: updateBalanceInfo } = useSWR([active, getContract(CHAIN_ID, "X2ETHReader"), "getBalanceInfo", market.address, account], {
    fetcher: fetcher(library, X2ETHReader),
  })

  const { data: bullRewards, mutate: updateBullRewards } = useSWR([active, getContract(CHAIN_ID, "X2ETHReader"), "getRewards", market.bullToken, account], {
    fetcher: fetcher(library, X2ETHReader),
  })

  const { data: previousBullRewards, mutate: updatePreviousBullRewards } = useSWR([active, market.bullToken, "rewards", account], {
    fetcher: fetcher(library, X2ETHToken),
  })

  const { data: bearRewards, mutate: updateBearRewards } = useSWR([active, getContract(CHAIN_ID, "X2ETHReader"), "getRewards", market.bearToken, account], {
    fetcher: fetcher(library, X2ETHReader),
  })

  const { data: previousBearRewards, mutate: updatePreviousBearRewards } = useSWR([active, market.bearToken, "rewards", account], {
    fetcher: fetcher(library, X2ETHToken),
  })

  let maxValue
  if (balanceInfo) {
    if (isBuying) {
      maxValue = balanceInfo[0]
    } else {
      maxValue = isBull ? balanceInfo[1] : balanceInfo[2]
    }
  }

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateBalanceInfo(undefined, true)
        updateBullRewards(undefined, true)
        updateBearRewards(undefined, true)
        updatePreviousBullRewards(undefined, true)
        updatePreviousBearRewards(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateBalanceInfo,
      updatePreviousBullRewards, updatePreviousBearRewards,
      updateBullRewards, updateBearRewards])

  const fromUnit = isBuying ? "ETH" : (isBull ? "BULL" : "BEAR")
  const toUnit = isBuying ? (isBull ? "BULL" : "BEAR") : "ETH"

  const parsedQuantity = parseFloat(quantity)
  let quantityInWei
  let quantityAfterFeesInWei
  let receiveStr = "0"
  let feeQty = ethers.BigNumber.from(0)

  if (!isNaN(parsedQuantity)) {
    receiveStr = parsedQuantity
    quantityInWei = ethers.utils.parseEther(parsedQuantity.toString())
    quantityAfterFeesInWei = quantityInWei.mul(9980).div(10000)
    feeQty = quantityInWei.sub(quantityAfterFeesInWei)
    receiveStr = formatAmountFree(quantityAfterFeesInWei)
  }

  let error
  if (isNaN(parsedQuantity)) {
    error = "Invalid quantity"
  }
  if (quantity === "" || (quantityInWei && quantityInWei.eq(0))) {
    error = "Enter an amount"
  }
  if (quantityInWei && maxValue && quantityInWei.gt(maxValue)) {
    error = `Insufficient ${fromUnit}`
  }

  let bullBalance = ethers.BigNumber.from(0)
  let bearBalance = ethers.BigNumber.from(0)

  if (balanceInfo) {
    bullBalance = balanceInfo[1]
    bearBalance = balanceInfo[2]
  }

  let bullDelta = "+0.0000"
  let bullDeltaPercentage = "+0.00%"
  let hasBullTokens = false
  if (balanceInfo && balanceInfo[1] && balanceInfo[7] && balanceInfo[7].gt(DUST_AMOUNT)) {
    hasBullTokens = true
    if (balanceInfo[1].gte(balanceInfo[7])) {
      const delta = balanceInfo[1].sub(balanceInfo[7])
      bullDelta = `+${formatAmount(delta)}`
      bullDeltaPercentage = `+${((delta.mul(10000).div(balanceInfo[7]).toNumber()) / 100).toFixed(2)}%`
    } else {
      const delta = balanceInfo[7].sub(balanceInfo[1])
      bullDelta = `-${formatAmount(delta)}`
      bullDeltaPercentage = `-${((delta.mul(10000).div(balanceInfo[7]).toNumber()) / 100).toFixed(2)}%`
    }
  }

  let bearDelta = "+0.0000"
  let bearDeltaPercentage = "+0.00%"
  let hasBearTokens = false
  if (balanceInfo && balanceInfo[2] && balanceInfo[8] && balanceInfo[8].gt(DUST_AMOUNT)) {
    hasBearTokens = true
    if (balanceInfo[2].gte(balanceInfo[8])) {
      const delta = balanceInfo[2].sub(balanceInfo[8])
      bearDelta = `+${formatAmount(delta)}`
      bearDeltaPercentage = `+${((delta.mul(10000).div(balanceInfo[8]).toNumber()) / 100).toFixed(2)}%`
    } else {
      const delta = balanceInfo[8].sub(balanceInfo[2])
      bearDelta = `-${formatAmount(delta)}`
      bearDeltaPercentage = `-${((delta.mul(10000).div(balanceInfo[8]).toNumber()) / 100).toFixed(2)}%`
    }
  }

  let bullLastBoughtAt
  let bearLastBoughtAt
  let pendingBullProfit
  let pendingBearProfit

  if (balanceInfo) {
    bullLastBoughtAt = balanceInfo[3]
    bearLastBoughtAt = balanceInfo[4]
    pendingBullProfit = balanceInfo[5]
    pendingBearProfit = balanceInfo[6]
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (pendingBullProfit && pendingBullProfit.gt(DUST_AMOUNT)) {
        setPendingBullTimeRemaining(getMinutesRemaining(bullLastBoughtAt.add(60 * 10)))
      } else {
        setPendingBearTimeRemaining("")
      }
      if (pendingBearProfit && pendingBearProfit.gt(DUST_AMOUNT)) {
        setPendingBearTimeRemaining(getMinutesRemaining(bearLastBoughtAt.add(60 * 10)))
      } else {
        setPendingBearTimeRemaining("")
      }
    }, 1000)
    return () => clearInterval(interval);
  }, [pendingBullProfit, pendingBearProfit, bullLastBoughtAt, bearLastBoughtAt])

  let bullAPR = "*"
  let bearAPR = "*"
  let bullRewardAmount = ethers.BigNumber.from(0)
  let bearRewardAmount = ethers.BigNumber.from(0)

  const hoursPerYear = 8760

  if (bullRewards) {
    let divisor = bullRewards[2]
    if (divisor.eq(0)) {
      divisor = ethers.utils.parseEther("1")
    }
    const rewardPerYear = bullRewards[0].mul(hoursPerYear).mul(10000)
    bullAPR = `${(parseFloat(rewardPerYear.div(divisor).toNumber()) / 100).toFixed(2)}%`
    bullRewardAmount = bullRewards[1]

    if (previousBullRewards && bullRewards[5].gt(0)) {
      const claimable = bullRewards[3].mul(bullRewards[4].sub(previousBullRewards[0])).div(bullRewards[5])
      bullRewardAmount = bullRewardAmount.add(claimable)
    }
  }

  if (bearRewards) {
    let divisor = bearRewards[2]
    if (divisor.eq(0)) {
      divisor = ethers.utils.parseEther("1")
    }
    const rewardPerYear = bearRewards[0].mul(hoursPerYear).mul(10000)
    bearAPR = `${(parseFloat(rewardPerYear.div(divisor).toNumber()) / 100).toFixed(2)}%`
    bearRewardAmount = bearRewards[1]

    if (previousBearRewards && bearRewards[5].gt(0)) {
      const claimable = bearRewards[3].mul(bearRewards[4].sub(previousBearRewards[0])).div(bearRewards[5])
      bearRewardAmount = bearRewardAmount.add(claimable)
    }
  }

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  const onClickPrimary = async () => {
    if (!active) {
      activateMetamask()
      return
    }
    if (chainId !== CHAIN_ID) {
      toast.error("Incorrect network, please select \"Ethereum Mainnet\" in Metamask")
      return
    }
    setIsSwapping(true)
    const contract = new ethers.Contract(market.address, X2ETHMarket.abi, library.getSigner())
    let qtyPoints
    if (!isBuying) {
      qtyPoints = quantityInWei.mul(MAX_QTY_POINTS).div(isBull ? balanceInfo[1] : balanceInfo[2])
    }
    if (!isBuying && quantityInWei.gt(maxValue.mul(9990).div(10000))) {
      qtyPoints = MAX_QTY_POINTS
    }
    if (qtyPoints && qtyPoints.gt(MAX_QTY_POINTS)) {
      qtyPoints = MAX_QTY_POINTS
    }
    let method = isBuying ? "buy" : "sell"
    let params = isBuying ? [token, ethers.constants.AddressZero, { value: quantityInWei }] : [token, qtyPoints, account, ethers.constants.AddressZero]

    let gasLimit = await contract.estimateGas[method](...params)
    if (gasLimit.lt(150000)) {
      gasLimit = ethers.BigNumber.from(150000)
      if (isBuying) {
        params[2].gasLimit = gasLimit
      } else {
        params.push({ gasLimit })
      }
    }

    contract[method](...params)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Swap submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Swap failed.")
      })
      .finally(() => {
        setIsSwapping(false)
      })
  }

  const flipAll = async (flipAllBull) => {
    if (chainId !== CHAIN_ID) {
      toast.error("Incorrect network, please select \"Ethereum Mainnet\" in Metamask")
      return
    }
    if (flipAllBull && (!balanceInfo || balanceInfo[1].eq(0))) {
      toast.error("You need to buy some BULL tokens first")
      return
    }
    if (!flipAllBull && (!balanceInfo || balanceInfo[2].eq(0))) {
      toast.error("You need to buy some BEAR tokens first")
      return
    }

    if (flipAllBull) {
      setIsFlippingAllBull(true)
    } else {
      setIsFlippingAllBear(true)
    }

    const contract = new ethers.Contract(market.address, X2ETHMarket.abi, library.getSigner())
    const params = [flipAllBull ? market.bullToken : market.bearToken, MAX_QTY_POINTS, ethers.constants.AddressZero]

    let gasLimit = await contract.estimateGas.flip(...params)
    if (gasLimit.lt(180000)) {
      gasLimit = ethers.BigNumber.from(180000)
      params.push({ gasLimit })
    }

    contract.flip(...params)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Flip submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Flip failed.")
      })
      .finally(() => {
        setIsFlippingAllBull(false)
        setIsFlippingAllBear(false)
      })
  }

  const sellAll = async (sellAllBull) => {
    if (chainId !== CHAIN_ID) {
      toast.error("Incorrect network, please select \"Ethereum Mainnet\" in Metamask")
      return
    }
    if (sellAllBull && (!balanceInfo || balanceInfo[1].eq(0))) {
      toast.error("You need to buy some BULL tokens first")
      return
    }
    if (!sellAll && (!balanceInfo || balanceInfo[2].eq(0))) {
      toast.error("You need to buy some BEAR tokens first")
      return
    }

    if (sellAll) {
      setIsSellingAllBull(true)
    } else {
      setIsSellingAllBear(true)
    }

    const contract = new ethers.Contract(market.address, X2ETHMarket.abi, library.getSigner())
    const params = [sellAllBull ? market.bullToken : market.bearToken, MAX_QTY_POINTS, account, ethers.constants.AddressZero]

    let gasLimit = await contract.estimateGas.sell(...params)
    if (gasLimit.lt(150000)) {
      gasLimit = ethers.BigNumber.from(150000)
      params.push({ gasLimit })
    }

    contract.sell(...params)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Sell submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Sell failed.")
      })
      .finally(() => {
        setIsSellingAllBull(false)
        setIsSellingAllBear(false)
      })
  }

  const claimRewards = async (claimBull) => {
    if (chainId !== CHAIN_ID) {
      toast.error("Incorrect network, please select \"Ethereum Mainnet\" in Metamask")
      return
    }
    if (claimBull && (!bullRewardAmount || bullRewardAmount.eq(0))) {
      toast.error("No rewards yet")
      return
    }
    if (!claimBull && (!bearRewardAmount || bearRewardAmount.eq(0))) {
      toast.error("No rewards yet")
      return
    }

    if (claimBull) {
      setIsClaimingBull(true)
    } else {
      setIsClaimingBear(true)
    }

    const claimToken = claimBull ? market.bullToken : market.bearToken
    const contract = new ethers.Contract(claimToken, X2ETHToken.abi, library.getSigner())
    let gasLimit = await contract.estimateGas.claim(account)
    if (gasLimit.lt(120000)) {
      gasLimit = ethers.BigNumber.from(120000)
    }
    contract.claim(account, { gasLimit })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Claim submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Claim failed.")
      })
      .finally(() => {
        setIsClaimingBull(false)
        setIsClaimingBear(false)
      })
  }

  const handleFavMarketClick = () => {
    if (favMarkets[market.address]) {
      delete favMarkets[market.address]
    } else {
      favMarkets[market.address] = true
    }

    setFavMarkets(favMarkets)
    forceUpdate()
  }

  return (
    <div className="Exchange-widget-container">
      <div>
        <div className="Exchange-title Exchange-widget-top">
          <div className="bull bull-bear-button clickable" onClick={ () => setIsBull(true) }>
            <div className={cx("radial-option", { active: isBull })} >
              <div className="radial-option-center"></div>
            </div>
            BULL
          </div>
          <div className="bear bull-bear-button clickable" onClick={ () => setIsBull(false) }>
            <div className={cx("radial-option", { active: !isBull })} >
              <div className="radial-option-center"></div>
            </div>
            BEAR
          </div>
          <div className="Exchange-login-container">
            <ExchangeLogin totalCount={totalCount} setTotalCount={setTotalCount} />
          </div>
        </div>
        <div className={cx("Exchange-form Exchange-box", { bull: isBull, bear: !isBull })}>
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  <div className="Exchange-form-market-row">
                    <div>
                      Market
                    </div>
                    <div className="Exchange-form-star clickable plain" onClick={ handleFavMarketClick }>
                      {favMarkets[market.address] ?
                        <AiFillStar className="Exchange-form-star-icon" /> :
                        <AiOutlineStar className="Exchange-form-star-icon" />
                      }
                      Fav
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="Exchange-market-selector">
                    <select value={market.address} onChange={(e) => {
                      if (e.target.value === "eth_usd_3x_old") {
                        history.push(`/trade-v1/`)
                      } else {
                        props.setLastUsedMarket(e.target.value)
                        history.push(`/trade/${e.target.value}`)
                      }
                    }}>
                      {favMarketsList.length > 0 && <optgroup label="Favs">
                        {favMarketsList.map((m) => {
                          return <option value={m.address} key={m.address}>
                            {m.label} : {m.leverage}x
                          </option>
                        })}
                      </optgroup>}
                      {newMarketsList.length > 0 && <optgroup label="New">
                        {newMarketsList.map((m) => {
                          return <option value={m.address} key={m.address}>
                            {m.label} : {m.leverage}x
                          </option>
                        })}
                      </optgroup>}
                      <optgroup label="Markets">
                        {filteredMarkets.map((m) => {
                          return <option value={m.address} key={m.address}>
                            {m.label} : {m.leverage}x
                          </option>
                        })
                        }
                        <option value="eth_usd_3x_old">ETH/USD : 3X (OLD)</option>
                      </optgroup>
                    </select>
                    <BsCaretDownFill className="select-caret" />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="Exchange-form-pay-row">
                    <div>Pay</div>
                    {maxValue &&
                      <div className="Exchange-form-max align-right clickable plain" onClick={ () => setQuantity(formatAmount(maxValue)) }>
                        { `Max ${formatAmount(maxValue)} ${fromUnit}` }
                      </div>
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="input-outline" placeholder="0" value={quantity} onChange={(e) => setQuantity(e.target.value) } />
                </td>
                <td>
                  <div className="Exchange-asset-label align-center">
                    {fromUnit}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="align-bottom">
                  Receive
                </td>
                <td className="align-center compact">
                  <div className="Exchange-form-swap" onClick={() => setIsBuying(!isBuying)} >
                    <div className="Exchange-button-overlay"></div>
                    <div className="Exchange-button-content"><IoMdSwap className="Exchange-form-swap-icon"/></div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <input type="text" className="input-outline" placeholder="0" value={receiveStr} disabled={true} />
                </td>
                <td>
                  <div className="Exchange-asset-label align-center">
                    {toUnit}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="Exchange-reserve-row">
                    <div className="Exchange-fee-box">
                      Fee: {formatAmount(feeQty)}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <button type="button" className="button-primary" onClick={ () => onClickPrimary() } disabled={active && (error || isSwapping)}>
                    <div className="Exchange-button-overlay"></div>
                    <div className="Exchange-button-content">
                      {!active && "Connect Wallet"}
                      {(active && error) && error}
                      {(active && !error && !isSwapping) && "Swap"}
                      {isSwapping && "Swapping..."}
                    </div>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {(hasBullTokens || hasBearTokens) &&
        <div className={cx("Exchange-wallet-container", { bull: isBull, bear: !isBull })}>
          <div className="Exchange-wallet-title">
            <FaRegGem className="Exchange-wallet-title-icon" />
            Wallet
          </div>
          <div className={cx("Exchange-wallet-box Exchange-box", { bull: isBull, bear: !isBull })}>
            {hasBullTokens &&
              <div>
                <div className="clickable" onClick={() => { setIsBuying(false); setIsBull(true); setQuantity(formatAmount(bullBalance)) } }>
                  {formatAmount(bullBalance)} BULLs
                </div>
                {pendingBullTimeRemaining === "" &&
                  <div className="Exchange-bull-delta">{bullDelta} ETH ({bullDeltaPercentage})</div>
                }
                {pendingBullTimeRemaining !== "" &&
                  <div className="Exchange-bull-delta">+{formatAmount(pendingBullProfit)} ETH (Pending {pendingBullTimeRemaining})</div>
                }
                <div>
                  <div className="Exchange-sell-button-container">
                    <div className="Exchange-sell-filler bull"></div>
                    <div>
                      <button type="button" className="Exchange-sell-button bull" onClick={ () => flipAll(true) } disabled={isFlippingAllBull}>
                        {isFlippingAllBull && "Flipping..."}
                        {!isFlippingAllBull && "Flip to BEAR"}
                      </button>
                      <span className="Exchange-sell-divider bull">|</span>
                      <button type="button" className="Exchange-sell-button bull" onClick={ () => sellAll(true) } disabled={isSellingAllBull}>
                        {isSellingAllBull && "Selling..."}
                        {!isSellingAllBull && "Sell All"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {hasBearTokens &&
              <div>
                <div className="clickable" onClick={() => { setIsBuying(false); setIsBull(false); setQuantity(formatAmount(bearBalance)) } }>
                  {formatAmount(bearBalance)} BEARs
                </div>
                {pendingBearTimeRemaining === "" &&
                  <div className="Exchange-bear-delta">{bearDelta} ETH ({bearDeltaPercentage})</div>
                }
                {pendingBearTimeRemaining !== "" &&
                  <div className="Exchange-bear-delta">+{formatAmount(pendingBearProfit)} ETH (Pending {pendingBearTimeRemaining})</div>
                }
                <div>
                  <div className="Exchange-sell-button-container">
                    <div className="Exchange-sell-filler bear"></div>
                    <div>
                      <button type="button" className="Exchange-sell-button bear" onClick={ () => flipAll(false) } disabled={isFlippingAllBear}>
                        {isFlippingAllBear && "Flipping..."}
                        {!isFlippingAllBear && "Flip to BULL"}
                      </button>
                      <span className="Exchange-sell-divider bear">|</span>
                      <button type="button" className="Exchange-sell-button bear" onClick={ () => sellAll(false) } disabled={isSellingAllBear}>
                        {isSellingAllBear && "Selling..."}
                        {!isSellingAllBear && "Sell All"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      {(bullRewardAmount.gt(0) || bearRewardAmount.gt(0) || (bullRewards && bullRewards[0].gt(0)) || (bearRewards && bearRewards[0].gt(0))) && <div className={cx("Exchange-rewards-container", { bull: isBull, bear: !isBull })}>
        <div className="Exchange-rewards-title">
          <GiTwoCoins className="Exchange-rewards-title-icon" />
          Rewards
        </div>
        <div className={cx("Exchange-box Exchange-rewards-box", { bull: isBull, bear: !isBull })}>
          <div className="Exchange-rewards-info">
            ETH rewards are distributed to BULL / BEAR holders every hour
          </div>
          <div className="Exchange-rewards-bull">
            BULL APR: {bullAPR}
          </div>
          <div className="Exchange-rewards-bull">
            <div>
              Rewards: {formatAmount(bullRewardAmount)} WETH
            </div>
          </div>
          <div className="Exchange-rewards-row">
            <div className="Exchange-sell-filler bull"></div>
            <div className="align-right">
              <button type="button" className="Exchange-rewards-claim-button bull" onClick={ () => claimRewards(true) } disabled={isClaimingBull}>
                {isClaimingBull && "Claiming..."}
                {!isClaimingBull && "Claim"}
              </button>
            </div>
          </div>
          <div className="Exchange-rewards-separator"></div>
          <div className="Exchange-rewards-bear">
            BEAR APR: {bearAPR}
          </div>
          <div className="Exchange-rewards-bear">
            Rewards: {formatAmount(bearRewardAmount)} WETH
          </div>
          <div className="Exchange-rewards-row">
            <div className="Exchange-sell-filler bear"></div>
            <div className="align-right">
              <button type="button" className="Exchange-rewards-claim-button bear" onClick={ () => claimRewards(false) } disabled={isClaimingBear}>
                {isClaimingBear && "Claiming..."}
                {!isClaimingBear && "Claim"}
              </button>
            </div>
          </div>
        </div>
      </div>}
      <br/>
      <br/>
    </div>
  )
}

const ExchangeLogin = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="Exchange-login">
      {!active &&
        <button type="button" onClick={activateMetamask}>
          CONNECT
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer" className={cx("Exchange-pending-tx", { active: pending > 0 })}>
          {pending === 1 && "1 Tx"}
          {pending !== 1 && `${pending} Txs`}
        </a>
      }
    </div>
  )
}

const ExchangeMenu = (props) => {
  const menuRef = useRef()
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const { marketAddress } = props
  const market = getMarket(CHAIN_ID, marketAddress)
  const markets = getMarkets(CHAIN_ID)

  useOutsideClick(menuRef, () => {
    setIsDropdownVisible(false)
  })

  return (
    <div>
    {isMenuVisible &&
      <div className="modal-container">
        <div className="modal-backdrop" onClick={ () => setIsMenuVisible(false) }></div>
        <div className="modal-box Exchange-markets-menu">
          <div className="Exchange-markets-menu-overlay"></div>
          <div className="Exchange-markets-menu-content">
            <div className="Exchange-markets-menu-title">
              <div className="Exchange-markets-menu-subtitle">Active Markets</div>
              <div className="align-right" >
                <ImCross className="clickable Exchange-markets-close" onClick={ () => setIsMenuVisible(false) } />
              </div>
            </div>
            <div>
              <Link to="/trade" className="Exchange-markets-menu-market" onClick={ () => setIsMenuVisible(false) }>3X ETH/USD</Link>
            </div>
            <br/>
            <div className="Exchange-markets-menu-title">
              <div className="Exchange-markets-menu-subtitle">Inactive Markets</div>
            </div>
            <div>
              <Link to="/beta-trade" className="Exchange-markets-menu-market">10X ETH/USD</Link>
            </div>
            <br/>
            <div className="Exchange-markets-menu-title">
              <div className="Exchange-markets-menu-subtitle">Learn More</div>
            </div>
            <div>
              <a href="https://xvi10.gitbook.io/xvix/x2" target="_blank" rel="noopener noreferrer" className="Exchange-markets-menu-market">
                BULL / BEAR Token Info
              </a>
            </div>
          </div>
        </div>
      </div>
    }
      <div className="Exchange-title Exchange-main-title" ref={menuRef}>
        <div className="Exchange-title-text">
          Trade {market.name} Tokens
        </div>
        {isDropdownVisible &&
        <div className="Exchange-dropdown-menu">
          {markets.map((m) =>
            <Link to={`/trade/${m.address}`} className="Exchange-dropdown-menu-item" key={m.address}>
              <div>{m.name}</div>
            </Link>)
          }
          <Link to="/trade-v1" className="Exchange-dropdown-menu-item">3X ETH/USD (Old)</Link>
        </div>
        }
      </div>
    </div>
  )
}

export default function Exchange() {
  const [lastUsedMarket, setLastUsedMarket] = useLocalStorage("exchangeLastUsedMarket", "")
  let { marketAddress } = useParams()

  if (!marketAddress || marketAddress.length === 0) {
    marketAddress = lastUsedMarket
  }
  const market = getMarket(CHAIN_ID, marketAddress)
  const [chartWidth, setChartWidth] = useState(520)
  const [chartHeight, setChartHeight] = useState(335)
  const [isWarningVisible, setIsWarningVisible] = useLocalStorage("exchangeWarningVisible", true)
  const [isMoreInfoVisible, setIsMoreInfoVisiable] = useState(false)
  const [days, setDays] = useState(1)
  const url = `https://cors-300607.uc.r.appspot.com/price?market=${market.address}&days=${days}`
  const { data: prices, mutate: updatePrices } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then(res => res.json())
  })

  const { active, account, library } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)

  const { data: marketInfo, mutate: updateMarketInfo } = useSWR([active, getContract(CHAIN_ID, "X2ETHReader"), "getMarketInfo", market.address], {
    fetcher: fetcher(library, X2ETHReader),
  })

  let bullFunding
  let bearFunding

  if (marketInfo) {
    try {
      if (marketInfo[3].gt(0)) {
        bullFunding = "-" + parseFloat(marketInfo[3].mul(1000000).div(marketInfo[1]).toNumber() / 10000).toFixed(4)
        bearFunding = "+" + parseFloat(marketInfo[3].mul(1000000).div(marketInfo[2]).toNumber() / 10000).toFixed(4)
      }
      if (marketInfo[4].gt(0)) {
        bullFunding = "+" + parseFloat(marketInfo[4].mul(1000000).div(marketInfo[1]).toNumber() / 10000).toFixed(4)
        bearFunding = "-" + parseFloat(marketInfo[4].mul(1000000).div(marketInfo[2]).toNumber() / 10000).toFixed(4)
      }
    } catch (e) {
      console.error(e)
    }
  }

  let lastPrice

  const [activatingConnector, setActivatingConnector] = React.useState()
  const { connector, activate } = useWeb3React()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  useEffect(() => {
    const interval = setInterval(() => {
      updatePrices(undefined, true)
    }, 60 * 1000)
    return () => clearInterval(interval);
  }, [updatePrices])

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateMarketInfo(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateMarketInfo])

  let data
  let minPrice
  let maxPrice

  if (prices && prices.data && prices.data.result && prices.data.result[0]) {
    const priceData = prices.data.result[0].values
    if (marketInfo && marketInfo[0]) {
      priceData.push([parseInt(Date.now() / 1000), formatPriceFeed(marketInfo[0], 7)])
    }
    let divisor = 1
    if (market && market.divisor) {
      divisor = market.divisor
    }
    let decimals = 2
    if (market && market.decimals !== undefined) {
      decimals = market.decimals
    }
    minPrice = Math.floor(Math.min(...priceData.map(v => v[1] / divisor)) * 0.9)
    maxPrice = Math.ceil(Math.max(...priceData.map(v => v[1] / divisor)))
    if (maxPrice > 100) {
      const delta = maxPrice - minPrice
      maxPrice = Math.ceil(maxPrice + delta / 10)
    }
    data = priceData.map((v) => {
      return {
        name: v[0],
        value: v[1] / divisor,
        price: parseFloat(v[1] / divisor).toFixed(decimals),
        market
      }
    })
    lastPrice = data[data.length - 1].price
  }

  let bullLev
  let bearLev

  let gradientMid0 = 45
  let gradientMid1 = 55
  if (marketInfo) {
    lastPrice = formatPriceFeed(marketInfo[0], 2, market)
    const bulls = marketInfo[1]
    const bears = marketInfo[2]
    if (bulls.gt(DUST_AMOUNT) && bears.gt(DUST_AMOUNT)) {
      if (bulls.gt(bears)) {
        bearLev = parseFloat(market.leverage).toFixed(2)
        bullLev = (bears.mul(market.leverage * 10000).div(bulls).toNumber() / 10000).toFixed(2)
      } else {
        bullLev = parseFloat(market.leverage).toFixed(2)
        bearLev = (bulls.mul(market.leverage * 10000).div(bears).toNumber() / 10000).toFixed(2)
      }

      gradientMid0 = parseInt(bulls.mul(10000).div(bulls.add(bears)).toNumber() / 100)
      gradientMid1 = gradientMid0 + 10
      if (gradientMid0 < 0) {
        gradientMid0 = 0
        gradientMid1 = 5
      }
      if (gradientMid0 > 95) {
        gradientMid0 = 95
        gradientMid1 = 100
      }
    }

    if (bulls.gt(0) && bears.eq(0)) {
        gradientMid0 = 95
        gradientMid1 = 100
    }
    if (bears.gt(0) && bulls.eq(0)) {
        gradientMid0 = 0
        gradientMid1 = 5
    }
  }

  const barGradient = `linear-gradient(90deg, rgba(192,103,230,1) 0%, rgba(192,103,230,1) ${gradientMid0}%, rgba(93,109,252,1) ${gradientMid1}%, rgba(93,109,252,1) 100%)`

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 950) {
        setChartWidth(520 - (950 - window.innerWidth))
      }
      if (window.innerWidth < 700) {
        setChartWidth(window.innerWidth - 80)
        setChartHeight(200)
      }
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  const bullTokenURL = getTokenUrl(CHAIN_ID, market.bullToken)
  const bearTokenURL = getTokenUrl(CHAIN_ID, market.bearToken)

  return(
    <div className="Exchange Page-content">
      <div>
        <div className="Exchange-main">
          <div>
            {isWarningVisible &&
              <div className="Exchange-warning-box">
                <div>
                  The X2 protocol is in beta, use at your own risk.
                  <br/>
                  Read about&nbsp;
                  <a href="https://xvi10.gitbook.io/xvix/x2" target="_blank" rel="noopener noreferrer" className="simple">
                    leverage tokens
                  </a> before buying.
                </div>
                <div className="align-right">
                  <ImCross className="Exchange-warning-close-icon" onClick={ () => setIsWarningVisible(false) } />
                </div>
              </div>
            }
            <ExchangeMenu marketAddress={marketAddress} />
            <div className="Exchange-main-price">{lastPrice} {getMarketUnit(market)}</div>
            <div className="Exchange-chart-container">
              <AreaChart width={chartWidth} height={chartHeight} data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="priceStrokeColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#c067e6" stopOpacity={1}/>
                    <stop offset="95%" stopColor="#5d6dfc" stopOpacity={1}/>
                  </linearGradient>
                  <linearGradient id="priceFillColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#c067e6" stopOpacity={0.3}/>
                    <stop offset="95%" stopColor="#5d6dfc" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <Tooltip content={ PriceTooltip } />
                <YAxis type="number" domain={[minPrice, maxPrice]} hide={true} />
                <Area type="natural" dataKey="value" stroke="url(#priceStrokeColor)" strokeWidth="2" fillOpacity={1} fill="url(#priceFillColor)" />
              </AreaChart>
              <div className="Exchange-chart-options">
                <div className={cx("Exchange-chart-option", { active: days === 1 })} onClick={ () => setDays(1) }>24H</div>
                <div className={cx("Exchange-chart-option", { active: days === 7 })} onClick={ () => setDays(7) }>1W</div>
                <div className={cx("Exchange-chart-option", { active: days === 28 })} onClick={ () => setDays(28) }>1M</div>
              </div>
            </div>
            <div className="Exchange-bull-bear-container">
              <div className="Exchange-bull-bear-bar" style={ { background: barGradient } }></div>
              <div className="Exchange-bull-bear-info">
                <div className="Exchange-bull-bear-info-row">
                  <a href={bullTokenURL} target="_blank" rel="noopener noreferrer" className="Exchange-info-total">
                    {formatArrayAmount(marketInfo, 1, 1, 2)} BULLs
                  </a>
                  <div className="Exchange-info-hr bull"></div>
                  <div className="Exchange-info-label">Total Positions</div>
                  <div className="Exchange-info-hr bear"></div>
                  <a href={bearTokenURL} target="_blank" rel="noopener noreferrer" className="Exchange-info-total">
                    {formatArrayAmount(marketInfo, 2, 1, 2)} BEARs
                  </a>
                </div>
                {bullLev && bearLev &&
                  <div className="Exchange-bull-bear-info-row">
                    <div className="Exchange-bull-bear-lev Exchange-info-bull">{bullLev}x</div>
                    <div className="Exchange-info-hr bull"></div>
                    <div className="Exchange-info-label">Leverage</div>
                    <div className="Exchange-info-hr bear"></div>
                    <div className="Exchange-bull-bear-lev Exchange-info-bear Exchange-info-bear">{bearLev}x</div>
                  </div>
                }
                {bullFunding && bearFunding &&
                  <div className="Exchange-bull-bear-info-row">
                    <div className="Exchange-funding Exchange-info-bull">{bullFunding}% / hour</div>
                    <div className="Exchange-info-hr bull"></div>
                    <div className="Exchange-info-label">Funding</div>
                    <div className="Exchange-info-hr bear"></div>
                    <div className="Exchange-funding Exchange-info-bear">{bearFunding}% / hour</div>
                  </div>
                }
              </div>
              {!active &&
                <div className="Exchange-bull-bear-connect-wallet">
                  * <strong className="simple clickable" onClick={ () => activateMetamask() }>Connect Wallet</strong> to view market stats
                </div>
              }
              <div className="Exchange-more-info clickable" onClick={ () => setIsMoreInfoVisiable(!isMoreInfoVisible) }>
                <BsInfoCircleFill className="Exchange-reserve-info" /> { isMoreInfoVisible ? "Less Info" : "More Info"}
              </div>
              {isMoreInfoVisible && <div>
                <div className="Exchange-info-read-more">
                  Read about&nbsp;
                  <a href="https://xvi10.gitbook.io/xvix/x2" target="_blank" rel="noopener noreferrer" className="simple">
                    leverage tokens
                  </a>
                </div>
                <div className="Exchange-market-sponsor">
                  {market.note.content} <a href={market.note.url} target="_blank" rel="noopener noreferrer">
                  {market.note.creator}
                  </a>
                </div>
              </div>}
              <br/>
              <br/>
            </div>
            <div className="Exchange-chainlink">
              <a href="https://chain.link/" target="_blank" rel="noopener noreferrer" className="plain">
                <img src={ChainlinkLogo} alt="Chainlink Logo" /> Built on Chainlink
              </a>
            </div>
          </div>
          <ExchangeWidget setLastUsedMarket={setLastUsedMarket} marketAddress={marketAddress} totalCount={totalCount} setTotalCount={setTotalCount} />
        </div>
      </div>
    </div>
  )
}
