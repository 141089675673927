const PRICE_FEEDS = {
  1: [
    {
      name: "AAVE / ETH",
      address: "0x6Df09E975c830ECae5bd4eD9d90f3A95a4f88012"
    },
    {
      name: "AAVE / USD",
      address: "0x547a514d5e3769680Ce22B2361c10Ea13619e8a9"
    },
    {
      name: "ADA / USD",
      address: "0xAE48c91dF1fE419994FFDa27da09D5aC69c30f55"
    },
    {
      name: "ADX / USD",
      address: "0x231e764B44b2C1b7Ca171fa8021A24ed520Cde10"
    },
    {
      name: "ANT / ETH",
      address: "0x8f83670260F8f7708143b836a2a6F11eF0aBac01"
    },
    {
      name: "AUD / USD",
      address: "0x77F9710E7d0A19669A13c055F62cd80d313dF022"
    },
    {
      name: "BAL / ETH",
      address: "0xC1438AA3823A6Ba0C159CfA8D98dF5A994bA120b"
    },
    {
      name: "BAT / ETH",
      address: "0x0d16d4528239e9ee52fa531af613AcdB23D88c94"
    },
    {
      name: "BCH / USD",
      address: "0x9F0F69428F923D6c95B781F89E165C9b2df9789D"
    },
    {
      name: "BNB / USD",
      address: "0x14e613AC84a31f709eadbdF89C6CC390fDc9540A"
    },
    {
      name: "BNT / ETH",
      address: "0xCf61d1841B178fe82C8895fe60c2EDDa08314416"
    },
    {
      name: "BNT / USD",
      address: "0x1E6cF0D433de4FE882A437ABC654F58E1e78548c"
    },
    {
      name: "BTC / ARS",
      address: "0xA912dd6b62B1C978e205B86994E057B1b494D73a"
    },
    {
      name: "BTC / ETH",
      address: "0xdeb288F737066589598e9214E782fa5A8eD689e8"
    },
    {
      name: "BTC / USD",
      address: "0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c"
    },
    {
      name: "BUSD / ETH",
      address: "0x614715d2Af89E6EC99A233818275142cE88d1Cfd"
    },
    {
      name: "BZRX / ETH",
      address: "0x8f7C7181Ed1a2BA41cfC3f5d064eF91b67daef66"
    },
    {
      name: "CHF / USD",
      address: "0x449d117117838fFA61263B61dA6301AA2a88B13A"
    },
    {
      name: "COMP / ETH",
      address: "0x1B39Ee86Ec5979ba5C322b826B3ECb8C79991699"
    },
    {
      name: "COMP / USD",
      address: "0xdbd020CAeF83eFd542f4De03e3cF0C28A4428bd5"
    },
    {
      name: "CRO / ETH",
      address: "0xcA696a9Eb93b81ADFE6435759A29aB4cf2991A96"
    },
    {
      name: "CRV / ETH",
      address: "0x8a12Be339B0cD1829b91Adc01977caa5E9ac121e"
    },
    {
      name: "DAI / ETH",
      address: "0x773616E4d11A78F511299002da57A0a94577F1f4"
    },
    {
      name: "DAI / USD",
      address: "0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9"
    },
    {
      name: "DASH / USD",
      address: "0xFb0cADFEa136E9E343cfb55B863a6Df8348ab912"
    },
    {
      name: "DMG / ETH",
      address: "0xD010e899f7ab723AC93f825cDC5Aa057669557c2"
    },
    {
      name: "ENJ / ETH",
      address: "0x24D9aB51950F3d62E9144fdC2f3135DAA6Ce8D1B"
    },
    {
      name: "EOS / USD",
      address: "0x10a43289895eAff840E8d45995BBa89f9115ECEe"
    },
    {
      name: "ETC / USD",
      address: "0xaEA2808407B7319A31A383B6F8B60f04BCa23cE2"
    },
    {
      name: "ETH / USD",
      address: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419"
    },
    {
      name: "ETH / XDR",
      address: "0xb022E2970b3501d8d83eD07912330d178543C1eB"
    },
    {
      name: "EUR / USD",
      address: "0xb49f677943BC038e9857d61E7d053CaA2C1734C1"
    },
    {
      name: "FNX / USD",
      address: "0x80070f7151BdDbbB1361937ad4839317af99AE6c"
    },
    {
      name: "FTSE / GBP",
      address: "0xE23FA0e8dd05D6f66a6e8c98cab2d9AE82A7550c"
    },
    {
      name: "Fast Gas / Gwei",
      address: "0x169E633A2D1E6c10dD91238Ba11c4A708dfEF37C"
    },
    {
      name: "GBP / USD",
      address: "0x5c0Ab2d9b5a7ed9f470386e82BB36A3613cDd4b5"
    },
    {
      name: "JPY / USD",
      address: "0xBcE206caE7f0ec07b545EddE332A47C2F75bbeb3"
    },
    {
      name: "KNC / ETH",
      address: "0x656c0544eF4C98A6a98491833A89204Abb045d6b"
    },
    {
      name: "KNC / USD",
      address: "0xf8fF43E991A81e6eC886a3D281A2C6cC19aE70Fc"
    },
    {
      name: "LINK / ETH",
      address: "0xDC530D9457755926550b59e8ECcdaE7624181557"
    },
    {
      name: "LINK / USD",
      address: "0x2c1d072e956AFFC0D435Cb7AC38EF18d24d9127c"
    },
    {
      name: "LRC / ETH",
      address: "0x160AC928A16C93eD4895C2De6f81ECcE9a7eB7b4"
    },
    {
      name: "LTC / USD",
      address: "0x6AF09DF7563C363B5763b9102712EbeD3b9e859B"
    },
    {
      name: "MANA / ETH",
      address: "0x82A44D92D6c329826dc557c5E1Be6ebeC5D5FeB9"
    },
    {
      name: "MKR / ETH",
      address: "0x24551a8Fb2A7211A25a17B1481f043A8a8adC7f2"
    },
    {
      name: "MLN / ETH",
      address: "0xDaeA8386611A157B08829ED4997A8A62B557014C"
    },
    {
      name: "N225 / JPY",
      address: "0x5c4939a2ab3A2a9f93A518d81d4f8D0Bc6a68980"
    },
    {
      name: "NMR / ETH",
      address: "0x9cB2A01A7E64992d32A34db7cEea4c919C391f6A"
    },
    {
      name: "OXT / USD",
      address: "0xd75AAaE4AF0c398ca13e2667Be57AF2ccA8B5de6"
    },
    {
      name: "PAX / ETH",
      address: "0x3a08ebBaB125224b7b6474384Ee39fBb247D2200"
    },
    {
      name: "RCN / BTC",
      address: "0xEa0b3DCa635f4a4E77D9654C5c18836EE771566e"
    },
    {
      name: "REN / ETH",
      address: "0x3147D7203354Dc06D9fd350c7a2437bcA92387a4"
    },
    {
      name: "REN / USD",
      address: "0x0f59666EDE214281e956cb3b2D0d69415AfF4A01"
    },
    {
      name: "REP / ETH",
      address: "0xD4CE430C3b67b3E2F7026D86E7128588629e2455"
    },
    {
      name: "RLC / ETH",
      address: "0x4cba1e1fdc738D0fe8DB3ee07728E2Bc4DA676c6"
    },
    {
      name: "SNX / ETH",
      address: "0x79291A9d692Df95334B1a0B3B4AE6bC606782f8c"
    },
    {
      name: "SNX / USD",
      address: "0xDC3EA94CD0AC27d9A86C180091e7f78C683d3699"
    },
    {
      name: "SUSD / ETH",
      address: "0x8e0b7e6062272B5eF4524250bFFF8e5Bd3497757"
    },
    {
      name: "SUSHI / ETH",
      address: "0xe572CeF69f43c2E488b33924AF04BDacE19079cf"
    },
    {
      name: "SXP / USD",
      address: "0xFb0CfD6c19e25DB4a08D8a204a387cEa48Cc138f"
    },
    {
      name: "TRX / USD",
      address: "0xacD0D1A29759CC01E8D925371B72cb2b5610EA25"
    },
    {
      name: "TSLA / USD",
      address: "0x1ceDaaB50936881B3e449e47e40A2cDAF5576A4a"
    },
    {
      name: "TUSD / ETH",
      address: "0x3886BA987236181D98F2401c507Fb8BeA7871dF2"
    },
    {
      name: "TUSD Reserves",
      address: "0x478f4c42b877c697C4b19E396865D4D533EcB6ea"
    },
    {
      name: "Total Marketcap / USD",
      address: "0xEC8761a0A73c34329CA5B1D3Dc7eD07F30e836e2"
    },
    {
      name: "UMA / ETH",
      address: "0xf817B69EA583CAFF291E287CaE00Ea329d22765C"
    },
    {
      name: "UNI / ETH",
      address: "0xD6aA3D25116d8dA79Ea0246c4826EB951872e02e"
    },
    {
      name: "USDC / ETH",
      address: "0x986b5E1e1755e3C2440e960477f25201B0a8bbD4"
    },
    {
      name: "USDK / USD",
      address: "0xfAC81Ea9Dd29D8E9b212acd6edBEb6dE38Cb43Af"
    },
    {
      name: "USDT / ETH",
      address: "0xEe9F2375b4bdF6387aa8265dD4FB8F16512A1d46"
    },
    {
      name: "WNXM / ETH",
      address: "0xe5Dc0A609Ab8bCF15d3f35cFaa1Ff40f521173Ea"
    },
    {
      name: "WOM / ETH",
      address: "0xcEBD2026d3C99F2a7CE028acf372C154aB4638a9"
    },
    {
      name: "XAG / USD",
      address: "0x379589227b15F1a12195D3f2d90bBc9F31f95235"
    },
    {
      name: "XAU / USD",
      address: "0x214eD9Da11D2fbe465a6fc601a91E62EbEc1a0D6"
    },
    {
      name: "XHV / USD",
      address: "0xeccBeEd9691d8521385259AE596CF00D68429de0"
    },
    {
      name: "XMR / USD",
      address: "0xFA66458Cce7Dd15D8650015c4fce4D278271618F"
    },
    {
      name: "XRP / USD",
      address: "0xCed2660c6Dd1Ffd856A5A82C67f3482d88C50b12"
    },
    {
      name: "XTZ / USD",
      address: "0x5239a625dEb44bF3EeAc2CD5366ba24b8e9DB63F"
    },
    {
      name: "YFI / ETH",
      address: "0x7c5d4F8345e66f68099581Db340cd65B078C41f4"
    },
    {
      name: "ZRX / ETH",
      address: "0x2Da4983a622a8498bb1a21FaE9D8F6C664939962"
    },
    {
      name: "sCEX / USD",
      address: "0x283D433435cFCAbf00263beEF6A362b7cc5ed9f2"
    },
    {
      name: "sDEFI / USD",
      address: "0xa8E875F94138B0C5b51d1e1d5dE35bbDdd28EA87"
    }
  ]
}

const CHAIN_IDS = [1]
const PRICE_FEEDS_MAP = {}

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j]
  PRICE_FEEDS_MAP[chainId] = {}
  for (let i = 0; i < PRICE_FEEDS[chainId].length; i++) {
    const priceFeed = PRICE_FEEDS[chainId][i]
    PRICE_FEEDS_MAP[chainId][priceFeed.address] = priceFeed
  }
}

export function getPriceFeed(chainId, address) {
  if (!PRICE_FEEDS_MAP[chainId]) {
    return {}
  }
  if (!PRICE_FEEDS_MAP[chainId][address]) {
    return {}

  }
  return PRICE_FEEDS_MAP[chainId][address]
}

export function getPriceFeeds(chainId) {
  return PRICE_FEEDS[chainId]
}
