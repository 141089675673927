import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import cx from "classnames";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom'

import { CSSTransition } from 'react-transition-group';

import { cssTransition } from 'react-toastify';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Font.css'
import './Shared.css'
import './App.css'

import Home from './Home'
import Dashboard from './Dashboard'
import Stake from './Stake'
import Exchange from './Exchange'
import ExchangeV1 from './ExchangeV1'
import ExchangeBeta from './ExchangeBeta'
import Distribution from './Distribution'
import Migration from './Migration'
import Migration1 from './Migration1'
import X2Launcher from './X2Launcher'
import Debug from './Debug'
import Gambit from './Gambit'
import Snapshot from './Snapshot'

import { FiMenu } from 'react-icons/fi'
import { ImCross } from 'react-icons/im'

if ('ethereum' in window) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  return library
}

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 300
})

const CloseButton = (props) => {
  return (
    <div className="App-toast-close-bar">
      <div>
        {props.type === "error" && "System Error"}
        {props.type === "success" && "Success"}
      </div>
      <span className="App-toast-close-button" onClick={props.closeToast} >
        <ImCross className="App-toast-close-x">x</ImCross>
      </span>
    </div>
  )
}

export default function App() {
  const [showDrawer, setShowDrawer] = React.useState(false)

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <NavLink exact activeClassName="active" to="/" className="App-logo">XVIX</NavLink>
          <NavLink activeClassName="active" to="/dashboard">Dashboard</NavLink>
          <NavLink activeClassName="active" to="/stake">Stake</NavLink>
          <NavLink activeClassName="active" to="/migrate">Migrate</NavLink>
          <NavLink activeClassName="active" to="/x2-launcher">Launch</NavLink>
        </header>
        <header className="App-header-mobile" onClick={ () => { setShowDrawer(!showDrawer) } }>
          <div className="App-header-mobile-background"></div>
          <div className="App-header-mobile-border"></div>
          <div className="App-logo-mobile">XVIX</div>
          <div className={cx("App-header-arrow", { opened: showDrawer })}>
            <FiMenu/>
          </div>

          <CSSTransition
            in={showDrawer}
            timeout={350}
            classNames="App-drawer"
            unmountOnExit
          >
            <div className="App-header-drawer">
              <NavLink exact activeClassName="active" to="/">Home</NavLink>
              <NavLink activeClassName="active" to="/dashboard">Dashboard</NavLink>
              <NavLink activeClassName="active" to="/stake">Stake</NavLink>
              <NavLink activeClassName="active" to="/migrate">Migrate</NavLink>
              <NavLink activeClassName="active" to="/x2-launcher">Launch</NavLink>
            </div>
          </CSSTransition>
        </header>
        <div className="App-content">
          <div className="App-background"></div>
          <div className="App-highlight"></div>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/dashboard">
                <Dashboard />
              </Route>
              <Route path="/stake">
                <Stake />
              </Route>
              <Route path="/migrate">
                <Migration />
              </Route>
              <Route path="/gambit">
                <Gambit />
              </Route>
              <Route path="/manual_migration_1">
                <Migration1 />
              </Route>
              <Route path="/trade/:marketAddress?">
                <Exchange />
              </Route>
              <Route path="/beta-trade">
                <ExchangeBeta />
              </Route>
              <Route path="/trade-v1">
                <ExchangeV1 />
              </Route>
              <Route path="/distribution">
                <Distribution />
              </Route>
              <Route path="/x2-launcher">
                <X2Launcher />
              </Route>
              <Route path="/debug">
                <Debug />
              </Route>
              <Route path="/snapshot">
                <Snapshot />
              </Route>
            </Switch>
          </Web3ReactProvider>
        </div>
        <ToastContainer
          closeButton={CloseButton}
          transition={Zoom}
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={false}
          draggable={false}
          pauseOnHover
        />
      </div>
    </Router>
  )
}
