import React from 'react';
import ethers from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useEagerConnect, useInactiveListener, formatAmount } from './Helpers'

import InfoReader from './abis/InfoReader.json'

import { HOLDERS } from './data/Holders'

const BATCH_SIZE = 50

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function Snapshot() {
  const [activatingConnector, setActivatingConnector] = React.useState()
  const { connector, library } = useWeb3React()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const tokens = [
    {
      name: "Token",
      address: "0x4bAE380B5D762D543d426331b8437926443ae9ec"
    },
    {
      name: "BV_V2",
      address: "0x780e9996Ec934cba0E2FC830C9b9f3e19F99ec3B"
    },
    {
      name: "TV_V2",
      address: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327"
    },
    {
      name: "UNI",
      address: "0x619aAa52a10F196e521F823aeD4CdeA30D45D366"
    },
    {
      name: "XLGE",
      address: "0xC278A41fC6cf7F488AEa2D0Ab321CC77128931D5"
    },
    {
      name: "UNI_FARM",
      address: "0x8A3d7A49ADa4FED00d83fA6827F46F83b62eF87F"
    },
    {
      name: "XLGE_FARM",
      address: "0x7c1cFFEA9E773186A5950Bb27CBf14b895b4025E"
    },
    {
      name: "BV_V1",
      address: "0x7D1c31E27b4b635fA15A91367d82C8bca9f51C26"
    },
    {
      name: "TV_V1",
      address: "0x77409f913e3D5A6a878e42B858525aAD688e7ED1"
    }
  ]

  const handleBatch = async (contract, token, batch, data) => {
    const balances = await contract.getTokenBalances(token.address, batch)
    for (let i = 0; i < batch.length; i++) {
      const address = batch[i]
      const balance = balances[i]
      if (!data[address]) {
        data[address] = {}
      }
      data[address][token.name] = formatAmount(balance.toString())
    }
  }

  const handleContractCheck = async (contract, batch, data) => {
    const contractInfo = await contract.getContractInfo(batch)
    for (let i = 0; i < batch.length; i++) {
      const address = batch[i]
      const isContract = contractInfo[i]
      if (!data[address]) {
        data[address] = {}
      }
      data[address].isContract = isContract
    }
  }

  const print = async () => {
    const data = {}
    const readerAddress = "0xCCF43B318b16ae6b58d6Bfba35573C247FE02793"
    const contract = new ethers.Contract(readerAddress, InfoReader.abi, library.getSigner())
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i]
      let batch = []

      for (let j = 0; j < HOLDERS.length; j++) {
        batch.push(HOLDERS[j])
        if (batch.length === BATCH_SIZE) {
          console.info(`Handling ${token.name}, ${j}`)
          await handleBatch(contract, token, batch, data)
          batch = []
          await sleep(3000)
        }
      }

      if (batch.length > 0) {
        console.info(`Handling final batch ${token.name}, ${batch.length}`)
        await handleBatch(contract, token, batch, data)
      }
    }

    let batch = []
    for (let j = 0; j < HOLDERS.length; j++) {
      batch.push(HOLDERS[j])
      if (batch.length === BATCH_SIZE) {
        console.info(`Handling contract check, ${j}`)
        await handleContractCheck(contract, batch, data)
        batch = []
        await sleep(3000)
      }
    }

    if (batch.length > 0) {
      console.info(`Handling final contract check, ${batch.length}`)
      await handleContractCheck(contract,  batch, data)
    }

    console.info("data", JSON.stringify(data))

    const formattedData = []
    for (const address in data) {
      const row = [address]
      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i]
        let value = data[address][token.name]
        if (value === undefined) {
          value = "0"
        }

        row.push(value)
      }

      row.push(data[address].isContract ? "TRUE" : "FALSE")
      formattedData.push(row.join(","))
    }

    console.info(formattedData.join("\n"))
  }

  return (
    <div className="Snapshot Page-content">
      <div className="Box">
        <div>
          <button onClick={ () => print() }>Print</button>
        </div>
      </div>
    </div>
  )
}
