import React, { useEffect, useState, useLayoutEffect } from 'react'
import ethers from 'ethers'
import useSWR from 'swr'
import { formatEther } from '@ethersproject/units'
import { Link } from 'react-router-dom'
import { AreaChart, Area, Tooltip, BarChart, Bar, YAxis } from 'recharts'
import { FaTwitter, FaTelegramPlane, FaMediumM, FaGithub } from 'react-icons/fa'

import './Home.css'

import { formatDateTime } from './Helpers'

let cachedPriceData = []
let cachedVolumeData = []
const MULTIPLIER = 1000000

const PriceTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <div className="Exchange-price-tooltip">
        <div>{formatDateTime(payload[0].payload.name)}</div>
        <div>{payload[0].payload.ethPrice} ETH</div>
        <div>{payload[0].payload.usdPrice} USD</div>
      </div>
    );
  }

  return null;
};

const VolumeTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <div className="Exchange-price-tooltip">
        <div>{formatDateTime(payload[0].payload.name)}</div>
        <div>Volume: {payload[0].payload.volume} ETH</div>
      </div>
    );
  }

  return null;
};

export default function Home() {
  const url = "https://cors-300607.uc.r.appspot.com/floor_price"
  const { data: prices, mutate: updatePrices } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then(res => res.json())
  })

  const volumeUrl = "https://cors-300607.uc.r.appspot.com/volume_daily"
  const { data: volumes, mutate: updateVolume } = useSWR([volumeUrl], {
    fetcher: (...args) => fetch(...args).then(res => res.json())
  })

  const [chartWidth, setChartWidth] = useState(450)
  const [chartHeight, setChartHeight] = useState(300)
  let lastUpdatedTime = Date.now()

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 950) {
        setChartWidth(520 - (950 - window.innerWidth))
      }
      if (window.innerWidth < 768) {
        setChartWidth(window.innerWidth - 80)
        setChartHeight(200)
      }
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  let priceData = cachedPriceData
  let priceIncrease
  let maxPrice

  if (prices) {
    priceData = prices.map((v) => {
      const { data } = v
      const capital = ethers.BigNumber.from(data.capital)
      const supply = ethers.BigNumber.from(data.supply)
      const ethPrice = capital.mul(MULTIPLIER * 0.9).div(supply).toNumber() / MULTIPLIER
      const usdPrice = (ethPrice * parseFloat(data.ethPrice)).toFixed(2)
      if (!maxPrice) {
        maxPrice = ethPrice
      }
      if (maxPrice < ethPrice) {
        maxPrice = ethPrice
      }
      return {
        name: data.day,
        ethPrice,
        usdPrice
      }
    })
    cachedPriceData = priceData
    const lastPrice = priceData[priceData.length - 1].ethPrice
    const refPrice = priceData[1].ethPrice
    priceIncrease = (lastPrice / refPrice).toFixed(2)
  }

  let volumeData = cachedVolumeData
  let maxVolume
  let totalVolume = 0

  if (volumes) {
    volumeData = volumes.map((v) => {
      const { data } = v
      const volume = parseFloat(formatEther(data.volume)).toFixed(2)
      totalVolume += parseFloat(formatEther(data.volume))
      if (!maxVolume) {
        maxVolume = parseInt(formatEther(data.volume)) + 1
      }
      if (parseInt(formatEther(data.volume)) > maxVolume) {
        maxVolume = parseInt(formatEther(data.volume)) + 1
      }

      return {
        name: data.day,
        volume
      }
    })
    cachedVolumeData = volumeData
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updatePrices(undefined, true)
      updateVolume(undefined, true)
    }, 60000)
    return () => clearInterval(interval);
  }, [updatePrices, updateVolume])

  return(
    <div className="Home Page-content">
      <div className="Home-logo-container">
        <div className="Home-logo">XVIX</div>
        <div>A Subsystem for DeFi</div>
      </div>
      <div>
        <div className="Home-row">
          <div>
            <AreaChart width={chartWidth} height={chartHeight} data={priceData}
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
              <defs>
                <linearGradient id="priceStrokeColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="3%" stopColor="#fff185" stopOpacity={1}/>
                  <stop offset="40%" stopColor="#fa7a89" stopOpacity={1}/>
                  <stop offset="95%" stopColor="#f5028c" stopOpacity={1}/>
                </linearGradient>
                <linearGradient id="priceFillColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="3%" stopColor="#fff185" stopOpacity={0.2}/>
                  <stop offset="40%" stopColor="#fa7a89" stopOpacity={0.2}/>
                  <stop offset="95%" stopColor="#f5028c" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <YAxis type="number" domain={[0, maxPrice ? Math.ceil(maxPrice * 1000) / 1000 : 0]} />
              <Tooltip content={ PriceTooltip } />
              <Area type="monotone" dataKey="ethPrice" stroke="url(#priceStrokeColor)" strokeWidth="2" fillOpacity={1} fill="url(#priceFillColor)" />
            </AreaChart>
            <div className="Home-chart-info">
              Actual data (Last updated {formatDateTime(lastUpdatedTime / 1000)})
            </div>
          </div>
          <div>
            <div className="Home-row-title">
              Tokenomics
            </div>
            <br/>
            <div className="Home-row-description">
              The XVIX token is a new kind of financial instrument that stores value
              as its price increases.
              <br/>
              <br/>
              Its minimum price has increased {priceIncrease}x against ETH within the last {priceData.length} days
              <br/>
              <br/>
              <a href="https://xvi10.gitbook.io/xvix/xvix" className="Home-token-top-link" target="_blank" rel="noopener noreferrer">
                Learn more
              </a>
              <br/>
              <a className="Home-token-bottom-link" href="https://app.uniswap.org/#/swap?outputCurrency=0x4bAE380B5D762D543d426331b8437926443ae9ec" target="_blank" rel="noopener noreferrer">
                Buy XVIX
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="Home-row">
          <div>
            <BarChart width={chartWidth} height={chartHeight} data={volumeData}
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}>
              <defs>
                <linearGradient id="volumeStrokeColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="3%" stopColor="#00ecfc" stopOpacity={1}/>
                  <stop offset="40%" stopColor="#00a6e3" stopOpacity={1}/>
                  <stop offset="95%" stopColor="#0255c2" stopOpacity={1}/>
                </linearGradient>
                <linearGradient id="volumeFillColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="3%" stopColor="#00ecfc" stopOpacity={0.2}/>
                  <stop offset="40%" stopColor="#00a6e3" stopOpacity={0.2}/>
                  <stop offset="95%" stopColor="#0255c2" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <YAxis type="number" domain={[0, maxVolume]} />
              <Tooltip content={ VolumeTooltip } />
              <Bar dataKey="volume" stroke="url(#volumeStrokeColor)" strokeWidth="2" fillOpacity={1} fill="url(#volumeFillColor)" />
            </BarChart>
            <div className="Home-chart-info">
              Actual data (Last updated {formatDateTime(lastUpdatedTime / 1000)})
            </div>
          </div>
          <div>
            <div className="Home-row-title">
              Leverage Platform
            </div>
            <br/>
            <div className="Home-row-description">
              The XVIX token also receives fees from a simple to use on-chain leverage platform
              <br/>
              <br/>
              {totalVolume.toFixed(2)} ETH has been traded on the platform over the last {volumeData.length} days
              <br/>
              <br/>
              <a href="https://xvi10.gitbook.io/xvix/x2" className="Home-lev-top-link" target="_blank" rel="noopener noreferrer">
                Learn more
              </a>
              <br/>
              <Link className="Home-lev-bottom-link" to="/trade">Start trading</Link>
            </div>
          </div>

        </div>
      </div>
      <div className="Home-links">
        <a className="Home-link" href="https://twitter.com/XVIX_Finance" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a className="Home-link" href="https://xvix.medium.com" target="_blank" rel="noopener noreferrer">
          <FaMediumM className="Home-link" />
        </a>
        <a className="Home-link" href="https://github.com/xvi10" target="_blank" rel="noopener noreferrer">
          <FaGithub className="Home-link" />
        </a>
        <a className="Home-link" href="https://t.me/XVIX_Finance" target="_blank" rel="noopener noreferrer">
          <FaTelegramPlane className="Home-link" />
        </a>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  )
}
