export const WETH_ADDRESS = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
export const DAI_ADDRESS = "0x6b175474e89094c44da98b954eedeac495271d0f"
export const XVIX_ADDRESS = "0x4bAE380B5D762D543d426331b8437926443ae9ec"
export const DISTRIBUTOR_ADDRESS = "0x2b35cCCD8A0BdD17EC2F7e28D8929723826F13D5"
export const FLOOR_ADDRESS = "0x40ED3699C2fFe43939ecf2F3d11F633b522820aD"
export const MINTER_ADDRESS = "0x452dceFd77ee4CC41373CC1a3207d8969f1C21B2"
export const XLGE_WETH_ADDRESS = "0xC278A41fC6cf7F488AEa2D0Ab321CC77128931D5"
export const XLGE_DAI_ADDRESS = "0x4E2249c1458f12C664fCFDf74fB1490b454c9327"
export const READER_ADDRESS = "0x502004458Bc74E0dB836f38422b33043D765D8D8"

const CONTRACTS = {
  1: { // mainnet contracts
    XVIX: XVIX_ADDRESS,
    XLGE_WETH: XLGE_WETH_ADDRESS,
    X2Reader: "0x79f2dbE5E720Cb809c01885f05fE9a317f59467A",
    X2Router: "0x9B30E89ABD81377cDDcc8376550A58376aeef4b4",
    XvixEthUniPair: "0x619aAa52a10F196e521F823aeD4CdeA30D45D366",
    XvixDaiUniPair: "0x232ae3b131e78273f475f4827db83d19f9badbae",
    TimeVault: "0x77409f913e3D5A6a878e42B858525aAD688e7ED1",
    TimeVaultV2: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    BurnVault: "0x7D1c31E27b4b635fA15A91367d82C8bca9f51C26",
    BurnVaultV2: "0x780e9996Ec934cba0E2FC830C9b9f3e19F99ec3B",
    XlgeFarm: "0x7c1cFFEA9E773186A5950Bb27CBf14b895b4025E",
    UniFarm: "0x8A3d7A49ADa4FED00d83fA6827F46F83b62eF87F",
    X2ETHReader: "0x69818A5587cc49881EF618b1c509fB630a2afba5",
    X2ETHReaderV1: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    XvixRouter: "0xaD697e996164cf20a8fa645F71645A1ff028fA72",
    X2StakeReader: "0xb81e1ADB08DFDF5D26FA920019338c1AD75feC59",
    X2Distributor: "0x7f98d265ba2609c1534d12cf6b0976505ad7f653",
    X2AppOwner: "0x5d6493B97399FEc8B18B649769418d729c1a5E6e",
    X2ETHFactory: "0x9E372B445723e71117B59393aABa05aD3B54AD3f",
    ETH_USD_3X_X2FS: "0x03B8F104A9bcf1cEb1140F20ef0f43Ccc787d8b0",
    ETH_USD_3X_X2FS_FARM: "0x4b532083714F49d215cbb656221948AaC32AeEa9",
    GmtSwap: "0xc6f72F7225162f20217eEEFc2FC2F12F5BC44b03",
    GmtIou: "0x15928cC26CE757E08B7a0D2Aa5452AAF26e1ECD1",
    GmxMigrator: "0x6D42dAf7C26Aa3780178b80FAa893b9B6d4cCb85",
    GmxMigrator1: "0x2706AA4532721e6bCe2eA21c3Bb5bbb2146d1Ef1",
    GmxReader: "0xB3737f5FC9233C487267139D6A36De74e6Af3385",
    XVIX_GMX_IOU: "0x491050F83F54FF86907E77732Da7964E870fE1C1",
    UNI_GMX_IOU: "0x2eF13496946e9a2150977C855F9E34b5aB945b31",
    XLGE_GMX_IOU: "0x3c6930d6A4D698B9f4C24b4E14d0f9CF9DaC9c68",
    XVIX_GMX_IOU_1: "0xAb8e080130B51C5c4a6243b0faee12b42a63a3B8",
    UNI_GMX_IOU_1: "0xbD8F00AabeC361ce52486431433FB196c53C5101",
    XLGE_GMX_IOU_1: "0x3D449068d9A39A09f1Eb4D3b6B0dE9A27CaBA7C4"
  },
  42: { // kovan contracts
    X2Reader: "0x2b288a0b6e78F16dc08f309DEfbfB3F474C2B578",
    X2Router: "0x2c20C1cB6F0D2268C311380d8DbA1e010f9A990D",
    X2ETHReader: "0xA59E12D5985aBD20DD9AB06f3afC3Cc0d0208432"
  }
}

const MARKETS = {
  1: {
    ETH_USD_3X: {
      address: "0x494fb0A6783a61C9c465b8F9b030D04939811C7D",
      bullToken: "0x768Ca31d89Ee8f3cffcB8B73F217CC2ca052c068",
      bearToken: "0x24afEc6b30883E7aD6fBFE0c73F020a94FB6319B",
      priceFeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419"
    },
    ETH_USD_10X: {
      address: "0xEE973f48Faba51ee076B91809514aD6b929224dC",
      bullToken: "0xcD14891e7aC3138ba5727cBC0982170EAE32EC49",
      bearToken: "0x658440496e8FfD4Fe7Ab9c6ef7e5767C43890407",
      priceFeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419"
    }
  },
  42: {
    ETH_USD_3X: {
      address: "0x139193Be3B864D830fEad6200E55b8EAdD7849a5",
      bullToken: "0x9e21C0B994c36f248C21Af9aB1bF87b450498E6c",
      bearToken: "0x9F45566fB37D406A873E96bFafef85eaDFf58417",
      priceFeed: "0x9326BFA02ADD2366b30bacB125260Af641031331"
    },
    ETH_USD_10X: {
      address: "0xFb2362fb06e58D620F7697579395369bE4DEdafE",
      bullToken: "0xD8F930e589d27aAE1Bd744D528950041D7b656a7",
      bearToken: "0xdfF54418Aa4cf21c6b1a7e2fE61A1cc6Ad94b647",
      priceFeed: "0x9326BFA02ADD2366b30bacB125260Af641031331"
    }
  }
}

export function getContract(chainId, label) {
  return CONTRACTS[chainId][label]
}

export function getMarket(chainId, label) {
  return MARKETS[chainId][label]
}
