import React, { useEffect, useState } from 'react'
import { useLocalStorage } from './Storage'
import ethers from 'ethers'
import useSWR from 'swr'
import cx from "classnames";
import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import { getContract, XVIX_ADDRESS, XLGE_WETH_ADDRESS, WETH_ADDRESS } from './Addresses'
import { getInjectedConnector, useEagerConnect, getTokenUrl, getExplorerUrl, fetcher,
  useInactiveListener, formatAmount, formatArrayAmount } from './Helpers'

import Xvix from './abis/Xvix.json'
import GmtSwap from './abis/GmtSwap.json'
import X2StakeReader from './abis/X2StakeReader.json'

import { ALLOCATIONS } from './data/GmtAllocation'

import './Gambit.css';

const TOTAL_TX_KEY = "totalTxKey:"
const CHAIN_ID = 1

export const formatPrice = (amount, decimals, market) => {
  if (amount === undefined) {
    return "*"
  }
  if (!decimals) {
    decimals = 2
  }
  if (market && market.decimals !== undefined) {
    decimals = market.decimals
  }
  let divisor = 1
  if (market && market.divisor) {
    divisor = market.divisor
  }
  const value = parseFloat(amount) / Math.pow(10, 6) / divisor
  return value.toFixed(decimals)
}

export function getTotalTxKey(account) {
  if (!account) {
    return TOTAL_TX_KEY
  }
  return TOTAL_TX_KEY + account
}

async function incrementTotalTxCount(library, account, props) {
  const txCount = await library.getTransactionCount(account)
  if (txCount > props.totalCount) {
    props.setTotalCount(txCount + 1)
    return
  }

  props.setTotalCount(props.totalCount + 1)
}

export const X2Login = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="ExchangeBeta-login">
      {!active &&
        <button type="button" onClick={activateMetamask} className="button-outline">
          Connect Wallet
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer" className={cx("button-outline", "ExchangeBeta-pending-tx", { active: pending > 0 })}>
          {pending === 1 && "1 Pending Tx"}
          {pending !== 1 && `${pending} Pending Txs`}
        </a>
      }
    </div>
  )
}

export default function Gambit() {
  const gmtPrice = ethers.BigNumber.from(4.5 * 1000000)
  const [purchaseOption, setPurchaseOption] = useState("xvix")
  const [isApproving, setIsApproving] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)
  const [sendAmount, setSendAmount] = useState("")
  const [receiveAmount, setReceiveAmount] = useState("")
  const [activatingConnector, setActivatingConnector] = React.useState()
  const { connector, account, library, active, activate } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  let gmtAmount = ethers.BigNumber.from(0)
  let sig
  if (account) {
    const allocation = ALLOCATIONS[account.toLowerCase()]
    if (allocation) {
      gmtAmount = allocation.amount
      sig = allocation.sig
    }
  }

  const gmtSwap = getContract(CHAIN_ID, "GmtSwap")
  const xvixEthUniPair = getContract(CHAIN_ID, "XvixEthUniPair")
  const timeVaultV2 = getContract(CHAIN_ID, "TimeVaultV2")
  const burnVaultV2 = getContract(CHAIN_ID, "BurnVaultV2")
  const xlgeFarm = getContract(CHAIN_ID, "XlgeFarm")
  const uniFarm = getContract(CHAIN_ID, "UniFarm")
  const stakeReader = getContract(CHAIN_ID, "X2StakeReader")

  const { data: gmtBalance, mutate: updateGmtBalance } = useSWR([active, getContract(CHAIN_ID, "GmtIou"), 'balanceOf', account], {
    fetcher: fetcher(library, Xvix),
  })
  const { data: gmtSupply, mutate: updateGmtSupply } = useSWR([active, getContract(CHAIN_ID, "GmtIou"), 'totalSupply'], {
    fetcher: fetcher(library, Xvix),
  })
  const { data: xvixPrice, mutate: updateXvixPrice } = useSWR([active, getContract(CHAIN_ID, "GmtSwap"), "getXvixPrice"], {
    fetcher: fetcher(library, GmtSwap),
  })
  const { data: uniPrice, mutate: updateUniPrice } = useSWR([active, getContract(CHAIN_ID, "GmtSwap"), "getUniPrice"], {
    fetcher: fetcher(library, GmtSwap),
  })
  const { data: xvixAllowance, mutate: updateXvixAllowance } = useSWR([active, XVIX_ADDRESS, 'allowance', account, gmtSwap], {
    fetcher: fetcher(library, Xvix),
  })
  const { data: uniAllowance, mutate: updateUniAllowance } = useSWR([active, xvixEthUniPair, 'allowance', account, gmtSwap], {
    fetcher: fetcher(library, Xvix),
  })
  const { data: xlgeAllowance, mutate: updateXlgeAllowance } = useSWR([active, XLGE_WETH_ADDRESS, 'allowance', account, gmtSwap], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: stakeInfo, mutate: updateStakeInfo } = useSWR([active, stakeReader, 'getStakeInfo', xlgeFarm, uniFarm, burnVaultV2, timeVaultV2, XLGE_WETH_ADDRESS, xvixEthUniPair, XVIX_ADDRESS, WETH_ADDRESS, account], {
    fetcher: fetcher(library, X2StakeReader),
  })

  let tokenBalanceIndex = 6
  if (purchaseOption === "uni") {
    tokenBalanceIndex = 10
  }
  if (purchaseOption === "xlge") {
    tokenBalanceIndex = 7
  }

  let maxGmt
  if (gmtAmount && gmtBalance) {
    maxGmt = ethers.BigNumber.from(gmtAmount).sub(gmtBalance)
  }

  let tokenPrice
  if (xvixPrice && uniPrice) {
    if (purchaseOption === "xvix") {
      tokenPrice = xvixPrice
    }
    if (purchaseOption === "uni") {
      tokenPrice = uniPrice
    }
    if (purchaseOption === "xlge") {
      tokenPrice = ethers.BigNumber.from(22500 * 1000000)
    }
  }

  const updateSendAmount = (value, option) => {
    if (xvixPrice && uniPrice) {
      if (option === "xvix") {
        tokenPrice = xvixPrice
      }
      if (option === "uni") {
        tokenPrice = uniPrice
      }
      if (option === "xlge") {
        tokenPrice = ethers.BigNumber.from(22500 * 1000000)
      }
    }

    const parsedValue = parseFloat(value)
    setSendAmount(value)
    if (isNaN(parsedValue) || !tokenPrice || !gmtPrice) {
      return
    }

    const valueInWei = ethers.utils.parseEther(parsedValue.toString())
    setReceiveAmount(formatAmount(valueInWei.mul(tokenPrice).div(gmtPrice)))
  }

  const updateReceiveAmount = (value) => {
    const parsedValue = parseFloat(value)
    setReceiveAmount(value)
    if (isNaN(parsedValue) || !tokenPrice || !gmtPrice) {
      return
    }

    const valueInWei = ethers.utils.parseEther(parsedValue.toString())
    setSendAmount(formatAmount(valueInWei.mul(gmtPrice).div(tokenPrice)))
  }

  const updatePurchaseOption = (option) => {
    setPurchaseOption(option)
    if (sendAmountInWei) {
      updateSendAmount(formatAmount(sendAmountInWei), option)
    }
  }

  const parsedSendAmount = parseFloat(sendAmount)
  let sendAmountInWei
  if (!isNaN(parsedSendAmount)) {
    sendAmountInWei = ethers.utils.parseEther(parsedSendAmount.toString())
  }

  const parsedReceiveAmount = parseFloat(receiveAmount)
  let receiveAmountInWei
  if (!isNaN(parsedReceiveAmount)) {
    receiveAmountInWei = ethers.utils.parseEther(parsedReceiveAmount.toString())
  }

  let needApproval = false
  if (purchaseOption === "xvix" && sendAmountInWei && xvixAllowance && sendAmountInWei.gt(xvixAllowance)) {
    needApproval = true
  }
  if (purchaseOption === "uni" && sendAmountInWei && uniAllowance && sendAmountInWei.gt(uniAllowance)) {
    needApproval = true
  }
  if (purchaseOption === "xlge" && sendAmountInWei && xlgeAllowance && sendAmountInWei.gt(xlgeAllowance)) {
    needApproval = true
  }

  let error
  if (isNaN(parsedSendAmount)) {
    error = "Enter an amount"
  }

  if (isNaN(parsedReceiveAmount)) {
    error = "Enter an amount"
  }

  if (sendAmountInWei && stakeInfo && sendAmountInWei.gt(stakeInfo[tokenBalanceIndex])) {
    error = `Insufficient ${purchaseOption.toUpperCase()}`
  }
  if (receiveAmountInWei && maxGmt && receiveAmountInWei.gt(maxGmt)) {
    error = `Reduce GMT Amount`
  }

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateXvixPrice(undefined, true)
        updateUniPrice(undefined, true)
        updateStakeInfo(undefined, true)
        updateGmtBalance(undefined, true)
        updateGmtSupply(undefined, true)
        updateXvixAllowance(undefined, true)
        updateUniAllowance(undefined, true)
        updateXlgeAllowance(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateXvixPrice, updateUniPrice,
      updateStakeInfo, updateGmtBalance, updateGmtSupply,
      updateXvixAllowance, updateUniAllowance, updateXlgeAllowance])

  const approveTokens = async () => {
    let token
    if (purchaseOption === "xvix") {
      token = XVIX_ADDRESS
    } else if (purchaseOption === "uni") {
      token = xvixEthUniPair
    } else if (purchaseOption === "xlge") {
      token = XLGE_WETH_ADDRESS
    } else {
      toast.error("Invalid option")
      return
    }
    setIsApproving(true)
    const contract = new ethers.Contract(token, Xvix.abi, library.getSigner())
    contract.approve(gmtSwap, ethers.constants.MaxUint256)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Approval submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Approval failed.")
      })
      .finally(() => {
        setIsApproving(false)
      })
  }

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  const swap = async () => {
    if (!active) {
      activateMetamask()
      return
    }
    let token
    if (purchaseOption === "xvix") {
      token = XVIX_ADDRESS
    } else if (purchaseOption === "uni") {
      token = xvixEthUniPair
    } else if (purchaseOption === "xlge") {
      token = XLGE_WETH_ADDRESS
    } else {
      toast.error("Invalid option")
      return
    }
    setIsSwapping(true)
    const contract = new ethers.Contract(gmtSwap, GmtSwap.abi, library.getSigner())
    const vrs = ethers.utils.splitSignature(sig)

    const params = [token, sendAmountInWei, gmtAmount, vrs.v, vrs.r, vrs.s]

    let gasLimit = await contract.estimateGas.swap(...params)
    if (gasLimit.lt(350000)) {
      gasLimit = ethers.BigNumber.from(350000)
    }

    contract.swap(...params, { gasLimit })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Swap submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Swap failed.")
      })
      .finally(() => {
        setIsSwapping(false)
      })
  }
  const gmtTokenURL = getTokenUrl(CHAIN_ID, getContract(CHAIN_ID, "GmtIou"), account)

  return (
    <div className="Gambit Page-content">
      <div className="Box">
        <table className="Dashboard-title-box">
          <tbody>
            <tr>
              <td>
                <div className="Dashboard-title">
                  Gambit
                </div>
              </td>
              <td>
                <X2Login totalCount={totalCount} setTotalCount={setTotalCount} />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          GMT will be the governance token for the <a href="https://gambit.financial/" className="simple" target="_blank" rel="noopener noreferrer">Gambit protocol</a>.
        </div>
        <div>
          The XVIX-GMT swap has ended, GMT tokens will be airdropped to your account on BSC.
        </div>
        <br/>
        <table>
          <tbody>
            <tr>
              <td>Your Allocation:</td>
              <td>{formatAmount(gmtAmount)} GMT</td>
            </tr>
            <tr>
              <td>Your Tokens:</td>
              <td>
                <a href={gmtTokenURL} target="_blank" rel="noopener noreferrer" className="simple">
                  {formatAmount(gmtBalance)} GMT
                </a>
              </td>
            </tr>
            <tr>
              <td>Total Purchased:</td>
              <td>{formatAmount(gmtSupply)} GMT</td>
            </tr>
          </tbody>
        </table>
        <br/>
        <br/>
        <div className="Gambit-purchase-options">
          <div className={cx("Gambit-purchase-option", { active: purchaseOption === "xvix" })} onClick={ () => updatePurchaseOption("xvix") }>
            <div className="Gambit-purchase-option-title">XVIX</div>
            <div className="Gambit-purchase-option-price">{formatPrice(xvixPrice)} USD</div>
            <div className="Gambit-purchase-option-balance">{formatArrayAmount(stakeInfo, 6, 1, 2)} XVIX</div>
          </div>
          <div className={cx("Gambit-purchase-option", { active: purchaseOption === "uni" })} onClick={ () => updatePurchaseOption("uni") }>
            <div className="Gambit-purchase-option-title">XVIX/ETH UNI</div>
            <div className="Gambit-purchase-option-price">{formatPrice(uniPrice)} USD</div>
            <div className="Gambit-purchase-option-balance">{formatArrayAmount(stakeInfo, 10, 1, 2)} UNI</div>
          </div>
          <div className={cx("Gambit-purchase-option", { active: purchaseOption === "xlge" })} onClick={ () => updatePurchaseOption("xlge") }>
            <div className="Gambit-purchase-option-title">XLGE</div>
            <div className="Gambit-purchase-option-price">22500.00 USD</div>
            <div className="Gambit-purchase-option-balance">{formatArrayAmount(stakeInfo, 7, 1, 2)} XLGE</div>
          </div>
        </div>
        <br/>
        <table className="Gambit-form">
          <tbody>
            <tr>
              <td>Send:</td>
              <td>
                <input type="text" className="input-outline" placeholder="0" value={sendAmount} onChange={(e) => {updateSendAmount(e.target.value, purchaseOption)} } />
              </td>
              <td>
                {purchaseOption.toUpperCase()}
              </td>
              <td className="Gambit-max-value" onClick={() => { updateSendAmount(formatArrayAmount(stakeInfo, tokenBalanceIndex, 1), purchaseOption) }}>
                [Max: {formatArrayAmount(stakeInfo, tokenBalanceIndex, 1)}]
              </td>
            </tr>
            <tr>
              <td>Receive:</td>
              <td>
                <input type="text" className="input-outline" placeholder="0" value={receiveAmount} onChange={(e) => {updateReceiveAmount(e.target.value)} } />
              </td>
              <td>
                GMT
              </td>
              <td className="Gambit-max-value" onClick={() => { updateReceiveAmount(formatAmount(maxGmt)) }}>
                [Max: {formatAmount(maxGmt)}]
              </td>
            </tr>
          </tbody>
        </table>
        <br/>
        <div className="Gambit-submit-container">
          {needApproval &&
            <button type="button" className="button-primary Gambit-button" disabled={isApproving} onClick={() => approveTokens()}>
              {isApproving && "Approving..."}
              {!isApproving && `Approve ${purchaseOption.toUpperCase()}`}
            </button>
          }
          <button type="button" className="button-primary Gambit-button" disabled={active && (needApproval || error || isSwapping)} onClick={() => swap()}>
            {(!active) && "Connect Wallet"}
            {(active && error && !isSwapping) && error}
            {(active && !error && !isSwapping) && "Buy GMT"}
            {isSwapping && "Buying..."}
          </button>
        </div>
      </div>
    </div>
  )
}
