import React from 'react'

import { FaTimes } from 'react-icons/fa'

import './Modal.css';

export default function Modal(props) {
  const { isVisible, setIsVisible, className } = props

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }

  const _className = ["Modal", className].filter(Boolean).join(" ");

  return (
    <div>
      {isVisible && <div className={_className}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={fadeVariants}
          transition={{ duration: 0.2 }}
        >
        <div className="Modal-backdrop" onClick={() => setIsVisible(false)}></div>
        <div className="Modal-content">
          <div className="Modal-title-bar">
            <div className="Modal-title">
              {props.label}
            </div>
            <div className="Modal-close-button" onClick={() => setIsVisible(false)}><FaTimes className="Modal-close-icon" /></div>
          </div>
          {props.children}
        </div>
      </div>}
    </div>
  )
}
