import React, { useEffect } from 'react'
import { InjectedConnector } from '@web3-react/injected-connector'
import { formatEther } from '@ethersproject/units'
import ethers from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { format as formatDateFn } from 'date-fns'

const injected = new InjectedConnector({
  supportedChainIds: [
    1, // Mainet
    // 3, // Ropsten
    42 // Kovan
  ],
})

export function getExplorerUrl(chainId) {
  if (chainId === 3) {
    return "https://ropsten.etherscan.io/"
  }
  if (chainId === 42) {
    return "https://kovan.etherscan.io/"
  }
  return "https://etherscan.io/"
}

export function getTokenUrl(chainId, token, account) {
  const url = getExplorerUrl(chainId)
  if (!account) {
    return `${url}token/${token}`
  }
  return `${url}token/${token}?a=${account}`
}

export function getInjectedConnector() {
  return injected
}

export const fetcher = (library, contractInfo, additionalArgs) => (...args) => {
  if (!library) {
    return
  }

  const [active, arg0, arg1, ...params] = args
  if (!active) {
    return
  }

  if (ethers.utils.isAddress(arg0)) {
    const address = arg0
    const method = arg1
    const contract = new ethers.Contract(address, contractInfo.abi, library.getSigner())
    if (additionalArgs) {
      return contract[method](...params.concat(additionalArgs))
    }
    return contract[method](...params)
  }

  const method = arg0
  return library[method](arg1, ...params)
}

export const formatPriceFeed = (amount, decimals, market) => {
  if (!decimals) {
    decimals = 2
  }
  if (market && market.decimals !== undefined) {
    decimals = market.decimals
  }
  let divisor = 1
  if (market && market.divisor) {
    divisor = market.divisor
  }
  const value = parseFloat(amount) / Math.pow(10, 8) / divisor
  return value.toFixed(decimals)
}

export const formatAmount = (amount, multiplier, decimals) => {
  if (!multiplier) {
    multiplier = 1
  }
  if (!decimals) {
    decimals = 4
  }
  let amountStr = "*"
  if (amount) {
    amountStr = (Math.floor(parseFloat(formatEther(amount) * multiplier) * 10000) / 10000).toFixed(decimals)
  }
  return amountStr
}

export const formatAmountFree = (amount, multiplier) => {
  if (!multiplier) {
    multiplier = 1
  }
  let amountStr = "*"
  if (amount) {
    amountStr = (Math.floor(parseFloat(formatEther(amount) * multiplier) * 10000) / 10000)
  }
  return amountStr
}

export const formatArrayAmount = (array, index, multiplier, decimals) => {
  if (!array) {
    return "*"
  }

  if (!decimals) {
    decimals = 4
  }

  if (!multiplier) {
    multiplier = 1
  }

  let amountStr = "*"
  const amount = array[index]
  if (amount) {
    amountStr = (Math.floor(parseFloat(formatEther(amount) * multiplier) * 10000) / 10000).toFixed(decimals)
  }

  return amountStr
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function useEagerConnect() {
  const injected = getInjectedConnector()
  const { activate, active } = useWeb3React()

  const [tried, setTried] = React.useState(false)

  React.useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  React.useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

export function useInactiveListener(suppress: boolean = false) {
  const injected = getInjectedConnector()
  const { active, error, activate } = useWeb3React()

  React.useEffect((): any => {
    const { ethereum } = window
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected)
      }
      const handleChainChanged = (chainId: string | number) => {
        activate(injected)
      }
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(injected)
        }
      }
      const handleNetworkChanged = (networkId: string | number) => {
        activate(injected)
      }

      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, suppress, activate])
}

export function formatDate(time) {
  return formatDateFn(time * 1000, "dd MMM yyyy")
}

export function formatDateTime(time) {
  return formatDateFn(time * 1000, "dd MMM yyyy, h:mm a")
}

export const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback()
      }
    }

    useEffect(() => {
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      }
    })
  }
