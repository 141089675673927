import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocalStorage } from './Storage'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import { formatEther } from '@ethersproject/units'
import useSWR from 'swr'
import ethers from 'ethers'
import cx from "classnames";
import { format as formatDate } from 'date-fns'

import './Dashboard.css'

import Xvix from './abis/Xvix.json'
import Distributor from './abis/Distributor.json'
import Floor from './abis/Floor.json'
import Minter from './abis/Minter.json'
import LGEToken from './abis/LGEToken.json'
import Reader from './abis/Reader.json'
import Farm from './abis/Farm.json'
import TimeVault from './abis/TimeVault.json'
import BurnVault from './abis/BurnVault.json'
import XvixRouter from './abis/XvixRouter.json'

import { PieChart, Pie, Cell } from 'recharts'

import { FaExternalLinkAlt } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { BsCaretDownFill } from 'react-icons/bs'

import { getInjectedConnector, getExplorerUrl, getTokenUrl, formatAmount,
  formatAmountFree, fetcher, formatArrayAmount, numberWithCommas,
  useEagerConnect, useInactiveListener } from './Helpers'

import { getContract, XVIX_ADDRESS, DISTRIBUTOR_ADDRESS, FLOOR_ADDRESS, MINTER_ADDRESS,
  READER_ADDRESS, WETH_ADDRESS, DAI_ADDRESS, XLGE_WETH_ADDRESS,
  XLGE_DAI_ADDRESS } from './Addresses'

const TOTAL_TX_KEY = "totalTxKey:"

const CHAIN_ID = 1

const MIN_XVIX = "100000000000000"

const EthBalance = (props) => {
  const { account, library, active } = useWeb3React()

  const { data: balance, mutate } = useSWR([active, 'getBalance', account, 'latest'], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  return (
    <tr>
      <td>ETH Balance:</td>
      <td>
        {props.onClick &&
          <div className="clickable" onClick={ () => props.onClick(formatAmount(balance)) }>
            { formatAmount(balance) }
          </div>
        }
        {!props.onClick && formatAmount(balance) }
      </td>
    </tr>
  )
}

const EthReceived = () => {
  const { library, active } = useWeb3React()

  const { data: balance, mutate } = useSWR([active, XLGE_WETH_ADDRESS, 'totalSupply'], {
    fetcher: fetcher(library, LGEToken),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  return (
    <tr>
      <td>Total ETH Received:</td>
      <td>
        { formatAmount(balance) }
      </td>
    </tr>
  )
}

const LGEAccountInfo = () => {
  const { account, library, active, chainId } = useWeb3React()

  const { data: amounts, mutate } = useSWR([active, READER_ADDRESS, 'getLGEAmounts', account], {
    fetcher: fetcher(library, Reader),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const lgeWethLink = getTokenUrl(chainId, XLGE_WETH_ADDRESS, account)
  const lgeDaiLink = getTokenUrl(chainId, XLGE_DAI_ADDRESS, account)
  const xvixLink = getTokenUrl(chainId, XVIX_ADDRESS, account)

  return (
    <tbody>
      <tr>
        <td>ETH Sent:</td>
        <td>
          { formatArrayAmount(amounts, 0) }
        </td>
      </tr>
      <tr>
        <td>XLGE:WETH:</td>
        <td>
          <a href={lgeWethLink} target="_blank" rel="noopener noreferrer">
            {formatArrayAmount(amounts, 0)}
          </a> worth {formatArrayAmount(amounts, 1)} ETH
        </td>
      </tr>
      <tr>
        <td>XLGE:DAI:</td>
        <td>
          <a href={lgeDaiLink} target="_blank" rel="noopener noreferrer">
            {formatArrayAmount(amounts, 0)}
          </a> worth {formatArrayAmount(amounts, 2)} DAI
        </td>
      </tr>
      <tr>
        <td>XVIX:</td>
        <td>
          <a href={xvixLink} target="_blank" rel="noopener noreferrer">
            {formatArrayAmount(amounts, 3)}
          </a> worth {formatArrayAmount(amounts, 1, 2)} ETH
        </td>
      </tr>
    </tbody>
  )
}

function getTimeRemaining(dateTime) {
    const now = parseInt(Date.now() / 1000)
    const diff = parseInt(dateTime.toNumber() - now)
    const days = parseInt(diff / (24 * 60 * 60))
    const hours = parseInt((diff - days * 24 * 60 * 60) / (60 * 60))
    const minutes = parseInt((diff - days * 24 * 60 * 60 - hours * 60 * 60) / 60)
    const dayLabel = days === 1 ? "day" : "days"
    const hourLabel = hours === 1 ? "hour" : "hours"
    const minLabel = minutes === 1 ? "minute" : "minutes"
    return `${days} ${dayLabel} ${hours} ${hourLabel} ${minutes} ${minLabel}`
}

const LGEEndDate = () => {
  const endTime = ethers.BigNumber.from(1606809600)

  let timeRemaining = "*"
  let lgeEnded = false
  if (endTime) {
    const now = parseInt(Date.now() / 1000)
    if (endTime.toNumber() < now) {
      lgeEnded = true
    } else {
      timeRemaining = getTimeRemaining(endTime)
    }
  }

  if (lgeEnded) {
    return (
      <tr>
        <td colSpan="2">LGE has ended</td>
      </tr>
    )
  }

  return (
    <tr>
      <td>LGE Ending In:</td>
      <td>
        { timeRemaining }
      </td>
    </tr>
  )
}

const DistributorMinter = (props) => {
  const { library, account, active, chainId } = useWeb3React()
  const [ethAmount, setEthAmount] = useState("")
  const [minting, setMinting] = useState(false)
  const parsedEthAmount = parseFloat(ethAmount)
  let error

  if (!active) {
    error = "Login to Metamask to send ETH."
  }

  const mint = async () => {
    if (isNaN(parsedEthAmount)) {
      toast.error("ETH to Send is not a valid number.")
      return
    }
    if (parsedEthAmount === 0) {
      toast.error("ETH to Send cannot be zero.")
      return
    }
    const ethAmountInWei = ethers.utils.parseEther(parsedEthAmount.toString())
    const ethBalance = await library.getBalance(account, "latest")
    if (ethAmountInWei.gt(ethBalance)) {
      toast.error("ETH to Send exceeds your ETH balance.")
      return
    }

    setMinting(true)
    const contract = new ethers.Contract(DISTRIBUTOR_ADDRESS, Distributor.abi, library.getSigner())
    // const minDAI
    const reader = new ethers.Contract(READER_ADDRESS, Reader.abi, library.getSigner())
    const amounts = await reader.getPoolAmounts(account, WETH_ADDRESS, DAI_ADDRESS)
    const ethReserve = amounts[2]
    const daiReserve = amounts[3]
    const slippage = 2 // 2% slippage
    // only 25% of ETH will be converted to DAI
    const expectedDAI = ethAmountInWei.mul(daiReserve).div(ethReserve).div(4)
    const minDAI = expectedDAI.mul(parseInt((100 - slippage) * 100)).div(10000)

    const deadline = parseInt(Date.now() / 1000) + 20 * 60
    contract.join(account, minDAI, deadline, { value: ethAmountInWei })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, props)

        const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash
        toast.success(
          <div>
            Join submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setEthAmount(0)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Join failed.")
      })
      .finally(() => {
        setMinting(false)
      })
  }

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>ETH to Send:</td>
            <td><input type="text" placeholder="0.0000" value={ethAmount} onChange={ (e) => setEthAmount(e.target.value) } /></td>
          </tr>
        </tbody>
      </table>
      <br/>
      <div>
        {error && <div className="Box-error">{error}</div>}
        {!error &&
          <button className="Dashboard-distributor-mint wide-button" onClick={ mint } disabled={minting}>
            {!minting && "[ Send ]"}
            {minting && "[ Sending... ]"}
          </button>
        }
      </div>
    </div>
  )
}

export const Account = () => {
  const { account, active, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  return (
    <tr>
      <td>Account:</td>
      <td>
        {!active && "*"}
        {active &&
          <a href={accountUrl} target="_blank" rel="noopener noreferrer" className="Dashboard-account-url">
            {account}
          </a>
        }
      </td>
    </tr>
  )
}

export function getTotalTxKey(account) {
  if (!account) {
    return TOTAL_TX_KEY
  }
  return TOTAL_TX_KEY + account
}

async function incrementTotalTxCount(library, account, props) {
  const txCount = await library.getTransactionCount(account)
  if (txCount > props.totalCount) {
    props.setTotalCount(txCount + 1)
    return
  }

  props.setTotalCount(props.totalCount + 1)
}

export const X2Login = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="ExchangeBeta-login">
      {!active &&
        <button type="button" onClick={activateMetamask} className="button-outline">
          Connect Wallet
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer" className={cx("button-outline", "ExchangeBeta-pending-tx", { active: pending > 0 })}>
          {pending === 1 && "1 Pending Tx"}
          {pending !== 1 && `${pending} Pending Txs`}
        </a>
      }
    </div>
  )
}

export const Login = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="Dashboard-login">
      {!active &&
        <button type="button" onClick={activateMetamask}>
          [ Metamask Login ]
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer">
          {pending === 1 &&
            <span>
              1 Pending Tx
            </span>
          }
          {pending !== 1 &&
            <span>
              {pending} Pending Txs
            </span>
          }
        </a>
      }
    </div>
  )
}

function autoActivate(active, activate) {
  if (!active) {
    // activate(getInjectedConnector())
  }
}

export const DistributionApp = (props) => {
  const { account, activate, active } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  const lgeEnded = true

  autoActivate(active, activate)

  return (
    <div>
      <div className="Box-subtitle">
        <span className="Box-title-symbol Dashboard-view-info">➜&nbsp;</span>
        Distribution
        <Login totalCount={totalCount} setTotalCount={setTotalCount} />
      </div>
      {!lgeEnded &&
        <div>
          <div>
            XVIX has been distributed through an <a href="https://xvix.medium.com/xlge-a-new-kind-of-lge-60906f597991" target="_blank" rel="noopener noreferrer">
              XLGE
            </a>.
          </div>
          <br/>

          <table>
            <tbody>
              <EthReceived />
              <LGEEndDate />
            </tbody>
          </table>
          <br/>

          <table>
            <tbody>
              <Account />
              <EthBalance />
            </tbody>
            <LGEAccountInfo />
          </table>
          <div>-----</div>
          <DistributorMinter totalCount={totalCount} setTotalCount={setTotalCount} />
        </div>
      }
      {lgeEnded &&
        <div>
          <div>
            XVIX was distributed through an <a href="https://xvix.medium.com/xlge-a-new-kind-of-lge-60906f597991" target="_blank" rel="noopener noreferrer">
            XLGE
            </a>.
          </div>
          <br/>

          <LPApp />
        </div>
      }
    </div>
  )
}

export const LPApp = () => {
  const [isWarningVisible, setIsWarningVisible] = useState(false)
  const [asset, setAsset] = useState("eth")
  const [slippage, setSlippage] = useState("0.5")
  const [advancedMode, setAdvancedMode] = useState(false)
  const [slippageInput, setSlippageInput] = useState("")
  const [redeemAmount, setRedeemAmount] = useState("")
  const [validFor, setValidFor] = useState("")
  const [removing, setRemoving] = useState(false)
  const { account, library, active, chainId } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  let error

  const { data: wethLpAmounts, mutate: updateWethLpAmounts } = useSWR([active, READER_ADDRESS, 'getLPAmounts', account, XLGE_WETH_ADDRESS], {
    fetcher: fetcher(library, Reader),
  })

  const { data: daiLpAmounts, mutate: updateDaiLpAmounts } = useSWR([active, READER_ADDRESS, 'getLPAmounts', account, XLGE_DAI_ADDRESS], {
    fetcher: fetcher(library, Reader),
  })

  const { data: refWethLpAmounts, mutate: updateRefWethLpAmounts } = useSWR([active, READER_ADDRESS, 'getLPAmounts', "0xaD8987f5a71D22BD14F1c842D1f431eeDa83Fc4B", XLGE_WETH_ADDRESS], {
    fetcher: fetcher(library, Reader),
  })

  const { data: refDaiLpAmounts, mutate: updateRefDaiLpAmounts } = useSWR([active, READER_ADDRESS, 'getLPAmounts', "0x6eD15A7CAE019cE8aef046c643c70C12AA8dCbde", XLGE_DAI_ADDRESS], {
    fetcher: fetcher(library, Reader),
  })

  const { data: unlockTime } = useSWR([active, DISTRIBUTOR_ADDRESS, 'lpUnlockTime'], {
    fetcher: fetcher(library, Distributor),
  })

  const lpAmounts = {
    eth: wethLpAmounts,
    dai: daiLpAmounts
  }

  let timeRemaining = "*"
  let isUnlocked = false
  if (unlockTime) {
    const now = parseInt(Date.now() / 1000)
    if (unlockTime.toNumber() < now) {
      isUnlocked = true
    } else {
      timeRemaining = getTimeRemaining(unlockTime)
    }
  }

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateWethLpAmounts(undefined, true)
        updateDaiLpAmounts(undefined, true)
        updateRefWethLpAmounts(undefined, true)
        updateRefDaiLpAmounts(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateWethLpAmounts, updateDaiLpAmounts, updateRefWethLpAmounts, updateRefDaiLpAmounts])

  let toReceive = "*"
  let toBurn = 0
  let refundedETH = 0
  let toToast = 0
  let total = 0
  let redeemAmountInWei
  const amounts = lpAmounts[asset]
  const parsedRedeemAmount = parseFloat(redeemAmount)
  let redeemETHAmount
  let redeemXVIXAmount
  let redeemDAIAmount
  if (!isNaN(parsedRedeemAmount) && amounts) {
    redeemAmountInWei = ethers.utils.parseEther(parsedRedeemAmount.toString())
    const toReceiveInWei = amounts[1].mul(redeemAmountInWei).div(amounts[0])
    toReceive = formatAmount(toReceiveInWei)
    const refundBasisPoints = amounts[3]
    if (refundBasisPoints > 0) {
      const toBurnInWei = amounts[2].mul(redeemAmountInWei).div(amounts[0]).mul(amounts[3]).div(10000)
      toBurn = formatAmount(toBurnInWei)
      const refundedETHInWei = amounts[4].mul(redeemAmountInWei).div(amounts[0]).mul(amounts[3]).div(10000)
      refundedETH = formatAmount(refundedETHInWei)
      if (10000 - amounts[3].toNumber() > 0) {
        const toToastInWei = amounts[2].mul(redeemAmountInWei).div(amounts[0]).mul(10000 - amounts[3].toNumber()).div(10000)
        toToast = formatAmount(toToastInWei)
      }
      const totalInWei = toReceiveInWei.add(refundedETHInWei)
      total = formatAmount(totalInWei)
    }

    if (asset === "eth" && wethLpAmounts) {
      redeemETHAmount = toReceiveInWei
      redeemXVIXAmount = redeemETHAmount.mul(wethLpAmounts[2]).div(wethLpAmounts[1])
    }
    if (asset !== "eth" && daiLpAmounts) {
      redeemDAIAmount = toReceiveInWei
      redeemXVIXAmount = redeemDAIAmount.mul(daiLpAmounts[2]).div(daiLpAmounts[1])
    }
  }

  const removeLiquidity = () => {
    let selectedSlippage = slippage
    if (selectedSlippage === "") {
      selectedSlippage = slippageInput
    }
    let parsedSlippage = parseFloat(selectedSlippage)
    if (isNaN(parsedSlippage)) {
      toast.error("Invalid Max Slippage amount.")
      return
    }
    if (parsedSlippage === 0) {
      toast.error("Max Slippage should be more than zero.")
      return
    }

    let parsedValidFor = 20
    if (validFor !== "") {
      parsedValidFor = parseFloat(validFor)
    }
    if (isNaN(parsedValidFor)) {
      toast.error("Invalid Valid For value.")
      return
    }
    if (parsedValidFor === 0) {
      toast.error("Valid For must be more than zero.")
      return
    }

    if (!redeemAmountInWei || !amounts) {
      toast.error("Could not retrieve amounts.")
      return
    }

    setRemoving(true)
    const contract = new ethers.Contract(DISTRIBUTOR_ADDRESS, Distributor.abi, library.getSigner())
    const amountXVIXMin = amounts[2].mul(parseInt((100 - parsedSlippage) * 100)).div(10000).mul(redeemAmountInWei).div(amounts[0])
    const amountTokenMin = amounts[1].mul(parseInt((100 - parsedSlippage) * 100)).div(10000).mul(redeemAmountInWei).div(amounts[0])
    const deadline = parseInt(Date.now() / 1000 + parsedValidFor * 60)
    let method = asset === "eth" ? "removeLiquidityETH" : "removeLiquidityDAI"
    let params = [redeemAmountInWei, amountXVIXMin, amountTokenMin, account, deadline]
    contract[method](...params)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash
        toast.success(
          <div>
            Redeem submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Redeem failed.")
      })
      .finally(() => {
        setRemoving(false)
      })
  }


  if (isNaN(parsedRedeemAmount)) {
    error = "Invalid redeem amount."
  }

  if (redeemAmount === "0" || redeemAmount === "") {
    error = "Enter an amount to redeem."
  }

  let lgeAsset = asset === "eth" ? "XLGE:WETH" : "XLGE:DAI"
  if (redeemAmountInWei && redeemAmountInWei.gt && amounts && redeemAmountInWei.gt(amounts[0])) {
    error = `Amount exceeds ${formatAmount(amounts[0])} ${lgeAsset}`
  }

  if (!isUnlocked) {
    error = "Unlock time not yet reached."
  }

  if (!active) {
    error = "Login to Metamask to continue."
  }

  let xlgeEth
  let xlgeEthXvix
  let xlgeDai
  let xlgeDaiXvix

  if (refWethLpAmounts) {
    xlgeEth = refWethLpAmounts[1].mul(10000).div(refWethLpAmounts[0])
    xlgeEthXvix = refWethLpAmounts[2].mul(10000).div(refWethLpAmounts[0])
  }

  if (refDaiLpAmounts) {
    xlgeDai = refDaiLpAmounts[1].mul(10000).div(refDaiLpAmounts[0])
    xlgeDaiXvix = refDaiLpAmounts[2].mul(10000).div(refDaiLpAmounts[0])
  }

  return (
    <div>
      {isWarningVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsWarningVisible(false) }></div>
          <div className="modal-box">
            <strong>WARNING</strong>
            <br/>
            <br/>
            <div>
              You are redeeming {redeemAmount} {lgeAsset}.
            </div>
            <div>
              These tokens control {asset === "eth" ? `${formatAmount(redeemETHAmount)} ETH` : `${formatAmount(redeemDAIAmount)} DAI`} and {formatAmount(redeemXVIXAmount)} XVIX.
            </div>
            <br/>
            <div>
              When you redeem, you will receive {asset === "eth" ? `${formatAmount(redeemETHAmount)} ETH` : `${formatAmount(redeemDAIAmount)} DAI`}.
              The {formatAmount(redeemXVIXAmount)} XVIX will be permanently burnt.
            </div>
            <div>
            <br/>

            </div>
            <div className="align-right">
              <button onClick={ () => setIsWarningVisible(false) }>[ Cancel ]</button>&nbsp;&nbsp;
              <button onClick={ () => removeLiquidity() }>[ Continue Redemption ]</button>
            </div>
          </div>
        </div>
      }
      <div>
        1 <a href="https://etherscan.io/token/0xc278a41fc6cf7f488aea2d0ab321cc77128931d5" target="_blank" rel="noopener noreferrer">
          XLGE:WETH
        </a> is worth {xlgeEth ? parseFloat(xlgeEth / 10000).toFixed(4) : "*"} ETH and {xlgeEthXvix ? parseFloat(xlgeEthXvix / 10000).toFixed(4) : "*"} XVIX.
      </div>
      <div>
        1 <a href="https://etherscan.io/token/0x4e2249c1458f12c664fcfdf74fb1490b454c9327" target="_blank" rel="noopener noreferrer">
        XLGE:DAI
        </a> is worth {xlgeDai ? parseFloat(xlgeDai / 10000).toFixed(4) : "*"} DAI and {xlgeDaiXvix ? parseFloat(xlgeDaiXvix / 10000).toFixed(4) : "*"} XVIX.
      </div>
      <br/>
      <div>
        Since XLGE:WETH tokens receive 30% of X2 trading fees when staked, you can get a higher amount of ETH selling the token OTC compared to redeeming it.
      </div>
      <br/>
      <table>
        <tbody>
          <Account />
        </tbody>
        <tbody>
          <tr>
            <td>
              XLGE:WETH:
            </td>
            <td>
              {!wethLpAmounts && "*" }
              {wethLpAmounts &&
                <div>
                  <span className="clickable" onClick={ () => { setAsset("eth"); setRedeemAmount(formatArrayAmount(wethLpAmounts, 0)) } }>
                    { formatArrayAmount(wethLpAmounts, 0) }
                  </span> (
                  { formatArrayAmount(wethLpAmounts, 1) } ETH,&nbsp;
                  { formatArrayAmount(wethLpAmounts, 2) } XVIX)
                </div>
              }
            </td>
          </tr>
          <tr>
            <td>
              XLGE:DAI:
            </td>
            <td>
              {!daiLpAmounts && "*"}
              {daiLpAmounts &&
                <div>
                  <span className="clickable" onClick={ () => { setAsset("dai"); setRedeemAmount(formatArrayAmount(daiLpAmounts, 0)) } }>
                    { formatArrayAmount(daiLpAmounts, 0) }
                  </span> (
                  { formatArrayAmount(daiLpAmounts, 1) } DAI,&nbsp;
                  { formatArrayAmount(daiLpAmounts, 2) } XVIX)
                </div>
              }
            </td>
          </tr>
        </tbody>
        {!isUnlocked && <tbody>
          <tr>
            <td>
              Tokens Unlock In:
            </td>
            <td>
              { timeRemaining }
            </td>
          </tr>
        </tbody>}
        <tbody>
          <tr>
            <td>Redeem:</td>
            <td>
              <button className={cx("LPApp-settings-button",
                { active: asset === "eth" })} onClick={ () => setAsset("eth") }>
                XLGE:WETH
              </button>
              <button className={cx("LPApp-settings-button",
                { active: asset === "dai" })} onClick={ () => setAsset("dai") }>
                XLGE:DAI
              </button>
            </td>
          </tr>
          <tr>
            <td>Amount:</td>
            <td>
              <input type="text" placeholder="0.0000" size="10" value={redeemAmount} onChange={ (e) => setRedeemAmount(e.target.value) } />&nbsp;
              <button onClick={ () => setAdvancedMode(!advancedMode) }>
                {advancedMode && "[ Less ]" }
                {!advancedMode && "[ More ]" }
              </button>
            </td>
          </tr>
        </tbody>
        {advancedMode && <tbody>
          <tr>
            <td>
              Max Slippage:
            </td>
            <td>
              <button className={cx("LPApp-settings-button",
                { active: slippage === "0.1" })} onClick={ () => setSlippage("0.1") }>
                0.1%
              </button>
              <button className={cx("LPApp-settings-button",
                { active: slippage === "0.5" })} onClick={ () => setSlippage("0.5") }>
                0.5%
              </button>
              <button className={cx("LPApp-settings-button",
                { active: slippage === "1.0" })} onClick={ () => setSlippage("1.0") }>
                1.0%
              </button>
              <span className={cx("LPApp-slippage-container", { active: slippage === "" } )}>
                <input className="LPApp-slippage-input"
                  type="text" placeholder="2.0" value={slippageInput}
                  onChange={(e) => setSlippageInput(e.target.value) }
                  onClick={() => setSlippage("") } />
                <span className="LPApp-slippage-symbol">%</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              Valid For:
            </td>
            <td>
              <input type="text" placeholder="20" size="5" className="LPApp-valid-for-input" value={validFor} onChange={(e) => setValidFor(e.target.value)} /> minutes
            </td>
          </tr>
        </tbody>}
      </table>
      <div>
        You will receive: <br/>
        {toReceive} {asset.toUpperCase()}<br/>
        {toBurn !== 0 &&
          <div>
            + {refundedETH} ETH (from burning {toBurn} XVIX)
          </div>
        }
        {toToast !== 0 &&
          <div>
            - {toToast} XVIX will be permanently burnt
          </div>
        }
        {asset === "eth" && total !== 0 &&
          <div>
            <br/>
            Total: {total} ETH
          </div>
        }
        <br/>
        {error && <div className="Box-error">{error}</div>}
        {!error &&
          <button className="Dashboard-distributor-mint wide-button" onClick={ () => setIsWarningVisible(true) } disabled={removing}>
            {!removing && "[ Redeem ]"}
            {removing && "[ Redeeming... ]"}
          </button>
        }
      </div>
    </div>
  )
}

const FloorPrice = () => {
  const { library, active } = useWeb3React()

  const { data: capital, mutate: updateCapital } = useSWR([active, FLOOR_ADDRESS, 'capital'], {
    fetcher: fetcher(library, Floor),
  })

  const { data: supply, mutate: updateSupply } = useSWR([active, XVIX_ADDRESS, 'totalSupply'], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: ethReserve, mutate: updateEthReserve } = useSWR([active, MINTER_ADDRESS, 'ethReserve'], {
    fetcher: fetcher(library, Minter),
  })

  const { data: tokenReserve, mutate: updateTokenReserve } = useSWR([active, MINTER_ADDRESS, 'tokenReserve'], {
    fetcher: fetcher(library, Minter),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateCapital(undefined, true)
        updateSupply(undefined, true)
        updateEthReserve(undefined, true)
        updateTokenReserve(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateCapital, updateSupply, updateEthReserve, updateTokenReserve])

  let floorPriceStr = "*"
  if (capital && supply) {
    const minFloorPriceStr = (parseFloat(formatEther(capital.mul(9000).div(10000))) / parseFloat(formatEther(supply))).toFixed(6)
    floorPriceStr = `${minFloorPriceStr}`
  }

  let mintPriceStr = "*"
  if (ethReserve && tokenReserve) {
    const mintPrice = (parseFloat(formatEther(ethReserve)) / parseFloat(formatEther(tokenReserve)))
    mintPriceStr = mintPrice.toFixed(6)
    if (capital && supply) {
      const minMintPrice = (parseFloat(formatEther(capital.mul(10000).div(10000))) / parseFloat(formatEther(supply)))
      if (mintPrice < minMintPrice) {
        mintPriceStr = minMintPrice.toFixed(6)
      }
    }
  }

  return (
    <div>
      <div className="Dashboard-box">
        <div className="Dashboard-box-inner">
          <div className="Dashboard-box-label">
            <div>Floor Capital</div>
            <div className="Dashboard-box-label-link">
              <a href="https://etherscan.io/address/0x40ed3699c2ffe43939ecf2f3d11f633b522820ad" target="_blank" rel="noopener noreferrer" className="plain">
                <FaExternalLinkAlt />
              </a>
            </div>
          </div>
          { formatAmount(capital) } ETH
        </div>
      </div>
      <div className="Dashboard-box">
        <div className="Dashboard-box-inner">
          <div className="Dashboard-box-label">
            <div>Floor Price</div>
            <div className="Dashboard-box-label-link">
              <a href="https://xvi10.gitbook.io/xvix/xvix" target="_blank" rel="noopener noreferrer" className="FloorPrice-info plain">
                <FaExternalLinkAlt />
              </a>
            </div>
          </div>
          { floorPriceStr } ETH
        </div>
      </div>
      <div className="Dashboard-box">
        <div className="Dashboard-box-inner">
          <div className="Dashboard-box-label">Mint Price</div>
          { mintPriceStr } ETH
        </div>
      </div>
    </div>
  )
}

export const DashboardApp = () => {
  const [activatingConnector, setActivatingConnector] = React.useState()
  const { connector } = useWeb3React()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  const [isWithdrawalInfoModalVisible, setIsWithdrawalInfoModalVisible] = useState(false)
  const [isWithdrawalInfoModalVisibleV2, setIsWithdrawalInfoModalVisibleV2] = useState(false)
  const [isFinaliseWithdrawalModalVisible, setIsFinaliseWithdrawalModalVisible] = useState(false)
  const [isFinaliseWithdrawalModalVisibleV2, setIsFinaliseWithdrawalModalVisibleV2] = useState(false)
  const [isTimeVaultModalVisible, setIsTimeVaultModalVisible] = useState(false)
  const [ethWithdrawLpQuantity, setEthWithdrawLpQuantity] = useState("")
  const [xvixWithdrawLpQuantity, setXvixWithdrawLpQuantity] = useState("")
  const [isTimeVaultModalVisibleV2, setIsTimeVaultModalVisibleV2] = useState(false)
  const [isBurnVaultModalVisible, setIsBurnVaultModalVisible] = useState(false)
  const [isBurnVaultModalVisibleV2, setIsBurnVaultModalVisibleV2] = useState(false)
  const [isWithdrawLpModalVisible, setIsWithdrawLpModalVisible] = useState(false)
  const [isApprovingTimeVault, setIsApprovingTimeVault] = useState(false)
  const [isApprovingBurnVault, setIsApprovingBurnVault] = useState(false)
  const [isDepositing, setIsDepositing] = useState(false)
  const [isDepositingToBurnVault, setIsDepositingToBurnVault] = useState(false)
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const [isWithdrawingV2, setIsWithdrawingV2] = useState(false)
  const [isBurnVaultWithdrawing, setIsBurnVaultWithdrawing] = useState(false)
  const [isBurnVaultWithdrawingV2, setIsBurnVaultWithdrawingV2] = useState(false)
  const [isWithdrawingLp, setIsWithdrawingLp] = useState(false)
  const [isFinalisingWithdrawal, setIsFinalisingWithdrawal] = useState(false)
  const [isFinalisingWithdrawalV2, setIsFinalisingWithdrawalV2] = useState(false)
  const [timeVaultQuantity, setTimeVaultQuantity] = useState("")
  const [ethLpQuantity, setEthLpQuantity] = useState("")
  const [xvixLpQuantity, setXvixLpQuantity] = useState("")
  const [burnVaultQuantity, setBurnVaultQuantity] = useState("")
  const [withdrawQuantity, setWithdrawQuantity] = useState("")
  const [withdrawQuantityV2, setWithdrawQuantityV2] = useState("")
  const [burnVaultWithdrawQuantity, setBurnVaultWithdrawQuantity] = useState("")
  const [burnVaultWithdrawQuantityV2, setBurnVaultWithdrawQuantityV2] = useState("")
  const [burnOption, setBurnOption] = useState("TimeVault")
  const { account, activate, active, library } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  const xvixEthUniPair = getContract(CHAIN_ID, "XvixEthUniPair")
  const xvixDaiUniPair = getContract(CHAIN_ID, "XvixDaiUniPair")
  const timeVault = getContract(CHAIN_ID, "TimeVault")
  const timeVaultV2 = getContract(CHAIN_ID, "TimeVaultV2")
  const burnVault = getContract(CHAIN_ID, "BurnVault")
  const burnVaultV2 = getContract(CHAIN_ID, "BurnVaultV2")
  const supplyAccounts = [xvixEthUniPair, xvixDaiUniPair, timeVault, burnVault, timeVaultV2, burnVaultV2]
  const [isAddingLiquidity, setIsAddingLiquidity] = useState(false)
  const [isApprovingRouter, setIsApprovingRouter] = useState(false)
  const [isApprovingLp, setIsApprovingLp] = useState(false)

  const shouldLogTimelockInfo = false
  const timelockSlots = []
  const currentTimelockSlot = parseInt(Date.now() / (48 * 60 * 60 * 1000))
  const slotCount = 5

  for (let i = 0; i < slotCount; i++) {
    timelockSlots.push(currentTimelockSlot + i)
  }

  const { data: timelockInfo, mutate: updateTimelockInfo } = useSWR([active, READER_ADDRESS, 'getWithdrawalSlots', getContract(CHAIN_ID, "TimeVault")], {
    fetcher: fetcher(library, Reader, [timelockSlots]),
  })

  if (shouldLogTimelockInfo && timelockInfo) {
    for (let i = 0; i < timelockInfo.length; i++) {
      const slot = timelockSlots[i]
      console.info("Timelock", formatDate(slot * 48 * 60 * 60 * 1000, "dd MMM yyyy"), formatAmount(timelockInfo[i]))
    }
  }

  const { data: supplyInfo, mutate: updateSupplyInfo } = useSWR([active, READER_ADDRESS, 'getBalances', XVIX_ADDRESS], {
    fetcher: fetcher(library, Reader, [supplyAccounts]),
  })

  const { data: maxSupply, mutate: updateMaxSupply } = useSWR([active, XVIX_ADDRESS, 'maxSupply'], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: timeVaultApprovedAmount, mutate: updateTimeVaultApprovedAmount } = useSWR([active, XVIX_ADDRESS, 'allowance', account, timeVaultV2], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: burnVaultApprovedAmount, mutate: updateBurnVaultApprovedAmount } = useSWR([active, XVIX_ADDRESS, 'allowance', account, burnVaultV2], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: routerApprovedAmount, mutate: updateRouterApprovedAmount } = useSWR([active, XVIX_ADDRESS, 'allowance', account, getContract(CHAIN_ID, "XvixRouter")], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: uniLpApprovedAmount, mutate: updateUniLpApprovedAmount } = useSWR([active, getContract(CHAIN_ID, "XvixEthUniPair"), 'allowance', account, getContract(CHAIN_ID, "XvixRouter")], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: timeVaultBalance, mutate: updateTimeVaultBalance } = useSWR([active, getContract(CHAIN_ID, "TimeVault"), 'balanceOf', account], {
    fetcher: fetcher(library, TimeVault),
  })

  const { data: timeVaultBalanceV2, mutate: updateTimeVaultBalanceV2 } = useSWR([active, getContract(CHAIN_ID, "TimeVaultV2"), 'balanceOf', account], {
    fetcher: fetcher(library, TimeVault),
  })

  const { data: burnVaultBalance, mutate: updateBurnVaultBalance } = useSWR([active, getContract(CHAIN_ID, "BurnVault"), 'balanceOf', account], {
    fetcher: fetcher(library, BurnVault),
  })

  const { data: burnVaultBalanceV2, mutate: updateBurnVaultBalanceV2 } = useSWR([active, getContract(CHAIN_ID, "BurnVaultV2"), 'balanceOf', account], {
    fetcher: fetcher(library, BurnVault),
  })

  const { data: withdrawalTime, mutate: updateWithdrawalTime } = useSWR([active, getContract(CHAIN_ID, "TimeVault"), 'withdrawalTimestamps', account], {
    fetcher: fetcher(library, TimeVault),
  })

  const { data: withdrawalTimeV2, mutate: updateWithdrawalTimeV2 } = useSWR([active, getContract(CHAIN_ID, "TimeVaultV2"), 'withdrawalTimestamps', account], {
    fetcher: fetcher(library, TimeVault),
  })

  const { data: pendingWithdrawalAmount, mutate: updatePendingWithdrawalAmount } = useSWR([active, getContract(CHAIN_ID, "TimeVault"), 'withdrawalAmounts', account], {
    fetcher: fetcher(library, TimeVault),
  })

  const { data: pendingWithdrawalAmountV2, mutate: updatePendingWithdrawalAmountV2 } = useSWR([active, getContract(CHAIN_ID, "TimeVaultV2"), 'withdrawalAmounts', account], {
    fetcher: fetcher(library, TimeVault),
  })

  const { data: xvixBalance, mutate: updateXvixBalance } = useSWR([active, XVIX_ADDRESS, 'balanceOf', account], {
    fetcher: fetcher(library, Xvix),
  })

  const { data: ethBalance, mutate: updateEthBalance } = useSWR([active, 'getBalance', account, 'latest'], {
    fetcher: fetcher(library),
  })

  const { data: poolAmounts, mutate: updatePoolAmounts } = useSWR([active, READER_ADDRESS, 'getPoolAmounts', account, WETH_ADDRESS, XVIX_ADDRESS], {
    fetcher: fetcher(library, Reader),
  })

  const { data: stakedUni, mutate: updateStakedUni } = useSWR([active, getContract(CHAIN_ID, "UniFarm"), 'balanceOf', account], {
    fetcher: fetcher(library, Farm),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateSupplyInfo(undefined, true)
        updateTimeVaultApprovedAmount(undefined, true)
        updateBurnVaultApprovedAmount(undefined, true)
        updateTimeVaultBalance(undefined, true)
        updateTimeVaultBalanceV2(undefined, true)
        updateBurnVaultBalance(undefined, true)
        updateBurnVaultBalanceV2(undefined, true)
        updateXvixBalance(undefined, true)
        updateWithdrawalTime(undefined, true)
        updateWithdrawalTimeV2(undefined, true)
        updatePendingWithdrawalAmount(undefined, true)
        updatePendingWithdrawalAmountV2(undefined, true)
        updateTimelockInfo(undefined, true)
        updateEthBalance(undefined, true)
        updatePoolAmounts(undefined, true)
        updateRouterApprovedAmount(undefined, true)
        updateUniLpApprovedAmount(undefined, true)
        updateMaxSupply(undefined, true)
        updateStakedUni(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, supplyAccounts, updateSupplyInfo, updateXvixBalance,
      updateTimeVaultApprovedAmount, updateBurnVaultApprovedAmount,
      updateTimeVaultBalance, updateTimeVaultBalanceV2,
      updateBurnVaultBalance, updateBurnVaultBalanceV2,
      updatePendingWithdrawalAmount, updatePendingWithdrawalAmountV2,
      updateWithdrawalTime, updateWithdrawalTimeV2, updateTimelockInfo,
      updateEthBalance, updatePoolAmounts, updateRouterApprovedAmount,
      updateUniLpApprovedAmount, updateMaxSupply, updateStakedUni])


  let stakedUniXvix
  let stakedUniEth

  if (stakedUni && poolAmounts) {
    stakedUniEth = poolAmounts[2].mul(stakedUni).div(poolAmounts[4])
    stakedUniXvix = poolAmounts[3].mul(stakedUni).div(poolAmounts[4])
  }

  const parsedEthLpQuantity = parseFloat(ethLpQuantity)
  const parsedXvixLpQuantity = parseFloat(xvixLpQuantity)
  let ethLpQuantityInWei
  let xvixLpQuantityInWei

  if (!isNaN(parsedEthLpQuantity)) {
    ethLpQuantityInWei = ethers.utils.parseEther(parsedEthLpQuantity.toString())
  }

  if (!isNaN(parsedXvixLpQuantity)) {
    xvixLpQuantityInWei = ethers.utils.parseEther(parsedXvixLpQuantity.toString())
  }

  let needRouterApproval = false
  if (xvixLpQuantityInWei && routerApprovedAmount) {
    needRouterApproval = routerApprovedAmount.lt(xvixLpQuantityInWei)
  }

  let addLiquidityError = false

  if (isNaN(parsedXvixLpQuantity)) {
    addLiquidityError = "Invalid quantity"
  }
  if (xvixLpQuantity === "" || (xvixLpQuantityInWei && xvixLpQuantityInWei.eq(0))) {
    addLiquidityError = "Enter an amount"
  }
  if (xvixLpQuantityInWei && xvixBalance && xvixLpQuantityInWei.gt(xvixBalance)) {
    addLiquidityError = "Insufficient XVIX"
  }

  if (isNaN(parsedEthLpQuantity)) {
    addLiquidityError = "Invalid quantity"
  }
  if (ethLpQuantity === "" || (ethLpQuantityInWei && ethLpQuantityInWei.eq(0))) {
    addLiquidityError = "Enter an amount"
  }
  if (ethLpQuantityInWei && ethBalance && ethLpQuantityInWei.gt(ethBalance)) {
    addLiquidityError = "Insufficient ETH"
  }

  const parsedEthWithdrawLpQuantity = parseFloat(ethWithdrawLpQuantity)
  const parsedXvixWithdrawLpQuantity = parseFloat(xvixWithdrawLpQuantity)
  let ethWithdrawLpQuantityInWei
  let xvixWithdrawLpQuantityInWei

  if (!isNaN(parsedEthWithdrawLpQuantity)) {
    ethWithdrawLpQuantityInWei = ethers.utils.parseEther(parsedEthWithdrawLpQuantity.toString())
  }

  if (!isNaN(parsedXvixWithdrawLpQuantity)) {
    xvixWithdrawLpQuantityInWei = ethers.utils.parseEther(parsedXvixWithdrawLpQuantity.toString())
  }

  let withdrawLpError = false

  if (isNaN(parsedXvixWithdrawLpQuantity)) {
    withdrawLpError = "Invalid quantity"
  }
  if (xvixWithdrawLpQuantity === "" || (xvixWithdrawLpQuantityInWei && xvixWithdrawLpQuantityInWei.eq(0))) {
    withdrawLpError = "Enter an amount"
  }
  if (xvixWithdrawLpQuantityInWei && poolAmounts && poolAmounts[1] && xvixWithdrawLpQuantityInWei.gt(poolAmounts[1])) {
    withdrawLpError = "Insufficient XVIX"
  }

  if (isNaN(parsedEthWithdrawLpQuantity)) {
    withdrawLpError = "Invalid quantity"
  }
  if (ethWithdrawLpQuantity === "" || (ethWithdrawLpQuantityInWei && ethWithdrawLpQuantityInWei.eq(0))) {
    withdrawLpError = "Enter an amount"
  }
  if (ethWithdrawLpQuantityInWei && poolAmounts && poolAmounts[0] && ethWithdrawLpQuantityInWei.gt(poolAmounts[0])) {
    withdrawLpError = "Insufficient ETH"
  }

  let needRounterWithdrawApproval = false
  if (xvixWithdrawLpQuantityInWei && poolAmounts && poolAmounts[3]) {
    const liquidity = poolAmounts[4].mul(xvixWithdrawLpQuantityInWei).div(poolAmounts[3])
    needRounterWithdrawApproval = uniLpApprovedAmount.lt(liquidity)
  }

  const updateEthLpQuantity = (amount) => {
    const parsedAmount = parseFloat(amount)
    if (isNaN(parsedAmount) || !poolAmounts) {
      setEthLpQuantity(amount)
      return
    }

    const amountInWei = ethers.utils.parseEther(parsedAmount.toString())
    const xvixQuantityInWei = amountInWei.mul(poolAmounts[3]).div(poolAmounts[2])
    setEthLpQuantity(amount)
    setXvixLpQuantity(formatAmountFree(xvixQuantityInWei))
  }

  const updateXvixLpQuantity = (amount) => {
    const parsedAmount = parseFloat(amount)
    if (isNaN(parsedAmount) || !poolAmounts) {
      setXvixLpQuantity(amount)
      return
    }

    const amountInWei = ethers.utils.parseEther(parsedAmount.toString())
    const ethQuantityInWei = amountInWei.mul(poolAmounts[2]).div(poolAmounts[3])
    setXvixLpQuantity(amount)
    setEthLpQuantity(formatAmountFree(ethQuantityInWei))
  }

  const updateEthWithdrawLpQuantity = (amount) => {
    const parsedAmount = parseFloat(amount)
    if (isNaN(parsedAmount) || !poolAmounts) {
      setEthWithdrawLpQuantity(amount)
      return
    }

    const amountInWei = ethers.utils.parseEther(parsedAmount.toString())
    const xvixQuantityInWei = amountInWei.mul(poolAmounts[3]).div(poolAmounts[2])
    setEthWithdrawLpQuantity(amount)
    setXvixWithdrawLpQuantity(formatAmountFree(xvixQuantityInWei))
  }

  const updateXvixWithdrawLpQuantity = (amount) => {
    const parsedAmount = parseFloat(amount)
    if (isNaN(parsedAmount) || !poolAmounts) {
      setXvixWithdrawLpQuantity(amount)
      return
    }

    const amountInWei = ethers.utils.parseEther(parsedAmount.toString())
    const ethQuantityInWei = amountInWei.mul(poolAmounts[2]).div(poolAmounts[3])
    setXvixWithdrawLpQuantity(amount)
    setEthWithdrawLpQuantity(formatAmountFree(ethQuantityInWei))
  }

  autoActivate(active, activate)

  let totalSupply = ethers.utils.parseEther("300")
  let circulatingSupply = ethers.utils.parseEther("100")
  let uniSupply = ethers.utils.parseEther("100")
  let vaultSupply = ethers.utils.parseEther("100")
  let burnVaultSupply = ethers.utils.parseEther("100")

  if (supplyInfo) {
    totalSupply = supplyInfo[0]
    uniSupply = supplyInfo[1].add(supplyInfo[2])
    vaultSupply = supplyInfo[3].add(supplyInfo[5])
    burnVaultSupply = supplyInfo[4].add(supplyInfo[6])
    circulatingSupply = totalSupply.sub(uniSupply).sub(vaultSupply).sub(burnVaultSupply)
  }

  const supplyData = [
    { name: active ? 'Circulating' : 'Connect', value: parseInt(formatAmount(circulatingSupply)) },
    { name: active ? 'Uniswap' : 'Metamask', value: parseInt(formatAmount(uniSupply)) },
    { name: active ? 'Burn Reduction Vault' : 'To', value: parseInt(formatAmount(burnVaultSupply)) },
    { name: active ? 'Timelock Vault' : 'View', value: parseInt(formatAmount(vaultSupply)) }
  ]

  const supplyColors = ['#ed243b', '#ff8c00', '#ffc800', '#383dc9']
  // const supplyColors = ['#ff9830', '#cc3f83', '#3948bd']

  let needApproval = false
  const parsedTimeVaultQuantity = parseFloat(timeVaultQuantity)
  let timeVaultQuantityInWei

  if (!isNaN(parsedTimeVaultQuantity)) {
    timeVaultQuantityInWei = ethers.utils.parseEther(parsedTimeVaultQuantity.toString())
  }

  if (timeVaultQuantityInWei && timeVaultApprovedAmount) {
    needApproval = timeVaultApprovedAmount.lt(timeVaultQuantityInWei)
  }

  let needBurnVaultApproval = false
  const parsedBurnVaultQuantity = parseFloat(burnVaultQuantity)
  let burnVaultQuantityInWei

  if (!isNaN(parsedBurnVaultQuantity)) {
    burnVaultQuantityInWei = ethers.utils.parseEther(parsedBurnVaultQuantity.toString())
  }

  if (burnVaultQuantityInWei && burnVaultApprovedAmount) {
    needBurnVaultApproval = burnVaultApprovedAmount.lt(burnVaultQuantityInWei)
  }

  let timeVaultError = false
  if (isNaN(parsedTimeVaultQuantity)) {
    timeVaultError = "Invalid quantity"
  }
  if (timeVaultQuantity === "" || (timeVaultQuantityInWei && timeVaultQuantityInWei.eq(0))) {
    timeVaultError = "Enter an amount"
  }
  if (timeVaultQuantityInWei && xvixBalance && timeVaultQuantityInWei.gt(xvixBalance)) {
    timeVaultError = "Insufficient XVIX"
  }

  let burnVaultError = false
  if (isNaN(parsedBurnVaultQuantity)) {
    burnVaultError = "Invalid quantity"
  }
  if (burnVaultQuantity === "" || (burnVaultQuantityInWei && burnVaultQuantityInWei.eq(0))) {
    burnVaultError = "Enter an amount"
  }
  if (burnVaultQuantityInWei && xvixBalance && burnVaultQuantityInWei.gt(xvixBalance)) {
    burnVaultError = "Insufficient XVIX"
  }

  const parsedWithdrawQuantity = parseFloat(withdrawQuantity)
  let withdrawQuantityInWei

  if (!isNaN(parsedWithdrawQuantity)) {
    withdrawQuantityInWei = ethers.utils.parseEther(parsedWithdrawQuantity.toString())
  }

  let withdrawError = false
  if (isNaN(parsedWithdrawQuantity)) {
    withdrawError = "Invalid quantity"
  }
  if (withdrawQuantity === "" || (withdrawQuantityInWei && withdrawQuantityInWei.eq(0))) {
    withdrawError = "Enter an amount"
  }
  if (withdrawQuantityInWei && timeVaultBalance && withdrawQuantityInWei.gt(timeVaultBalance)) {
    withdrawError = "Insufficient XVIX"
  }

  const parsedWithdrawQuantityV2 = parseFloat(withdrawQuantityV2)
  let withdrawQuantityInWeiV2

  if (!isNaN(parsedWithdrawQuantityV2)) {
    withdrawQuantityInWeiV2 = ethers.utils.parseEther(parsedWithdrawQuantityV2.toString())
  }

  let withdrawErrorV2 = false
  if (isNaN(parsedWithdrawQuantityV2)) {
    withdrawErrorV2 = "Invalid quantity"
  }
  if (withdrawQuantityV2 === "" || (withdrawQuantityInWeiV2 && withdrawQuantityInWeiV2.eq(0))) {
    withdrawErrorV2 = "Enter an amount"
  }
  if (withdrawQuantityInWeiV2 && timeVaultBalanceV2 && withdrawQuantityInWeiV2.gt(timeVaultBalanceV2)) {
    withdrawErrorV2 = "Insufficient XVIX"
  }

  const parsedBurnVaultWithdrawQuantity = parseFloat(burnVaultWithdrawQuantity)
  let burnVaultWithdrawQuantityInWei
  if (!isNaN(parsedBurnVaultWithdrawQuantity)) {
    burnVaultWithdrawQuantityInWei = ethers.utils.parseEther(parsedBurnVaultWithdrawQuantity.toString())
  }

  let burnVaultWithdrawError = false
  if (isNaN(parsedBurnVaultWithdrawQuantity)) {
    burnVaultWithdrawError = "Invalid quantity"
  }
  if (burnVaultWithdrawQuantity === "" || (burnVaultWithdrawQuantityInWei && burnVaultWithdrawQuantityInWei.eq(0))) {
    burnVaultWithdrawError = "Enter an amount"
  }
  if (burnVaultWithdrawQuantityInWei && burnVaultBalance && burnVaultWithdrawQuantityInWei.gt(burnVaultBalance)) {
    burnVaultWithdrawError = "Insufficient XVIX"
  }

  const parsedBurnVaultWithdrawQuantityV2 = parseFloat(burnVaultWithdrawQuantityV2)
  let burnVaultWithdrawQuantityInWeiV2
  if (!isNaN(parsedBurnVaultWithdrawQuantityV2)) {
    burnVaultWithdrawQuantityInWeiV2 = ethers.utils.parseEther(parsedBurnVaultWithdrawQuantityV2.toString())
  }

  let burnVaultWithdrawErrorV2 = false
  if (isNaN(parsedBurnVaultWithdrawQuantityV2)) {
    burnVaultWithdrawErrorV2 = "Invalid quantity"
  }
  if (burnVaultWithdrawQuantityV2 === "" || (burnVaultWithdrawQuantityInWeiV2 && burnVaultWithdrawQuantityInWeiV2.eq(0))) {
    burnVaultWithdrawErrorV2 = "Enter an amount"
  }
  if (burnVaultWithdrawQuantityInWeiV2 && burnVaultBalanceV2 && burnVaultWithdrawQuantityInWeiV2.gt(burnVaultBalanceV2)) {
    burnVaultWithdrawErrorV2 = "Insufficient XVIX"
  }

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  const approveTokens = async (spender) => {
    const contract = new ethers.Contract(XVIX_ADDRESS, Xvix.abi, library.getSigner())
    contract.approve(spender, ethers.constants.MaxUint256)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Approval submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Approval failed.")
      })
      .finally(() => {
        setIsApprovingTimeVault(false)
        setIsApprovingBurnVault(false)
      })
  }

  const approveRouter = async (spender) => {
    setIsApprovingRouter(true)
    const contract = new ethers.Contract(XVIX_ADDRESS, Xvix.abi, library.getSigner())
    contract.approve(spender, ethers.constants.MaxUint256)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Approval submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Approval failed.")
      })
      .finally(() => {
        setIsApprovingRouter(false)
      })
  }

  const approveLp = async (spender) => {
    setIsApprovingLp(true)
    const contract = new ethers.Contract(getContract(CHAIN_ID, "XvixEthUniPair"), Xvix.abi, library.getSigner())
    contract.approve(spender, ethers.constants.MaxUint256)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Approval submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Approval failed.")
      })
      .finally(() => {
        setIsApprovingLp(false)
      })
  }

  const burnVaultWithdraw = async () => {
    setIsBurnVaultWithdrawing(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "BurnVault"), BurnVault.abi, library.getSigner())
    contract.withdraw(account, burnVaultWithdrawQuantityInWei)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdrawal submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setBurnVaultWithdrawQuantity("")
        setIsBurnVaultModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw failed.")
      })
      .finally(() => {
        setIsBurnVaultWithdrawing(false)
      })
  }

  const burnVaultWithdrawV2 = async () => {
    setIsBurnVaultWithdrawingV2(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "BurnVaultV2"), BurnVault.abi, library.getSigner())
    let gasLimit = await contract.estimateGas.withdraw(account, burnVaultWithdrawQuantityInWeiV2)
    if (gasLimit.lt(200000)) {
      gasLimit = ethers.BigNumber.from(200000)
    }
    contract.withdraw(account, burnVaultWithdrawQuantityInWeiV2, { gasLimit })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdrawal submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setBurnVaultWithdrawQuantityV2("")
        setIsBurnVaultModalVisibleV2(false)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw failed.")
      })
      .finally(() => {
        setIsBurnVaultWithdrawingV2(false)
      })
  }

  const beginWithdrawal = async () => {
    if (hasPendingWithdrawal || canFinaliseWithdrawal) {
      const r = window.confirm("You are overwriting a withdrawal, proceed?")
      if (!r) {
        return
      }
    }
    setIsWithdrawing(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "TimeVault"), TimeVault.abi, library.getSigner())
    contract.beginWithdrawal(withdrawQuantityInWei)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdrawal submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setWithdrawQuantity("")
        setIsTimeVaultModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw failed.")
      })
      .finally(() => {
        setIsWithdrawing(false)
      })
  }

  const beginWithdrawalV2 = async () => {
    if (hasPendingWithdrawalV2 || canFinaliseWithdrawalV2) {
      const r = window.confirm("You are overwriting a withdrawal, proceed?")
      if (!r) {
        return
      }
    }
    setIsWithdrawingV2(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "TimeVaultV2"), TimeVault.abi, library.getSigner())
    contract.beginWithdrawal(withdrawQuantityInWeiV2)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdrawal submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setWithdrawQuantityV2("")
        setIsTimeVaultModalVisibleV2(false)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw failed.")
      })
      .finally(() => {
        setIsWithdrawingV2(false)
      })
  }

  const finaliseWithdrawal = async () => {
    setIsFinalisingWithdrawal(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "TimeVault"), TimeVault.abi, library.getSigner())
    let gasLimit = await contract.estimateGas.withdraw(account)
    if (gasLimit.lt(200000)) {
      gasLimit = ethers.BigNumber.from(200000)
    }
    contract.withdraw(account, { gasLimit })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdrawal submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setIsFinaliseWithdrawalModalVisible(false)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw failed.")
      })
      .finally(() => {
        setIsFinalisingWithdrawal(false)
      })
  }

  const finaliseWithdrawalV2 = async () => {
    setIsFinalisingWithdrawalV2(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "TimeVaultV2"), TimeVault.abi, library.getSigner())
    contract.withdraw(account)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdrawal submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setIsFinaliseWithdrawalModalVisibleV2(false)
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw failed.")
      })
      .finally(() => {
        setIsFinalisingWithdrawalV2(false)
      })
  }

  const onClickPrimary = async () => {
    if (!active) {
      activateMetamask()
      return
    }

    if (needApproval) {
      setIsApprovingTimeVault(true)
      approveTokens(getContract(CHAIN_ID, "TimeVaultV2"))
      return
    }

    setIsDepositing(true)
    const contract = new ethers.Contract(getContract(CHAIN_ID, "TimeVaultV2"), TimeVault.abi, library.getSigner())
    contract.deposit(timeVaultQuantityInWei, { gasLimit: ethers.BigNumber.from(250000) })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Deposit submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setTimeVaultQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Deposit failed.")
      })
      .finally(() => {
        setIsDepositing(false)
      })
  }

  const withdrawLp = async () => {
    if (needRounterWithdrawApproval) {
      approveLp(getContract(CHAIN_ID, "XvixRouter"))
      return
    }

    setIsWithdrawingLp(true)

    const contract = new ethers.Contract(getContract(CHAIN_ID, "XvixRouter"), XvixRouter.abi, library.getSigner())
    const amountXVIXMin = xvixWithdrawLpQuantityInWei.mul(9950).div(10000)
    const amountETHMin = ethWithdrawLpQuantityInWei.mul(9950).div(10000)
    const deadline = parseInt(Date.now() / 1000 + 20 * 60)
    const liquidity = poolAmounts[4].mul(xvixWithdrawLpQuantityInWei).div(poolAmounts[3])
    contract.removeLiquidityETHSupportingFeeOnTransferTokens(XVIX_ADDRESS, liquidity, amountXVIXMin, amountETHMin, account, deadline)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Withdraw liquidity submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setEthLpQuantity("")
        setXvixLpQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Withdraw liquidity failed.")
      })
      .finally(() => {
        setIsWithdrawingLp(false)
      })
  }

  const addLiquidity = async () => {
    if (!active) {
      activateMetamask()
      return
    }
    if (needRouterApproval) {
      approveRouter(getContract(CHAIN_ID, "XvixRouter"))
      return
    }
    setIsAddingLiquidity(true)
    const contract = new ethers.Contract(getContract(CHAIN_ID, "XvixRouter"), XvixRouter.abi, library.getSigner())
    const amountXVIXMin = xvixLpQuantityInWei.mul(9950).div(10000)
    const amountETHMin = ethLpQuantityInWei.mul(9950).div(10000)
    const deadline = parseInt(Date.now() / 1000 + 20 * 60)
    contract.addLiquidityETH(XVIX_ADDRESS, xvixLpQuantityInWei, amountXVIXMin, amountETHMin, account, deadline, { value: ethLpQuantityInWei })
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Add liquidity submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setEthLpQuantity("")
        setXvixLpQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Add liquidity failed.")
      })
      .finally(() => {
        setIsAddingLiquidity(false)
      })
  }

  const onClickBurnVaultPrimary = async () => {
    if (!active) {
      activateMetamask()
      return
    }

    if (needBurnVaultApproval) {
      setIsApprovingBurnVault(true)
      approveTokens(getContract(CHAIN_ID, "BurnVaultV2"))
      return
    }

    setIsDepositingToBurnVault(true)
    const contract = new ethers.Contract(getContract(CHAIN_ID, "BurnVaultV2"), BurnVault.abi, library.getSigner())
    contract.deposit(burnVaultQuantityInWei)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Deposit submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setBurnVaultQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Deposit failed.")
      })
      .finally(() => {
        setIsDepositingToBurnVault(false)
      })
  }

  let hasPendingWithdrawal = false
  let canFinaliseWithdrawal = false
  let minTime
  let maxTime
  if (withdrawalTime && withdrawalTime.gt(0) && pendingWithdrawalAmount && pendingWithdrawalAmount.gt(0)) {
    minTime = withdrawalTime.toNumber()
    maxTime = minTime + 48 * 60 * 60
    const now = parseInt(Date.now() / 1000)
    if (now < minTime) {
      hasPendingWithdrawal = true
    }
    if (now > minTime && now < maxTime) {
      canFinaliseWithdrawal = true
    }
  }

  let hasPendingWithdrawalV2 = false
  let canFinaliseWithdrawalV2 = false
  let minTimeV2
  let maxTimeV2
  if (withdrawalTimeV2 && withdrawalTimeV2.gt(0) && pendingWithdrawalAmountV2 && pendingWithdrawalAmountV2.gt(0)) {
    minTimeV2 = withdrawalTimeV2.toNumber()
    maxTimeV2 = minTimeV2 + 48 * 60 * 60
    const now = parseInt(Date.now() / 1000)
    if (now < minTimeV2) {
      hasPendingWithdrawalV2 = true
    }
    if (now > minTimeV2 && now < maxTimeV2) {
      canFinaliseWithdrawalV2 = true
    }
  }

  const onWithdrawClickV2 = () => {
    if (hasPendingWithdrawalV2) {
      setIsWithdrawalInfoModalVisibleV2(true)
      return
    }
    if (canFinaliseWithdrawalV2) {
      setIsFinaliseWithdrawalModalVisibleV2(true)
      return
    }
    setIsTimeVaultModalVisibleV2(true)
  }

  const onWithdrawClick = () => {
    if (hasPendingWithdrawal) {
      setIsWithdrawalInfoModalVisible(true)
      return
    }
    if (canFinaliseWithdrawal) {
      setIsFinaliseWithdrawalModalVisible(true)
      return
    }
    setIsTimeVaultModalVisible(true)
  }

  const xvixTokenURL = getTokenUrl(CHAIN_ID, XVIX_ADDRESS, account)
  const uniLpTokenURL = getTokenUrl(CHAIN_ID, getContract(CHAIN_ID, "XvixEthUniPair"), account)
  const burnVaultTokenURLV2 = getTokenUrl(CHAIN_ID, burnVaultV2, account)
  const timeVaultTokenURLV2 = getTokenUrl(CHAIN_ID, timeVaultV2, account)
  const burnVaultTokenURL = getExplorerUrl(CHAIN_ID) + "address/" + burnVault
  const timeVaultTokenURL = getExplorerUrl(CHAIN_ID) + "address/" + timeVault

  const getWithdrawalText = () => {
    if (isWithdrawing) {
      return (hasPendingWithdrawal || canFinaliseWithdrawal) ? "Overwriting" : "Withdrawing"
    }
    if (withdrawError) {
      return withdrawError
    }

    return (hasPendingWithdrawal || canFinaliseWithdrawal) ? "Overwrite" : "Withdraw"
  }

  const getWithdrawalTextV2 = () => {
    if (isWithdrawingV2) {
      return (hasPendingWithdrawalV2 || canFinaliseWithdrawalV2) ? "Overwriting" : "Withdrawing"
    }
    if (withdrawErrorV2) {
      return withdrawErrorV2
    }

    return (hasPendingWithdrawalV2 || canFinaliseWithdrawalV2) ? "Overwrite" : "Withdraw"
  }

  return (
    <div className="DashboardApp">
      {isFinaliseWithdrawalModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsFinaliseWithdrawalModalVisible(false) }></div>
          <div className="modal-box Dashboard-finalise=withdrawal-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Finalise Withdrawal</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsFinaliseWithdrawalModalVisible(false) } />
              </div>
              You have until {formatDate(maxTime * 1000, "dd MMM yyyy, h:mm a")} to finalise your withdrawal of {formatAmount(pendingWithdrawalAmount)} XVIX.
              <br/>
              <br/>
              If you do not withdraw by this time, your tokens will remain in the Timelock Vault and you can re-initiate another withdrawal if required.
              <br/>
              <br/>
              Tokens remaining in the Timelock Vault will not have any burns.
              <br/>
              <br/>
              You can also choose to overwite this withdrawal.
            </div>
            <br/>
            <div className="Dashboard-withdraw-info-button-box">
              <button type="button" className="Dashboard-button-primary" onClick={() => { setIsFinaliseWithdrawalModalVisible(false); setIsTimeVaultModalVisible(true) } }>
                Overwrite
              </button>
              <button type="button" className="Dashboard-button-primary" onClick = { () => finaliseWithdrawal() } >
                {isFinalisingWithdrawal && "Finalising..."}
                {!isFinalisingWithdrawal && "Finalise"}
              </button>
            </div>
          </div>
        </div>
      }
      {isFinaliseWithdrawalModalVisibleV2 &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsFinaliseWithdrawalModalVisibleV2(false) }></div>
          <div className="modal-box Dashboard-finalise=withdrawal-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Finalise Withdrawal</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsFinaliseWithdrawalModalVisibleV2(false) } />
              </div>
              You have until {formatDate(maxTimeV2 * 1000, "dd MMM yyyy, h:mm a")} to finalise your withdrawal of {formatAmount(pendingWithdrawalAmountV2)} XVIX.
              <br/>
              <br/>
              If you do not withdraw by this time, your tokens will remain in the Timelock Vault and you can re-initiate another withdrawal if required.
              <br/>
              <br/>
              Tokens remaining in the Timelock Vault will not have any burns.
              <br/>
              <br/>
              You can also choose to overwite this withdrawal.
            </div>
            <br/>
            <div className="Dashboard-withdraw-info-button-box">
              <button type="button" className="Dashboard-button-primary" onClick={() => { setIsFinaliseWithdrawalModalVisibleV2(false); setIsTimeVaultModalVisibleV2(true) } }>
                Overwrite
              </button>
              <button type="button" className="Dashboard-button-primary" onClick = { () => finaliseWithdrawalV2() } >
                {isFinalisingWithdrawalV2 && "Finalising..."}
                {!isFinalisingWithdrawalV2 && "Finalise"}
              </button>
            </div>
          </div>
        </div>
      }
      {isWithdrawalInfoModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsWithdrawalInfoModalVisible(false) }></div>
          <div className="modal-box Dashboard-withdraw-info-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Pending Withdrawal</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsWithdrawalInfoModalVisible(false) } />
              </div>
            </div>
            <div>
              You have a pending withdrawal for {formatAmount(pendingWithdrawalAmount)} XVIX.
            </div>
            <div>
              You can finalise your withdrawal between {formatDate(minTime * 1000, "dd MMM yyyy, h:mm a")} and {formatDate(maxTime * 1000, "dd MMM yyyy, h:mm a")}.
            </div>
            <br/>
            <div>
              You can also overwrite the withdrawal amount, note that  this will reset the withdrawal timer.
            </div>
            <div className="Dashboard-withdraw-info-button-box">
              <button type="button" className="Dashboard-button-primary" onClick={() => { setIsWithdrawalInfoModalVisible(false); setIsTimeVaultModalVisible(true) } }>
                Overwrite
              </button>
            </div>
          </div>
        </div>
      }
      {isWithdrawalInfoModalVisibleV2 &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsWithdrawalInfoModalVisibleV2(false) }></div>
          <div className="modal-box Dashboard-withdraw-info-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Pending Withdrawal</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsWithdrawalInfoModalVisibleV2(false) } />
              </div>
            </div>
            <div>
              You have a pending withdrawal for {formatAmount(pendingWithdrawalAmountV2)} XVIX.
            </div>
            <div>
              You can finalise your withdrawal between {formatDate(minTimeV2 * 1000, "dd MMM yyyy, h:mm a")} and {formatDate(maxTimeV2 * 1000, "dd MMM yyyy, h:mm a")}.
            </div>
            <br/>
            <div>
              You can also overwrite the withdrawal amount, note that  this will reset the withdrawal timer.
            </div>
            <div className="Dashboard-withdraw-info-button-box">
              <button type="button" className="Dashboard-button-primary" onClick={() => { setIsWithdrawalInfoModalVisibleV2(false); setIsTimeVaultModalVisibleV2(true) } }>
                Overwrite
              </button>
            </div>
          </div>
        </div>
      }
      {isTimeVaultModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsTimeVaultModalVisible(false) }></div>
          <div className="modal-box Dashboard-withdraw-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">{(hasPendingWithdrawal || canFinaliseWithdrawal) ? "Overwrite" : "Withdraw XVIX"}</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsTimeVaultModalVisible(false) } />
              </div>
            </div>
            {(hasPendingWithdrawal || canFinaliseWithdrawal) && <div className="Dashboard-warning">
              WARNING: You are overwriting a pending withdrawal
            </div>}
            <div className="Dashboard-max-value Dashboard-withdraw-max-value" onClick={() => setWithdrawQuantity(formatAmountFree(timeVaultBalance)) }>
              Max {formatAmountFree(timeVaultBalance)}
            </div>
            <div>
              <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={withdrawQuantity}
                onChange={(e) => setWithdrawQuantity(e.target.value) } />
            </div>
            <div>
              <button type="button" className="Dashboard-button-primary Dashboard-withdraw-button-primary" disabled={withdrawError || isWithdrawing} onClick={() => beginWithdrawal() }>
                {getWithdrawalText()}
              </button>
            </div>
          </div>
        </div>
      }
      {isTimeVaultModalVisibleV2 &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsTimeVaultModalVisibleV2(false) }></div>
          <div className="modal-box Dashboard-withdraw-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">{(hasPendingWithdrawalV2 || canFinaliseWithdrawalV2) ? "Overwrite" : "Withdraw XVIX"}</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsTimeVaultModalVisibleV2(false) } />
              </div>
            </div>
            {(hasPendingWithdrawalV2 || canFinaliseWithdrawalV2) && <div className="Dashboard-warning">
              WARNING: You are overwriting a pending withdrawal
            </div>}
            <div className="Dashboard-max-value Dashboard-withdraw-max-value" onClick={() => setWithdrawQuantityV2(formatAmountFree(timeVaultBalanceV2)) }>
              Max {formatAmountFree(timeVaultBalanceV2)}
            </div>
            <div>
              <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={withdrawQuantityV2}
                onChange={(e) => setWithdrawQuantityV2(e.target.value) } />
            </div>
            <div>
              <button type="button" className="Dashboard-button-primary Dashboard-withdraw-button-primary" disabled={withdrawErrorV2 || isWithdrawingV2} onClick={() => beginWithdrawalV2() }>
                {getWithdrawalTextV2()}
              </button>
            </div>
          </div>
        </div>
      }
      {isBurnVaultModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsBurnVaultModalVisible(false) }></div>
          <div className="modal-box Dashboard-withdraw-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Withdraw XVIX</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsBurnVaultModalVisible(false) } />
              </div>
            </div>
            <div className="Dashboard-max-value Dashboard-withdraw-max-value" onClick={() => setBurnVaultWithdrawQuantity(formatAmountFree(burnVaultBalance)) }>
              Max {formatAmountFree(burnVaultBalance)}
            </div>
            <div>
              <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={burnVaultWithdrawQuantity}
                onChange={(e) => setBurnVaultWithdrawQuantity(e.target.value) } />
            </div>
            <div>
              <button type="button" className="Dashboard-button-primary Dashboard-withdraw-button-primary" disabled={burnVaultWithdrawError || isBurnVaultWithdrawing} onClick={() => burnVaultWithdraw() }>
                {isBurnVaultWithdrawing && "Withdrawing..."}
                {(!isBurnVaultWithdrawing && burnVaultWithdrawError !== false) && burnVaultWithdrawError}
                {(!isBurnVaultWithdrawing && burnVaultWithdrawError === false) && "Withdraw"}
              </button>
            </div>
          </div>
        </div>
      }
      {isBurnVaultModalVisibleV2 &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsBurnVaultModalVisibleV2(false) }></div>
          <div className="modal-box Dashboard-withdraw-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Withdraw XVIX</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsBurnVaultModalVisibleV2(false) } />
              </div>
            </div>
            <div className="Dashboard-max-value Dashboard-withdraw-max-value" onClick={() => setBurnVaultWithdrawQuantityV2(formatAmountFree(burnVaultBalanceV2)) }>
              Max {formatAmountFree(burnVaultBalanceV2)}
            </div>
            <div>
              <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={burnVaultWithdrawQuantityV2}
                onChange={(e) => setBurnVaultWithdrawQuantityV2(e.target.value) } />
            </div>
            <div>
              <button type="button" className="Dashboard-button-primary Dashboard-withdraw-button-primary" disabled={burnVaultWithdrawErrorV2 || isBurnVaultWithdrawingV2} onClick={() => burnVaultWithdrawV2() }>
                {isBurnVaultWithdrawingV2 && "Withdrawing..."}
                {(!isBurnVaultWithdrawingV2 && burnVaultWithdrawErrorV2 !== false) && burnVaultWithdrawErrorV2}
                {(!isBurnVaultWithdrawingV2 && burnVaultWithdrawErrorV2 === false) && "Withdraw"}
              </button>
            </div>
          </div>
        </div>
      }
      {isWithdrawLpModalVisible &&
        <div className="modal-container">
          <div className="modal-backdrop" onClick={ () => setIsWithdrawLpModalVisible(false) }></div>
          <div className="modal-box Dashboard-withdraw-liquidity-modal">
            <div className="Dashboard-withdraw-modal-top">
              <div className="Dashboard-title Dashboard-withdraw-title">Withdraw Liquidity</div>
              <div className="Dashboard-withdraw-modal-close" >
                <ImCross className="clickable" onClick={ () => setIsWithdrawLpModalVisible(false) } />
              </div>
            </div>
            <div className="Dashboard-max-value Dashboard-withdraw-max-value" onClick={() => updateEthWithdrawLpQuantity(formatArrayAmount(poolAmounts, 0)) }>
              Max {formatArrayAmount(poolAmounts, 0)}
            </div>
            <div>
              <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={ethWithdrawLpQuantity}
                onChange={(e) => updateEthWithdrawLpQuantity(e.target.value) } /> ETH
            </div>
            <div className="Dashboard-max-value Dashboard-withdraw-max-value Dashboard-withdraw-liquidity-xvix-value" onClick={() => updateXvixWithdrawLpQuantity(formatArrayAmount(poolAmounts, 1)) }>
              Max {formatArrayAmount(poolAmounts, 1)}
            </div>
            <div>
              <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={xvixWithdrawLpQuantity}
                onChange={(e) => updateXvixWithdrawLpQuantity(e.target.value) } /> XVIX
            </div>
            <div>
              <button type="button" className="Dashboard-button-primary Dashboard-withdraw-button-primary" disabled={withdrawLpError || isWithdrawingLp} onClick={() => withdrawLp() }>
                {!isApprovingLp && needRounterWithdrawApproval && !withdrawLpError && "Approve"}
                {isApprovingLp && "Approving..."}
                {isWithdrawingLp && "Withdrawing..."}
                {(!isApprovingLp && !isWithdrawingLp && withdrawLpError !== false) && withdrawLpError}
                {(!needRounterWithdrawApproval && !isApprovingLp && !isWithdrawingLp && withdrawLpError === false) && "Withdraw"}
              </button>
            </div>
          </div>
        </div>
      }
      <div>
        <table className="Dashboard-title-box">
          <tbody>
            <tr>
              <td>
                <div className="Dashboard-title">
                  Dashboard
                </div>
              </td>
              <td>
                <X2Login totalCount={totalCount} setTotalCount={setTotalCount} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="Dashboard-top-box">
          <tbody>
            <tr>
              <td className="Dashboard-supply-chart-box">
                <PieChart width={210} height={210}>
                  <Pie  data={supplyData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        innerRadius={60}
                        outerRadius={100}
                        stroke="none"
                  >
                    { supplyData.map((entry, index) => <Cell key={`cell-${index}`} fill={supplyColors[index]} />) }
                  </Pie>
                </PieChart>
                <div className="Dashboard-supply-chart-total">
                  <div>
                    {!totalSupply && "*"}
                    {totalSupply && numberWithCommas(parseInt(formatAmount(totalSupply)))}
                  </div>
                </div>
              </td>
              <td>
                <table className="Dashboard-supply-label-box">
                  <tbody>
                    {supplyData.map((item, index) =>
                      <tr key={`supply-${index}`}>
                        <td>
                          <div style={ { background: supplyColors[index] } } className="Dashboard-supply-square"></div>
                        </td>
                        <td>
                          <div className="Dashboard-supply-label">{item.name}</div>
                          <div className="Dashboard-supply-number">
                            {!isNaN(item.value) &&
                              <div>
                                {numberWithCommas(item.value)} ({parseFloat(item.value / parseFloat(formatAmount(totalSupply)) * 100).toFixed(1)}%)
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
              <td className="FloorPrice-large">
                <FloorPrice />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="FloorPrice-small">
          <FloorPrice />
        </div>
        <div className="Dashboard-max-supply">
          Max Supply:&nbsp;
          <a href="https://xvi10.gitbook.io/xvix/xvix" target="_blank" rel="noopener noreferrer" className="simple">
            {!maxSupply && "*"}
            {maxSupply && numberWithCommas(parseInt(formatAmount(maxSupply)))} XVIX
          </a>
        </div>
        <div className="hr Dashboard-hr"></div>
        <div className="Dashboard-bottom-box">
          <table className="Dashboard-wallet-box">
            <tbody>
              <tr>
                <td>
                  <div className="Dashboard-subtitle Dashboard-wallet-title">
                    <a href={xvixTokenURL} target="_blank" rel="noopener noreferrer" className="simple">
                      Wallet
                    </a>
                  </div>
                  { formatAmount(xvixBalance) } XVIX
                </td>
                <td className="align-right">
                  <a href={`https://app.uniswap.org/#/swap?outputCurrency=${XVIX_ADDRESS}`} target="_blank" rel="noopener noreferrer" className="plain">
                    Buy XVIX <FaExternalLinkAlt className="Dashboard-wallet-buy-icon" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="Dashboard-subtitle">
                    <a href={uniLpTokenURL} target="_blank" rel="noopener noreferrer" className="simple">
                      XVIX/ETH Uniswap
                    </a>
                  </div>
                  { formatArrayAmount(poolAmounts, 1) } XVIX, { formatArrayAmount(poolAmounts, 0) } ETH
                  {stakedUniEth && stakedUniXvix &&
                    <div>
                      Staked: {formatAmount(stakedUniXvix)} XVIX, {formatAmount(stakedUniEth)} ETH
                    </div>
                  }
                </td>
                <td className="align-right">
                  <div className="clickable plain Dashboard-withdraw-button" onClick={ () => setIsWithdrawLpModalVisible(true) }>
                    Withdraw
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="Dashboard-subtitle">
                    <a href={burnVaultTokenURLV2} target="_blank" rel="noopener noreferrer" className="simple">
                      Burn Reduction Vault
                    </a>
                  </div>
                  { formatAmount(burnVaultBalanceV2) } XVIX
                </td>
                <td className="align-right">
                  <div className="clickable plain Dashboard-withdraw-button" onClick={ () => setIsBurnVaultModalVisibleV2(true) }>
                    Withdraw
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="Dashboard-subtitle Dashboard-timelock-vault-title">
                    <a href={timeVaultTokenURLV2} target="_blank" rel="noopener noreferrer" className="simple">
                      Timelock Vault
                    </a>
                  </div>
                  { formatAmount(timeVaultBalanceV2) } XVIX
                  {hasPendingWithdrawalV2 &&
                    <div className="Dashboard-withdrawal-time-remaining plain clickable" onClick={ () => setIsWithdrawalInfoModalVisibleV2(true) }>
                      Pending withdrawal on {formatDate(minTimeV2 * 1000, "dd MMM yyyy, h:mm a")}
                    </div>
                  }
                  {canFinaliseWithdrawalV2 &&
                    <div className="Dashboard-finalise-withdrawal plain clickable" onClick={ () => setIsFinaliseWithdrawalModalVisibleV2(true) }>
                      You have until {formatDate(maxTimeV2 * 1000, "dd MMM yyyy, h:mm a")} to finalise your withdrawal
                    </div>
                  }
                </td>
                <td className="align-right">
                  <div className="clickable plain Dashboard-withdraw-button" onClick={ onWithdrawClickV2 }>
                    Withdraw
                  </div>
                </td>
              </tr>
              {burnVaultBalance && burnVaultBalance.gt(MIN_XVIX) && <tr>
                <td>
                  <div className="Dashboard-subtitle">
                    <a href={burnVaultTokenURL} target="_blank" rel="noopener noreferrer" className="simple">
                      Burn Reduction Vault (Old)
                    </a>
                  </div>
                  { formatAmount(burnVaultBalance) } XVIX
                </td>
                <td className="align-right">
                  <div className="clickable plain Dashboard-withdraw-button" onClick={ () => setIsBurnVaultModalVisible(true) }>
                    Withdraw
                  </div>
                </td>
              </tr>}
              {timeVaultBalance && timeVaultBalance.gt(MIN_XVIX) && <tr>
                <td>
                  <div className="Dashboard-subtitle Dashboard-timelock-vault-title">
                    <a href={timeVaultTokenURL} target="_blank" rel="noopener noreferrer" className="simple">
                      Timelock Vault (Old)
                    </a>
                  </div>
                  { formatAmount(timeVaultBalance) } XVIX
                  {hasPendingWithdrawal &&
                    <div className="Dashboard-withdrawal-time-remaining plain clickable" onClick={ () => setIsWithdrawalInfoModalVisible(true) }>
                      Pending withdrawal on {formatDate(minTime * 1000, "dd MMM yyyy, h:mm a")}
                    </div>
                  }
                  {canFinaliseWithdrawal &&
                    <div className="Dashboard-finalise-withdrawal plain clickable" onClick={ () => setIsFinaliseWithdrawalModalVisible(true) }>
                      You have until {formatDate(maxTime * 1000, "dd MMM yyyy, h:mm a")} to finalise your withdrawal
                    </div>
                  }
                </td>
                <td className="align-right">
                  <div className="clickable plain Dashboard-withdraw-button" onClick={ onWithdrawClick }>
                    Withdraw
                  </div>
                </td>
              </tr>}
              <tr>
                <td colSpan="2">
                  <Link to="/distribution" className="simple">View XLGEs</Link>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="hr"></div>
          <div className="Dashboard-burn-info">
            <div>
              <div className="Dashboard-title">Burn Protection</div>
            </div>
            <div className="Dashboard-burn-options">
              <select onChange={ (e) => setBurnOption(e.target.value) } value={burnOption}>
                <option value="TimeVault">Timelock Vault</option>
                <option value="BurnVault">Burn Reduction Vault</option>
                <option value="XvixEth">XVIX/ETH UNI</option>
              </select>
              <BsCaretDownFill className="select-caret" />
            </div>
          </div>
          <div className="Dashboard-burn-widget">
            <div>
              Keep your tokens safe from the&nbsp;
              <a href="https://xvi10.gitbook.io/xvix/xvix" className="simple" target="_blank" rel="noopener noreferrer">
                0.02% hourly burn
              </a>
            </div>
            {burnOption === "XvixEth" &&
              <div className="Dashboard-burn-box">
                <div>
                  Tokens in the XVIX/ETH Uniswap Pool have zero burns.
                </div>
                <div className="Dashboard-burn-box-form">
                  <div className="Dashboard-max-value" onClick={() => updateEthLpQuantity(formatAmountFree(ethBalance)) }>Max {formatAmountFree(ethBalance)}</div>
                  <div className="Dashboard-vault-box">
                    <div>
                      <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={ethLpQuantity}
                        onChange={(e) => updateEthLpQuantity(e.target.value) } /> ETH
                    </div>
                  </div>
                  <div className="Dashboard-max-value Dashboard-lp-xvix-max-value" onClick={() => updateXvixLpQuantity(formatAmountFree(xvixBalance)) }>Max {formatAmountFree(xvixBalance)}</div>
                  <div className="Dashboard-vault-box">
                    <div>
                      <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={xvixLpQuantity}
                        onChange={(e) => updateXvixLpQuantity(e.target.value) } /> XVIX
                    </div>
                  </div>
                  <div>
                    <button type="button" className="Dashboard-button-primary Dashboard-add-liquidity-button"
                      disabled={active && (isAddingLiquidity || isApprovingRouter || addLiquidityError)}
                      onClick={ addLiquidity }>
                      {!active && "Connect Wallet"}
                      {isAddingLiquidity && "Adding..."}
                      {isApprovingRouter && "Approving..."}
                      {needRouterApproval && !addLiquidityError && !isAddingLiquidity && !isApprovingRouter && "Approve" }
                      {(active && !isAddingLiquidity && !isApprovingRouter && addLiquidityError) && addLiquidityError }
                      {(!needRouterApproval && !isAddingLiquidity && !isApprovingRouter && !addLiquidityError) && "Add Liquidity" }
                    </button>
                  </div>
                </div>
              </div>
            }
            {burnOption === "TimeVault" &&
              <div className="Dashboard-burn-box">
                <div>Tokens in the Timelock Vault have zero burns and a withdrawal delay of&nbsp;
                  <a href="https://xvi10.gitbook.io/xvix/xvix-vault" className="simple" target="_blank" rel="noopener noreferrer">
                    7 days
                  </a>.
                </div>
                <div className="Dashboard-burn-box-form">
                  <div className="Dashboard-max-value" onClick={() => setTimeVaultQuantity(formatAmountFree(xvixBalance, 1, 6)) }>Max {formatAmountFree(xvixBalance)}</div>
                  <div className="Dashboard-vault-box">
                    <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={timeVaultQuantity}
                      onChange={(e) => setTimeVaultQuantity(e.target.value) } />
                    <button type="button" className="Dashboard-button-primary" disabled={active && (isDepositing || isApprovingTimeVault || timeVaultError)} onClick={() => onClickPrimary() }>
                      {!active && "Connect Wallet"}
                      {isDepositing && "Depositing..."}
                      {isApprovingTimeVault && "Approving..."}
                      {active && needApproval && !isApprovingTimeVault && !timeVaultError && "Approve Vault" }
                      {((active && timeVaultError) || (active && !needApproval && !isDepositing)) && (timeVaultError ? timeVaultError : "Deposit")}
                    </button>
                  </div>
                </div>
              </div>
            }
            {burnOption === "BurnVault" &&
              <div className="Dashboard-burn-box">
                <div>Tokens in the&nbsp;
                  <a href="https://xvi10.gitbook.io/xvix/burn-reduction-vault" className="simple" target="_blank" rel="noopener noreferrer">
                    Burn Reduction Vault
                  </a> have 0.01% hourly burns and no withdrawal delay.</div>
                <div className="Dashboard-burn-box-form">
                  <div className="Dashboard-max-value" onClick={() => setBurnVaultQuantity(formatAmountFree(xvixBalance, 1, 6)) }>Max {formatAmountFree(xvixBalance)}</div>
                  <div className="Dashboard-vault-box">
                    <input type="text" placeholder="0" className="Dashboard-input-outline input-outline" value={burnVaultQuantity}
                      onChange={(e) => setBurnVaultQuantity(e.target.value) } />
                    <button type="button" className="Dashboard-button-primary" disabled={active && (isDepositingToBurnVault || isApprovingBurnVault || burnVaultError)} onClick={() => onClickBurnVaultPrimary() }>
                      {!active && "Connect Wallet"}
                      {isDepositingToBurnVault && "Depositing..."}
                      {isApprovingBurnVault && "Approving..."}
                      {needBurnVaultApproval && !isApprovingBurnVault && !burnVaultError && "Approve Vault" }
                      {((active && burnVaultError) || (active && !needBurnVaultApproval && !isDepositingToBurnVault)) && (burnVaultError ? burnVaultError : "Deposit")}
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Dashboard() {
  return (
    <div className="Dashboard Page-content">
      <div className="Box">
        <DashboardApp />
      </div>
    </div>
  )
}
