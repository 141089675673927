import React from 'react';
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'

export default function Debug() {
  let hasWeb3 = false
  if (window.web3) {
    hasWeb3 = true
  }
  let hasEthereum = false
  if (window.ethereum) {
    hasEthereum = true
  }
  let hasMetamask = false
  if (window.ethereum && window.ethereum.isMetaMask) {
    hasMetamask = true
  }
  const { activate, account } = useWeb3React()

const injected = new InjectedConnector({
  supportedChainIds: [
    1, // Mainet
    // 3, // Ropsten
    42 // Kovan
  ],
})

  const activateMetamask = async () => {
    activate(injected, (e) => {
      alert(e.toString())
    })
  }

  const connect = async () => {
    try {
      await window.ethereum.enable()
      alert("connected")
    } catch (e) {
      alert(e.toString())
    }
  }

  return (
    <div className="Overview Page-content">
      <div className="Box">
        <div>
          {account && <div>{account}</div>}
          Web3: {hasWeb3 ? "Yes" : "No" }
          <br/>
          Ethereum: {hasEthereum ? "Yes" : "No" }
          <br/>
          Metamask: {hasMetamask ? "Yes" : "No" }
          <br/>
          <button onClick={ () => connect() }>Test Connection</button>
          <br/>
          <button onClick={ () => activateMetamask() }>Test Metamask</button>
        </div>
      </div>
    </div>
  )
}
