import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useLocalStorage } from './Storage'
import useSWR from 'swr'
import { toast } from 'react-toastify'
import cx from "classnames";
import ethers from 'ethers'

import './ExchangeBeta.css'
import { FaExchangeAlt, FaWallet } from 'react-icons/fa'
import { BsInfoCircle } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'

import X2Reader from './abis/X2Reader.json'
import X2Router from './abis/X2Router.json'
import X2Token from './abis/X2Token.json'
import IX2PriceFeed from './abis/IX2PriceFeed.json'

import ChainlinkLogo from './img/chainlink.png'

import { getMarket, getContract } from './Addresses'
import { formatAmount, formatAmountFree, formatArrayAmount, formatPriceFeed, getTokenUrl,
  getInjectedConnector, getExplorerUrl, fetcher,
  useEagerConnect, useInactiveListener } from './Helpers'

const TOTAL_TX_KEY = "totalTxKey:"
const CHAIN_ID = 1

export function getTotalTxKey(account) {
  if (!account) {
    return TOTAL_TX_KEY
  }
  return TOTAL_TX_KEY + account
}

async function incrementTotalTxCount(library, account, props) {
  const txCount = await library.getTransactionCount(account)
  if (txCount > props.totalCount) {
    props.setTotalCount(txCount + 1)
    return
  }

  props.setTotalCount(props.totalCount + 1)
}

export const Login = (props) => {
  const { activate, active, account, library, chainId } = useWeb3React()
  const accountUrl = getExplorerUrl(chainId) + "address/" + account

  const { data: txCount, mutate } = useSWR([active, 'getTransactionCount', account], {
    fetcher: fetcher(library),
  })

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        mutate(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, mutate])

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let pending = 0

  if (active && props.totalCount > 0 && txCount) {
    pending = props.totalCount - txCount
  }

  if (pending < 0) {
    pending = 0
  }

  return (
    <div className="ExchangeBeta-login">
      {!active &&
        <button type="button" onClick={activateMetamask} className="button-outline">
          Connect Wallet
        </button>
      }
      {active &&
        <a href={accountUrl} target="_blank" rel="noopener noreferrer" className={cx("button-outline", "ExchangeBeta-pending-tx", { active: pending > 0 })}>
          {pending === 1 && "1 Pending Tx"}
          {pending !== 1 && `${pending} Pending Txs`}
        </a>
      }
    </div>
  )
}

export default function ExchangeBeta() {
  const [isWarningVisiable, setIsWarningVisible] = useState(true)
  const [isSwapping, setIsSwapping] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const [isApprovingBull, setIsApprovingBull] = useState(false)
  const [isApprovingBear, setIsApprovingBear] = useState(false)
  const [isBuying, setIsBuying] = useState(false)
  const [isBull, setIsBull] = useState(true)
  const [quantity, setQuantity] = useState("")
  const [isSellingAllBull, setIsSellingAllBull] = useState(false)
  const [isSellingAllBear, setIsSellingAllBear] = useState(false)

  const { activate, active, account, library, chainId } = useWeb3React()
  const [totalCount, setTotalCount] = useLocalStorage(getTotalTxKey(account), 0)
  const market = getMarket(CHAIN_ID, "ETH_USD_10X")

  const { data: marketAmounts, mutate: updateMarketAmounts } = useSWR([active, getContract(CHAIN_ID, "X2Reader"), "getMarketInfo", market.address, account], {
    fetcher: fetcher(library, X2Reader),
  })

  const { data: tokenAmounts, mutate: updateTokenAmounts } = useSWR([active, getContract(CHAIN_ID, "X2Reader"), "getTokenInfo", market.address, getContract(CHAIN_ID, "X2Router"), account], {
    fetcher: fetcher(library, X2Reader),
  })

  const { data: indexPrice, mutate: updateIndexPrice } = useSWR([active, market.priceFeed, "latestAnswer"], {
    fetcher: fetcher(library, IX2PriceFeed),
  })

  const { data: indexPriceTime, mutate: updateIndexPriceTime } = useSWR([active, market.priceFeed, "latestTimestamp"], {
    fetcher: fetcher(library, IX2PriceFeed),
  })

  const [activatingConnector, setActivatingConnector] = React.useState()
  const { connector } = useWeb3React()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  useEffect(() => {
    if (active) {
      library.on('block', () => {
        updateMarketAmounts(undefined, true)
        updateTokenAmounts(undefined, true)
        updateIndexPrice(undefined, true)
        updateIndexPriceTime(undefined, true)
      })
      return () => {
        library.removeAllListeners('block')
      }
    }
  }, [active, library, updateMarketAmounts, updateTokenAmounts, updateIndexPrice, updateIndexPriceTime])

  let maxValue
  if (marketAmounts) {
    if (isBuying) {
      maxValue = marketAmounts[0]
    } else {
      maxValue = isBull ? marketAmounts[4] : marketAmounts[5]
    }
  }

  let percentages = [0, 0]
  let levs = [0, 0]
  if (marketAmounts && (marketAmounts[2].gt(0) || marketAmounts[3].gt(0))) {
    const total = marketAmounts[2].add(marketAmounts[3])
    const bullPercentage = marketAmounts[2].mul(10000).div(total)
    percentages[0] = bullPercentage.toNumber() / 100.0
    percentages[1] = 100.0 - percentages[0]
    if (marketAmounts[2].gt(marketAmounts[3])) {
      levs[1] = 10
      levs[0] = marketAmounts[3].mul(10000).div(marketAmounts[2]).toNumber() / 1000
    } else {
      levs[0] = 10
      levs[1] = marketAmounts[2].mul(10000).div(marketAmounts[3]).toNumber() / 1000
    }
  }

  const parsedQuantity = parseFloat(quantity)
  let quantityInWei
  let receiveStr = "0"

  const toUnit = isBuying ? (isBull ? "BULL" : "BEAR") : "ETH"
  const fromUnit = isBuying ? "ETH" : (isBull ? "BULL" : "BEAR")
  const token = isBull ? market.bullToken : market.bearToken
  const tokenUrl = getTokenUrl(CHAIN_ID, token, account)

  if (!isNaN(parsedQuantity)) {
    quantityInWei = ethers.utils.parseEther(parsedQuantity.toString())

    if (isBuying && tokenAmounts) {
      if (tokenAmounts) {
        const cachedDivisor = isBull ? tokenAmounts[0] : tokenAmounts[1]
        const divisor = isBull ? tokenAmounts[2] : tokenAmounts[3]
        const receivedInWei = quantityInWei.mul(cachedDivisor).div(divisor)
        receiveStr = formatAmountFree(receivedInWei)
      }
    } else {
      receiveStr = parsedQuantity
    }
  }

  const activateMetamask = async () => {
    activate(getInjectedConnector(), (e) => {
      toast.error(e.toString())
    })
  }

  let needApproval = false
  if (!isBuying && quantityInWei && tokenAmounts) {
    const allowance = isBull ? tokenAmounts[4] : tokenAmounts[5]
    needApproval = allowance.lt(quantityInWei)
  }

  let needBullApproval = false
  let needBearApproval = false
  if (tokenAmounts && marketAmounts) {
    needBullApproval = tokenAmounts[4].lt(marketAmounts[4])
    needBearApproval = tokenAmounts[5].lt(marketAmounts[5])
  }

  const approveTokens = async (tokenToApprove) => {
    const contract = new ethers.Contract(tokenToApprove, X2Token.abi, library.getSigner())
    contract.approve(getContract(CHAIN_ID, "X2Router"), ethers.constants.MaxUint256)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Approval submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
      })
      .catch((e) => {
        console.error(e)
        toast.error("Approval failed.")
      })
      .finally(() => {
        setIsApproving(false)
        setIsApprovingBull(false)
        setIsApprovingBear(false)
      })
  }

  const onClickPrimary = async () => {
    if (!active) {
      activateMetamask()
      return
    }
    if (chainId !== CHAIN_ID) {
      toast.error("Incorrect network, please select \"Ethereum Mainnet\" in Metamask")
      return
    }

    if (needApproval) {
      setIsApproving(true)
      approveTokens(token)
      return
    }

    setIsSwapping(true)
    const contract = new ethers.Contract(getContract(CHAIN_ID, "X2Router"), X2Router.abi, library.getSigner())
    const deadline = parseInt(Date.now() / 1000) + 20 * 60
    const method = isBuying ? "depositETH" : "withdrawETH"
    const params = isBuying ? [token, 0, account, deadline, { value: quantityInWei }] : [token, quantityInWei, 0, account, deadline]

    contract[method](...params)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Swap submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Swap failed.")
      })
      .finally(() => {
        setIsSwapping(false)
      })
  }

  const sellAll = async (sellAllBull) => {
    if (chainId !== CHAIN_ID) {
      toast.error("Incorrect network, please select \"Ethereum Mainnet\" in Metamask")
      return
    }

    if (sellAllBull && needBullApproval) {
      setIsApprovingBull(true)
      approveTokens(market.bullToken)
      return
    }

    if (!sellAllBull && needBearApproval) {
      setIsApprovingBear(true)
      approveTokens(market.bearToken)
      return
    }

    if (sellAllBull) {
      setIsSellingAllBull(true)
    } else {
      setIsSellingAllBear(true)
    }

    const contract = new ethers.Contract(getContract(CHAIN_ID, "X2Router"), X2Router.abi, library.getSigner())
    const deadline = parseInt(Date.now() / 1000) + 20 * 60
    const _token = sellAllBull ? market.bullToken : market.bearToken

    contract.withdrawAllETH(_token, 0, account, deadline)
      .then(async (res) => {
        await incrementTotalTxCount(library, account, { totalCount, setTotalCount })

        const txUrl = getExplorerUrl(CHAIN_ID) + "tx/" + res.hash
        toast.success(
          <div>
            Sell all submitted! <a href={txUrl} target="_blank" rel="noopener noreferrer">View status.</a>
            <br/>
          </div>
        )
        setQuantity("")
      })
      .catch((e) => {
        console.error(e)
        toast.error("Sell all failed.")
      })
      .finally(() => {
        if (sellAllBull) {
          setIsSellingAllBull(false)
        } else {
          setIsSellingAllBear(false)
        }
      })
  }

  let error
  if (isNaN(parsedQuantity)) {
    error = "Invalid quantity"
  }
  if (quantity === "" || (quantityInWei && quantityInWei.eq(0))) {
    error = "Enter an amount"
  }
  if (quantityInWei && maxValue && quantityInWei.gt(maxValue)) {
    error = `Insufficient ${fromUnit}`
  }

  return(
    <div className="ExchangeBeta Page-content">
      <div className="Box">
        {isWarningVisiable &&
          <div className="Banner ExchangeBeta-banner">
            <div>
              This market is inactive. Buying has been disabled. <br/>
              Click <Link to="/trade">here</Link> to trade in the active markets.
            </div>
            <MdClose className="ExchangeBeta-close-icon" onClick={ () => setIsWarningVisible(false) } />
          </div>
        }

        <table className="ExchangeBeta-layout">
          <tbody>
            <tr>
              <td className="ExchangeBeta-title">
                <div>
                  Trade 10X ETH/USD Tokens
                  <a href="https://xvi10.gitbook.io/xvix/x2" target="_blank" rel="noopener noreferrer" className="ExchangeBeta-title-link">
                    <BsInfoCircle />
                  </a>
                </div>
                <div className="ExchangeBeta-small-login">
                  <Login totalCount={totalCount} setTotalCount={setTotalCount} />
                </div>
              </td>
              <td className="ExchangeBeta-right">
                <Login totalCount={totalCount} setTotalCount={setTotalCount} />
              </td>
            </tr>
            <tr className="ExchangeBeta-small-stats">
              <td colSpan="2">
                {marketAmounts &&
                  <div className="ExchangeBeta-box-stats-container">
                    <div className="ExchangeBeta-box ExchangeBeta-box-stats">
                      <div className="ExchangeBeta-box-info">
                        <table>
                          <tbody>
                            <tr>
                              <td>Mark price</td>
                              <td>{ formatPriceFeed(marketAmounts[1]) }</td>
                            </tr>
                            <tr>
                              <td>Min increase</td>
                              <td>{ formatPriceFeed(marketAmounts[1].mul(10050).div(10000)) }</td>
                            </tr>
                            <tr>
                              <td>Min decrease</td>
                              <td>{ formatPriceFeed(marketAmounts[1].mul(9950).div(10000)) }</td>
                            </tr>
                            <tr>
                              <td>Index price</td>
                              <td>{ formatPriceFeed(indexPrice) }</td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="ExchangeBeta-stats-updated-time">
                                <strong>Index Price Last Updated</strong>
                                <br/>
                                { (new Date(indexPriceTime * 1000)).toString() }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="ExchangeBeta-box-stats-number">
                        { formatPriceFeed(marketAmounts[1]) }
                      </div>
                      <div className="ExchangeBeta-box-stats-label">
                        ETH/USD
                      </div>
                    </div>
                    <div className="ExchangeBeta-box ExchangeBeta-box-stats">
                      <div className="ExchangeBeta-box-stats-number">
                        { formatArrayAmount(marketAmounts, 2) }
                      </div>
                      <div className="ExchangeBeta-box-stats-label">
                        BULLs ({percentages[0].toFixed(1)}%, {levs[0].toFixed(2)}x)
                      </div>
                    </div>
                    <div className="ExchangeBeta-box ExchangeBeta-box-stats">
                      <div className="ExchangeBeta-box-stats-number">
                        { formatArrayAmount(marketAmounts, 3) }
                      </div>
                      <div className="ExchangeBeta-box-stats-label">
                        BEARs ({percentages[1].toFixed(1)}%, {levs[1].toFixed(2)}x)
                      </div>
                    </div>
                  </div>
                }
              </td>
            </tr>
            <tr>
              <td className="align-top">
                <div className="ExchangeBeta-widget ExchangeBeta-box">
                  <div>
                    <button type="button" onClick={ () => setIsBull(true) } className={cx("button-text", "ExchangeBeta-widget-left", { active: isBull })}>
                      BULL
                    </button>
                    <button type="button" onClick={ () => setIsBull(false) } className={cx("button-text", { active: !isBull })}>
                      BEAR
                    </button>
                  </div>
                  <div className="hr"></div>
                  <table className="ExchangeBeta-form">
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <div className="ExchangeBeta-form-top">
                            <div className="ExchangeBeta-form-left ExchangeBeta-form-subtitle">
                              You Pay
                            </div>
                            {maxValue &&
                              <div className="ExchangeBeta-form-max ExchangeBeta-form-right clickable" onClick={ () => setQuantity(formatAmount(maxValue)) }>
                                { `Max ${formatAmount(maxValue)} ${fromUnit}` }
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="ExchangeBeta-form-left">
                            <input type="text" placeholder="0" value={quantity} className="input-outline ExchangeBeta-form-large-font"
                              onChange={(e) => setQuantity(e.target.value) } />
                          </div>
                        </td>
                        <td>
                          <div className="ExchangeBeta-form-right ExchangeBeta-form-large-font">
                            {!isBuying &&
                              <a href={tokenUrl} target="_blank" rel="noopener noreferrer" className="ExchangeBeta-unit">
                                {fromUnit}
                              </a>
                            }
                            {isBuying && fromUnit}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="ExchangeBeta-form-left ExchangeBeta-form-subtitle">
                            You Receive
                          </div>
                        </td>
                        <td>
                          <div className="ExchangeBeta-form-swap">
                            <FaExchangeAlt className="ExchangeBeta-form-swap-icon" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="ExchangeBeta-form-left">
                            <input type="text" placeholder="0" value={receiveStr} className="input-outline ExchangeBeta-form-large-font inactive" disabled={true}/>
                          </div>
                        </td>
                        <td>
                          <div className="ExchangeBeta-form-right ExchangeBeta-form-large-font">
                            {isBuying &&
                              <a href={tokenUrl} target="_blank" rel="noopener noreferrer" className="ExchangeBeta-unit">
                                {toUnit}
                              </a>
                            }
                            {!isBuying && toUnit}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <div className="button-container-primary">
                      <button type="button" className="button-primary" onClick={() => onClickPrimary() } disabled={active && (isSwapping || error)}>
                        {!active && "Connect Wallet"}
                        {isSwapping && "Swapping..."}
                        {isApproving && "Approving..."}
                        {(active && !isSwapping && !needApproval) && (error ? error : "Swap")}
                        {needApproval && !isApproving && `Approve ${fromUnit} Tokens` }
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {marketAmounts && (marketAmounts[4].gt(0) || marketAmounts[5].gt(0)) &&
                    <div className="ExchangeBeta-box ExchangeBeta-wallet-box">
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <div className="ExchangeBeta-wallet-label">
                                <FaWallet className="ExchangeBeta-wallet-icon" />
                                Wallet
                              </div>
                            </td>
                          </tr>
                          {marketAmounts && marketAmounts[4].gt(0) &&
                            <tr>
                              <td>
                                <div className="ExchangeBeta-wallet-value clickable" onClick={ () => { setIsBuying(false); setIsBull(true); setQuantity(formatArrayAmount(marketAmounts, 4)) } }>
                                  {formatArrayAmount(marketAmounts, 4)} BULL
                                </div>
                              </td>
                              <td>
                                <div className="ExchangeBeta-wallet-button-box">
                                  <button className="button-outline-compact" onClick={ () => sellAll(true) } disabled={isSellingAllBull} >
                                    {isSellingAllBull && "Selling..."}
                                    {isApprovingBull && "Approving..."}
                                    {!isSellingAllBull && !needBullApproval && "Sell All"}
                                    {!isSellingAllBull && needBullApproval && !isApprovingBull && "Approve"}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          }
                          {marketAmounts && marketAmounts[5].gt(0) &&
                            <tr>
                              <td>
                                <div className="ExchangeBeta-wallet-value clickable" onClick={ () => { setIsBuying(false); setIsBull(false); setQuantity(formatArrayAmount(marketAmounts, 5)) } }>
                                  {formatArrayAmount(marketAmounts, 5)} BEAR
                                </div>
                              </td>
                              <td>
                                <div className="ExchangeBeta-wallet-button-box">
                                  <button className="button-outline-compact" onClick={ () => sellAll(false) } disabled={isSellingAllBear} >
                                    {isSellingAllBear && "Selling..."}
                                    {isApprovingBear && "Approving..."}
                                    {!isSellingAllBear && !needBearApproval && "Sell All"}
                                    {!isSellingAllBear && needBearApproval && !isApprovingBear && "Approve"}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                  <div className="ExchangeBeta-box ExchangeBeta-chainlink-small-box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a href="https://chain.link/" target="_blank" rel="noopener noreferrer">
                              <img src={ChainlinkLogo} alt="Chainlink Logo" />
                            </a>
                          </td>
                          <td>
                            <a href="https://chain.link/" target="_blank" rel="noopener noreferrer" className="plain">
                              Built on Chainlink
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
              <td className="align-top ExchangeBeta-right">
                {marketAmounts &&
                  <div>
                    <div className="ExchangeBeta-box ExchangeBeta-box-stats ExchangeBeta-price-box">
                      <div className="ExchangeBeta-box-info">
                        <table>
                          <tbody>
                            <tr>
                              <td>Mark price</td>
                              <td>{ formatPriceFeed(marketAmounts[1]) }</td>
                            </tr>
                            <tr>
                              <td>Min increase</td>
                              <td>{ formatPriceFeed(marketAmounts[1].mul(10050).div(10000)) }</td>
                            </tr>
                            <tr>
                              <td>Min decrease</td>
                              <td>{ formatPriceFeed(marketAmounts[1].mul(9950).div(10000)) }</td>
                            </tr>
                            <tr>
                              <td>Index price</td>
                              <td>{ formatPriceFeed(indexPrice) }</td>
                            </tr>
                            <tr>
                              <td colSpan="2" className="ExchangeBeta-stats-updated-time">
                                <strong>Index Price Last Updated</strong>
                                <br/>
                                { (new Date(indexPriceTime * 1000)).toString() }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="ExchangeBeta-box-stats-number">
                        { formatPriceFeed(marketAmounts[1]) }
                      </div>
                      <div className="ExchangeBeta-box-stats-label">
                        ETH/USD
                      </div>
                    </div>
                    <div className="ExchangeBeta-box ExchangeBeta-box-stats ExchangeBeta-bull-box">
                      <div className="ExchangeBeta-box-stats-number">
                        { formatArrayAmount(marketAmounts, 2) }
                      </div>
                      <div className="ExchangeBeta-box-stats-label">
                        BULLs ({percentages[0].toFixed(1)}%, {levs[0].toFixed(2)}x)
                      </div>
                    </div>
                    <div className="ExchangeBeta-box ExchangeBeta-box-stats ExchangeBeta-bear-box">
                      <div className="ExchangeBeta-box-stats-number">
                        { formatArrayAmount(marketAmounts, 3) }
                      </div>
                      <div className="ExchangeBeta-box-stats-label">
                        BEARs ({percentages[1].toFixed(1)}%, {levs[1].toFixed(2)}x)
                      </div>
                    </div>
                  </div>
                }
                <div className="ExchangeBeta-box ExchangeBeta-box-stats ExchangeBeta-chainlink-box">
                  <div className="ExchangeBeta-chainlink-logo-box">
                    <a href="https://chain.link/" target="_blank" rel="noopener noreferrer">
                      <img src={ChainlinkLogo} alt="Chainlink Logo" />
                    </a>
                  </div>
                  <a href="https://chain.link/" target="_blank" rel="noopener noreferrer" className="plain">
                    Built on Chainlink
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
